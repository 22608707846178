@charset "UTF-8";
.progress-0 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.progress-0 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-1 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.progress-1 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-2 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.progress-2 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-3 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.progress-3 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-4 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.progress-4 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-5 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.progress-5 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-6 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.progress-6 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-7 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.progress-7 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-8 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.progress-8 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-9 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.progress-9 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-10 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.progress-10 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-11 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.progress-11 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-12 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.progress-12 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-13 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.progress-13 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-14 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.progress-14 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-15 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.progress-15 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-16 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.progress-16 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-17 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.progress-17 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-18 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.progress-18 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-19 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.progress-19 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-20 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.progress-20 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-21 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.progress-21 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-22 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.progress-22 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-23 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.progress-23 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-24 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.progress-24 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-25 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.progress-25 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-26 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.progress-26 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-27 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.progress-27 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-28 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.progress-28 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-29 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.progress-29 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-30 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.progress-30 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-31 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.progress-31 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-32 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.progress-32 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-33 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.progress-33 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-34 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.progress-34 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-35 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.progress-35 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-36 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.progress-36 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-37 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.progress-37 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-38 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.progress-38 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-39 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.progress-39 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-40 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.progress-40 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-41 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.progress-41 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-42 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.progress-42 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-43 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.progress-43 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-44 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.progress-44 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-45 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.progress-45 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-46 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.progress-46 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-47 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.progress-47 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-48 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.progress-48 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-49 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.progress-49 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-50 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.progress-50 .esg-progress-circle-pie--content .right-side {
  display: none;
}

.progress-51 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-51 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.progress-51 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-52 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-52 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.progress-52 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-53 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-53 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.progress-53 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-54 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-54 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.progress-54 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-55 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-55 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.progress-55 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-56 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-56 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.progress-56 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-57 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-57 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.progress-57 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-58 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-58 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.progress-58 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-59 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-59 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.progress-59 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-60 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-60 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.progress-60 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-61 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-61 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.progress-61 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-62 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-62 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.progress-62 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-63 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-63 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.progress-63 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-64 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-64 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.progress-64 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-65 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-65 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.progress-65 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-66 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-66 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.progress-66 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-67 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-67 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.progress-67 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-68 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-68 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.progress-68 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-69 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-69 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.progress-69 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-70 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-70 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.progress-70 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-71 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-71 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.progress-71 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-72 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-72 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.progress-72 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-73 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-73 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.progress-73 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-74 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-74 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.progress-74 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-75 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-75 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.progress-75 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-76 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-76 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.progress-76 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-77 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-77 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.progress-77 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-78 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-78 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.progress-78 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-79 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-79 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.progress-79 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-80 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-80 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.progress-80 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-81 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-81 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.progress-81 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-82 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-82 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.progress-82 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-83 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-83 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.progress-83 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-84 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-84 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.progress-84 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-85 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-85 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.progress-85 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-86 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-86 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.progress-86 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-87 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-87 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.progress-87 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-88 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-88 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.progress-88 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-89 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-89 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.progress-89 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-90 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-90 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.progress-90 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-91 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-91 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.progress-91 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-92 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-92 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.progress-92 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-93 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-93 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.progress-93 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-94 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-94 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.progress-94 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-95 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-95 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.progress-95 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-96 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-96 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.progress-96 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-97 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-97 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.progress-97 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-98 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-98 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.progress-98 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.progress-99 .esg-progress-circle-pie--content {
  clip: rect(auto, auto, auto, auto);
}
.progress-99 .esg-progress-circle-pie--content .left-side {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.progress-99 .esg-progress-circle-pie--content .right-side {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.cell-table-basic,
.cell-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.cell-list-floated,
.cell-list-block,
.cell-list-inline-block,
.cell-list-inline {
  list-style: outside none none;
  margin: 0 0 5px 0;
  padding: 0;
}

.cell-button {
  border-radius: 2px;
  cursor: pointer;
  font-family: inherit;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  padding: 0 24px;
  text-transform: uppercase;
  white-space: nowrap;
}
.cell-button[type="button"] {
  border: 0;
  cursor: pointer;
}
.cell-widget-footer .cell-button {
  font-weight: bold;
  line-height: normal;
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button {
    font-size: 11px;
    height: 22px;
    padding: 0 14px;
  }
}
.cell-button--placeholder {
  font-style: normal;
}
.cell-button--add {
  position: relative;
}
.cell-button--add::after {
  content: "";
  position: absolute;
  right: calc(50% - 3px);
  top: calc(50% - 5px);
}
.cell-button--remove {
  position: relative;
}
.cell-button--remove::after {
  content: "";
  left: calc(50% - 3px);
  position: absolute;
  top: calc(50% - 5px);
}
.cell-button--next {
  position: relative;
  text-indent: -9999px;
}
@media screen and (min-width: 40em) {
  .cell-button--next {
    padding-right: 40px;
    text-indent: 0;
  }
}
.cell-button--next::after {
  content: "";
  position: absolute;
  right: 20px;
  top: calc(50% - 6px);
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button--next {
    padding-left: 10px;
    padding-right: 25px;
  }
  .cell-widget-footer .cell-button--next::after {
    right: 10px;
    top: calc(50% - 4px);
  }
}
.cell-button--prev {
  position: relative;
  text-indent: -9999px;
}
@media screen and (min-width: 40em) {
  .cell-button--prev {
    padding-left: 40px;
    text-indent: 0;
  }
}
.cell-button--prev::after {
  content: "";
  left: 20px;
  position: absolute;
  top: calc(50% - 6px);
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button--prev {
    padding-left: 25px;
    padding-right: 10px;
  }
  .cell-widget-footer .cell-button--prev::after {
    left: 10px;
    top: calc(50% - 4px);
  }
}
.cell-button--first {
  position: relative;
  text-indent: -9999px;
}
@media screen and (min-width: 40em) {
  .cell-button--first {
    padding-left: 40px;
    text-indent: 0;
  }
}
.cell-button--first::before {
  content: "";
  display: inline-block;
  left: 12px;
  padding: 8px;
  position: absolute;
  top: calc(50% - 8px);
}
.cell-button--first::after {
  content: "";
  left: 20px;
  position: absolute;
  top: calc(50% - 6px);
}
.cell-widget-footer .cell-button--first {
  padding-left: 25px;
}
.cell-widget-footer .cell-button--first::before {
  top: calc(50% - 8px);
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button--first::before {
    left: 10px;
    padding: 6px;
    top: calc(50% - 6px);
  }
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button--first::after {
    left: 15px;
    top: calc(50% - 4px);
  }
}
.cell-button--last {
  position: relative;
  text-indent: -9999px;
}
@media screen and (min-width: 40em) {
  .cell-button--last {
    padding-right: 40px;
    text-indent: 0;
  }
}
.cell-button--last::before {
  content: "";
  display: inline-block;
  padding: 8px;
  position: absolute;
  right: 12px;
  top: calc(50% - 8px);
}
.cell-button--last::after {
  content: "";
  position: absolute;
  right: 20px;
  top: calc(50% - 6px);
}
.cell-widget-footer .cell-button--last {
  padding-right: 25px;
}
.cell-widget-footer .cell-button--last::before {
  top: calc(50% - 8px);
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button--last::before {
    padding: 6px;
    right: 10px;
    top: calc(50% - 6px);
  }
}
@media screen and (min-width: 40em) {
  .cell-widget-footer .cell-button--last::after {
    right: 15px;
    top: calc(50% - 4px);
  }
}
.cell-button--disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: not-allowed;
}
.cell-button--small {
  font-size: 11px;
  height: 24px;
  line-height: 22px;
  padding: 0 10px;
}
.cell-button--inline {
  font-size: 11px;
  font-weight: 700;
  height: 20px;
  line-height: 1;
  padding: 0 5px;
  text-transform: none;
}
.cell-button--flat {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: all linear 0.1s;
  transition: all linear 0.1s;
}
.cell-button--flat:hover {
  -webkit-transition: all linear 0.1s;
  transition: all linear 0.1s;
}
.cell-button--expanded {
  width: 100%;
}
.cell-button-link-h4 {
  opacity: 0.8;
}
.cell-button-link-h4:hover {
  opacity: 1;
}
.cell-button-link-h4-delete {
  cursor: pointer;
}
.cell-button-link-h4--delete {
  font-size: 11px;
  font-weight: normal;
}
.cell-button-resize-button {
  background-repeat: no-repeat;
  border-radius: 2px;
  cursor: pointer;
  height: 20px;
  margin: 0 0 0 5px;
  width: 20px;
}

.cell-h1,
.cell-h2,
.cell-h3,
.cell-h4,
.cell-h5,
.cell-h6 {
  font-family: "Roboto", "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}
.cell-h1 {
  font-size: 33px;
  font-weight: normal;
  height: auto;
  line-height: 1.5;
  margin-bottom: 2px;
  text-transform: uppercase;
}
.cell-h3 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
}
.cell-h2 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 2px;
}
.cell-h4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  padding-bottom: 2px;
  text-transform: uppercase;
}
.cell-h5 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  padding-bottom: 2px;
  text-transform: uppercase;
}

.cell-input {
  border-style: solid;
  border-width: 1px;
  font-family: inherit;
  font-size: 11px;
  max-width: 100%;
  padding: 3px 4px;
  vertical-align: top;
}
.cell-input--right {
  margin: 0 0 0 5px;
}

.cell-select {
  border: 0;
  font-family: inherit;
  font-size: 11px;
  max-width: 100%;
  padding: 0;
  margin: 0;
  vertical-align: top;
}

select.cell-input {
  padding-left: 0;
  padding-right: 0;
}
select.cell-select {
  cursor: pointer;
  padding-left: 15px;
  padding-right: 0;
  position: relative;
  z-index: 0;
}

.cell-input--250px,
.cell-width--250px {
  width: 250px;
}

.cell-input--220px,
.cell-width--220px {
  width: 220px;
}

.cell-input--200px,
.cell-width--200px {
  width: 200px;
}

.cell-input--190px,
.cell-width--190px {
  width: 190px;
}

.cell-input--175px,
.cell-width--175px {
  width: 175px;
}

.cell-input--150px,
.cell-width--150px {
  width: 150px;
}

.cell-input--145px,
.cell-width--145px {
  width: 145px;
}

.cell-input--120px,
.cell-width--120px {
  width: 120px;
}

.cell-input--100px,
.cell-width--100px {
  width: 100px;
}

.cell-input--90px,
.cell-width--90px {
  width: 90px;
}

.cell-input--80px,
.cell-width--80px {
  width: 80px;
}

.cell-input--40px,
.cell-width--40px {
  width: 40px;
}

.cell-input--100pct,
.cell-width--100pct {
  width: 100%;
}

.cell-input--80pct,
.cell-width--80pct {
  width: 80%;
}

.cell-input--30pct,
.cell-width--30pct {
  width: 30%;
}

.cell-input--dropdown {
  padding-left: 2px;
}

.cell-label-colon:after {
  content: ":";
}
@media screen and (max-width: 39.99875em) {
  .cell-label-dropdown-arrow {
    position: relative;
  }
  .cell-label-dropdown-arrow:after {
    border-bottom-width: 5px;
    border-left-color: transparent !important;
    border-left-width: 4px;
    border-right-color: transparent !important;
    border-right-width: 4px;
    border-style: solid;
    border-top: 0;
    content: "";
    height: 0;
    margin-left: 5px;
    margin-right: 0;
    position: absolute;
    top: 8px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 0;
  }
}

.cell-ul {
  display: block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.cell-list-flex {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  list-style: outside none none;
  margin: 0 0 10px 0;
  padding: 0;
}
.cell-list-flex > li {
  margin-right: 5px;
}
.cell-list-flex > li:last-of-type {
  margin: 0;
}
.cell-list-flex--collapse > li {
  margin-right: 0;
}
.cell-list-inline > li {
  float: left;
  margin-right: 5px;
  position: static;
}
.cell-list-inline > li:last-of-type {
  margin: 0;
}
.cell-list-inline-block > li {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
}
.cell-list-inline-block > li:last-of-type {
  margin: 0;
}
.cell-list-block > li {
  clear: both;
  float: none;
}
.cell-list-floated > li {
  float: left;
}
.cell-list-inner {
  list-style: inside;
  margin: 0;
  padding: 0;
}
.cell-list-bullets {
  margin-bottom: 15px;
}
.cell-list-bullets > li::before {
  content: "•";
  padding-right: 0.5em;
}
.cell-list-definition {
  display: table;
}
.cell-list-definition dt {
  display: table-cell;
}
.cell-list-definition dd {
  display: table-cell;
}
.cell-list--collapse > li {
  margin: 0;
}

.cell-p {
  display: block;
  font-size: 11px;
  line-height: 1.2;
  margin: 7px 0;
  width: 100%;
}

.cell-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cell-flex-wrapper {
  clear: both;
}
.cell-flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-direction {
  -webkit-box-orient: initial;
  -webkit-box-direction: initial;
  -ms-flex-direction: initial;
  flex-direction: initial;
}
.cell-flex-direction--column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.cell-flex--center-align {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-flex--end-align {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.cell-flex-justify--space-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.cell-flex-justify--flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.cell-flex-justify--space-evenly {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}
.cell-flex-justify--space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cell-flex-row {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.cell-flex-row .cell-flex-row {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .cell-flex-row .cell-flex-row {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cell-flex-row .cell-flex-row {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
}
.cell-flex-row .cell-flex-row.cell-flex-collapse {
  margin-left: 0;
  margin-right: 0;
}
.cell-flex-row.cell-flex-expanded {
  max-width: none;
}
.cell-flex-row.cell-flex-expanded .cell-flex-row {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.cell-flex-row:not(.cell-flex-expanded) .cell-flex-row {
  max-width: none;
}
.cell-flex-row.cell-flex-collapse > .cell-flex-column {
  padding-left: 0;
  padding-right: 0;
}
.cell-flex-row.cell-flex-formatted > .cell-flex-column {
  padding-left: 4px;
  padding-right: 4px;
}
.cell-flex-row.cell-flex-formatted > .cell-flex-column:first-of-type {
  padding-left: 0;
}
.cell-flex-row.cell-flex-formatted > .cell-flex-column:last-of-type {
  padding-right: 0;
}
.cell-flex-row > .cell-flex-column.cell-flex-collapse {
  padding-left: 0;
  padding-right: 0;
}
.cell-flex-row.is-collapse-child,
.cell-flex-row.cell-flex-collapse > .cell-flex-column > .cell-flex-row {
  margin-left: 0;
  margin-right: 0;
}

.cell-flex-column {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 40em) {
  .cell-flex-column {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.cell-flex-column.cell-flex-row.cell-flex-row {
  display: block;
  float: none;
}
.cell-flex-column-pull-right {
  margin-left: auto;
}

.cell-flex-row .cell-flex-column.cell-flex-row.cell-flex-row {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.cell-flex-small-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.cell-flex-small-offset-0 {
  margin-left: 0%;
}

.cell-flex-small-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.cell-flex-small-offset-1 {
  margin-left: 8.3333333333%;
}

.cell-flex-small-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.cell-flex-small-offset-2 {
  margin-left: 16.6666666667%;
}

.cell-flex-small-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.cell-flex-small-offset-3 {
  margin-left: 25%;
}

.cell-flex-small-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.cell-flex-small-offset-4 {
  margin-left: 33.3333333333%;
}

.cell-flex-small-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.cell-flex-small-offset-5 {
  margin-left: 41.6666666667%;
}

.cell-flex-small-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.cell-flex-small-offset-6 {
  margin-left: 50%;
}

.cell-flex-small-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.cell-flex-small-offset-7 {
  margin-left: 58.3333333333%;
}

.cell-flex-small-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.cell-flex-small-offset-8 {
  margin-left: 66.6666666667%;
}

.cell-flex-small-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.cell-flex-small-offset-9 {
  margin-left: 75%;
}

.cell-flex-small-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.cell-flex-small-offset-10 {
  margin-left: 83.3333333333%;
}

.cell-flex-small-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.cell-flex-small-offset-11 {
  margin-left: 91.6666666667%;
}

.cell-flex-small-up-1 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-1 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.cell-flex-small-up-2 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-2 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.cell-flex-small-up-3 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-3 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.cell-flex-small-up-4 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-4 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.cell-flex-small-up-5 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-5 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.cell-flex-small-up-6 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-6 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.cell-flex-small-up-7 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-7 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 14.2857142857%;
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.cell-flex-small-up-8 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.cell-flex-small-up-8 > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.cell-flex-collapse > .cell-flex-column {
  padding-left: 0;
  padding-right: 0;
}

.cell-flex-small-collapse > .cell-flex-column {
  padding-left: 0;
  padding-right: 0;
}

.cell-flex-small-uncollapse > .cell-flex-column {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

@media print, screen and (min-width: 40em) {
  .cell-flex-medium-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cell-flex-medium-offset-0 {
    margin-left: 0%;
  }
  .cell-flex-medium-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cell-flex-medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .cell-flex-medium-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cell-flex-medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .cell-flex-medium-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cell-flex-medium-offset-3 {
    margin-left: 25%;
  }
  .cell-flex-medium-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cell-flex-medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .cell-flex-medium-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-flex-medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .cell-flex-medium-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cell-flex-medium-offset-6 {
    margin-left: 50%;
  }
  .cell-flex-medium-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cell-flex-medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .cell-flex-medium-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cell-flex-medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .cell-flex-medium-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cell-flex-medium-offset-9 {
    margin-left: 75%;
  }
  .cell-flex-medium-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cell-flex-medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .cell-flex-medium-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cell-flex-medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .cell-flex-medium-up-1 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-1 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cell-flex-medium-up-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-2 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-flex-medium-up-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-3 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cell-flex-medium-up-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-4 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cell-flex-medium-up-5 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-5 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .cell-flex-medium-up-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-6 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cell-flex-medium-up-7 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-7 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .cell-flex-medium-up-8 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-medium-up-8 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .cell-flex-medium-expand {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
  }
}
.cell-flex-row.cell-flex-medium-unstack > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
@media print, screen and (min-width: 40em) {
  .cell-flex-row.cell-flex-medium-unstack > .cell-flex-column {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
  }
}

.cell-flex-row.cell-flex-unstack > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media print, screen and (min-width: 40em) {
  .cell-flex-collapse > .cell-flex-column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .cell-flex-medium-collapse > .cell-flex-column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media print, screen and (min-width: 40em) {
  .cell-flex-medium-uncollapse > .cell-flex-column {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
@media print, screen and (min-width: 64em) {
  .cell-flex-large-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .cell-flex-large-offset-0 {
    margin-left: 0%;
  }
  .cell-flex-large-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cell-flex-large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .cell-flex-large-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cell-flex-large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .cell-flex-large-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cell-flex-large-offset-3 {
    margin-left: 25%;
  }
  .cell-flex-large-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .cell-flex-large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .cell-flex-large-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-flex-large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .cell-flex-large-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .cell-flex-large-offset-6 {
    margin-left: 50%;
  }
  .cell-flex-large-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .cell-flex-large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .cell-flex-large-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .cell-flex-large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .cell-flex-large-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .cell-flex-large-offset-9 {
    margin-left: 75%;
  }
  .cell-flex-large-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .cell-flex-large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .cell-flex-large-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cell-flex-large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .cell-flex-large-up-1 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-1 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .cell-flex-large-up-2 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-2 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .cell-flex-large-up-3 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-3 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .cell-flex-large-up-4 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-4 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .cell-flex-large-up-5 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-5 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .cell-flex-large-up-6 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-6 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .cell-flex-large-up-7 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-7 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 14.2857142857%;
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .cell-flex-large-up-8 {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .cell-flex-large-up-8 > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .cell-flex-large-expand {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
  }
}
.cell-flex-row.cell-flex-large-unstack > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
@media print, screen and (min-width: 64em) {
  .cell-flex-row.cell-flex-large-unstack > .cell-flex-column {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 0px;
    flex: 1 1 0px;
  }
}

.cell-flex-row.cell-flex-unstack > .cell-flex-column {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}

@media print, screen and (min-width: 64em) {
  .cell-flex-collapse > .cell-flex-column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .cell-flex-large-collapse > .cell-flex-column {
    padding-left: 0;
    padding-right: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .cell-flex-large-uncollapse > .cell-flex-column {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
}
.cell-flex-shrink {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}

.cell-flex-column-block {
  margin-bottom: 1.25rem;
}
.cell-flex-column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .cell-flex-column-block {
    margin-bottom: 1.25rem;
  }
  .cell-flex-column-block > :last-child {
    margin-bottom: 0;
  }
}

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.cell-chart-wrapper {
  /* Titles */
  /* Axes */
  /* Credits */
  /* Tooltip */
  /* Legend hover affects points and series */
  /* Series options */
  /* Default colors */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* WT-300 the "rect" part is required for the legend (at least in pie chart) to indicate the correct series colors! */
  /* Legend */
  /* Bubble legend */
  /* Loading */
  /* Plot bands and polar pane backgrounds */
  /* Highcharts More and modules */
  /* Highstock */
  /* Highmaps */
  /* 3d charts */
  /* Exporting module */
  /* Drilldown module */
  /* No-data module */
  /* Drag-panes module */
  /* Bullet type series */
  /* Lineargauge type series */
  /* Annotations module */
  /* Gantt */
}
.cell-chart-wrapper .highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0; /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Roboto", "Open Sans", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cell-chart-wrapper .highcharts-root {
  display: block;
}
.cell-chart-wrapper .highcharts-root text {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-strong {
  font-weight: bold;
}
.cell-chart-wrapper .highcharts-emphasized {
  font-style: italic;
}
.cell-chart-wrapper .highcharts-anchor {
  cursor: pointer;
}
.cell-chart-wrapper .highcharts-background {
  fill: transparent;
}
.cell-chart-wrapper .highcharts-plot-border,
.cell-chart-wrapper .highcharts-plot-background {
  fill: none;
}
.cell-chart-wrapper .highcharts-label-box {
  fill: none;
}
.cell-chart-wrapper .highcharts-button-box {
  fill: inherit;
}
.cell-chart-wrapper .highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}
.cell-chart-wrapper .highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-title {
  fill: #333333;
  font-size: 1.5em;
}
.cell-chart-wrapper .highcharts-subtitle {
  fill: #666666;
}
.cell-chart-wrapper .highcharts-axis-line {
  fill: none;
  stroke: #ccd6eb;
}
.cell-chart-wrapper .highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-axis-title {
  fill: #666666;
}
.cell-chart-wrapper .highcharts-axis-labels {
  fill: #666666;
  cursor: default;
  font-size: 0.9em;
}
.cell-chart-wrapper .highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
}
.cell-chart-wrapper .highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}
.cell-chart-wrapper .highcharts-tick {
  stroke: #ccd6eb;
}
.cell-chart-wrapper .highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-minor-grid-line {
  stroke: #f2f2f2;
}
.cell-chart-wrapper .highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #cccccc;
}
.cell-chart-wrapper .highcharts-crosshair-category {
  stroke: #ccd6eb;
  stroke-opacity: 0.25;
}
.cell-chart-wrapper .highcharts-credits {
  cursor: pointer;
  fill: #999999;
  font-size: 0.7em;
  -webkit-transition: fill 250ms, font-size 250ms;
  transition: fill 250ms, font-size 250ms;
}
.cell-chart-wrapper .highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}
.cell-chart-wrapper .highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  -webkit-transition: stroke 150ms;
  transition: stroke 150ms;
}
.cell-chart-wrapper .highcharts-tooltip text {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}
.cell-chart-wrapper .highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #f7f7f7;
  fill-opacity: 0.85;
}
.cell-chart-wrapper .highcharts-tooltip-box .highcharts-label-box {
  fill: #f7f7f7;
  fill-opacity: 0.85;
}
.cell-chart-wrapper div.highcharts-tooltip {
  -webkit-filter: none;
  filter: none;
}
.cell-chart-wrapper .highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}
.cell-chart-wrapper .highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.cell-chart-wrapper .highcharts-empty-series {
  stroke-width: 1px;
  fill: none;
  stroke: #cccccc;
}
.cell-chart-wrapper .highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}
.cell-chart-wrapper .highcharts-point-inactive {
  opacity: 0.2;
  -webkit-transition: opacity 50ms;
  transition: opacity 50ms; /* quick in */
}
.cell-chart-wrapper .highcharts-series-inactive {
  opacity: 0.2;
  -webkit-transition: opacity 50ms;
  transition: opacity 50ms; /* quick in */
}
.cell-chart-wrapper .highcharts-state-hover path {
  -webkit-transition: stroke-width 50ms;
  transition: stroke-width 50ms; /* quick in */
}
.cell-chart-wrapper .highcharts-state-normal path {
  -webkit-transition: stroke-width 250ms;
  transition: stroke-width 250ms; /* slow out */
}
.cell-chart-wrapper g.highcharts-series,
.cell-chart-wrapper .highcharts-point,
.cell-chart-wrapper .highcharts-markers,
.cell-chart-wrapper .highcharts-data-labels {
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}
.cell-chart-wrapper .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.cell-chart-wrapper .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.cell-chart-wrapper .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.cell-chart-wrapper .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}
.cell-chart-wrapper .highcharts-color-0,
.cell-chart-wrapper .highcharts-color-0 rect {
  fill: #4dc46d;
  stroke: #4dc46d;
}
.cell-chart-wrapper .highcharts-color-1,
.cell-chart-wrapper .highcharts-color-1 rect {
  fill: #2196f3;
  stroke: #2196f3;
}
.cell-chart-wrapper .highcharts-color-2,
.cell-chart-wrapper .highcharts-color-2 rect {
  fill: #e95c3b;
  stroke: #e95c3b;
}
.cell-chart-wrapper .highcharts-color-3,
.cell-chart-wrapper .highcharts-color-3 rect {
  fill: #fbc246;
  stroke: #fbc246;
}
.cell-chart-wrapper .highcharts-color-4,
.cell-chart-wrapper .highcharts-color-4 rect {
  fill: #3cd0ce;
  stroke: #3cd0ce;
}
.cell-chart-wrapper .highcharts-color-5,
.cell-chart-wrapper .highcharts-color-5 rect {
  fill: #805e9d;
  stroke: #805e9d;
}
.cell-chart-wrapper .highcharts-color-6,
.cell-chart-wrapper .highcharts-color-6 rect {
  fill: #d069ae;
  stroke: #d069ae;
}
.cell-chart-wrapper .highcharts-color-7,
.cell-chart-wrapper .highcharts-color-7 rect {
  fill: #8b272d;
  stroke: #8b272d;
}
.cell-chart-wrapper .highcharts-color-8,
.cell-chart-wrapper .highcharts-color-8 rect {
  fill: #2e7d32;
  stroke: #2e7d32;
}
.cell-chart-wrapper .highcharts-color-9,
.cell-chart-wrapper .highcharts-color-9 rect {
  fill: #154d65;
  stroke: #154d65;
}
.cell-chart-wrapper .highcharts-color-10,
.cell-chart-wrapper .highcharts-color-10 rect {
  fill: #029e6a;
  stroke: #029e6a;
}
.cell-chart-wrapper .highcharts-color-11,
.cell-chart-wrapper .highcharts-color-11 rect {
  fill: #ff5a5a;
  stroke: #ff5a5a;
}
.cell-chart-wrapper .highcharts-color-12,
.cell-chart-wrapper .highcharts-color-12 rect {
  fill: #5969bf;
  stroke: #5969bf;
}
.cell-chart-wrapper .highcharts-color-13,
.cell-chart-wrapper .highcharts-color-13 rect {
  fill: #00888c;
  stroke: #00888c;
}
.cell-chart-wrapper .highcharts-color-14,
.cell-chart-wrapper .highcharts-color-14 rect {
  fill: #cc0000;
  stroke: #cc0000;
}
.cell-chart-wrapper .highcharts-color-15,
.cell-chart-wrapper .highcharts-color-15 rect {
  fill: #3be767;
  stroke: #3be767;
}
.cell-chart-wrapper .highcharts-color-16,
.cell-chart-wrapper .highcharts-color-16 rect {
  fill: #26a69a;
  stroke: #26a69a;
}
.cell-chart-wrapper .highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-markers {
  stroke-width: 1px;
  stroke: transparent;
}
.cell-chart-wrapper
  .highcharts-a11y-markers-hidden
  .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
.cell-chart-wrapper .highcharts-a11y-marker-hidden {
  opacity: 0;
}
.cell-chart-wrapper .highcharts-point {
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}
.cell-chart-wrapper .highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-data-label text,
.cell-chart-wrapper text.highcharts-data-label {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-data-label-connector {
  fill: none;
}
.cell-chart-wrapper .highcharts-data-label-hidden {
  pointer-events: none;
}
.cell-chart-wrapper .highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.cell-chart-wrapper .highcharts-markers .highcharts-point-select {
  fill: #cccccc;
  stroke: #000000;
}
.cell-chart-wrapper .highcharts-column-series rect.highcharts-point {
  stroke: transparent;
}
.cell-chart-wrapper .highcharts-column-series .highcharts-point {
  -webkit-transition: fill-opacity 250ms;
  transition: fill-opacity 250ms;
}
.cell-chart-wrapper .highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  -webkit-transition: fill-opacity 50ms;
  transition: fill-opacity 50ms;
}
.cell-chart-wrapper .highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: transparent;
}
.cell-chart-wrapper .highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  -webkit-transition: fill-opacity 50ms;
  transition: fill-opacity 50ms;
}
.cell-chart-wrapper .highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: transparent;
}
.cell-chart-wrapper .highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  -webkit-transition: fill-opacity 50ms;
  transition: fill-opacity 50ms;
}
.cell-chart-wrapper .highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
.cell-chart-wrapper .highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: transparent;
}
.cell-chart-wrapper .highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  -webkit-transition: fill-opacity 50ms;
  transition: fill-opacity 50ms;
}
.cell-chart-wrapper .highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}
.cell-chart-wrapper .highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #e6e6e6;
  -webkit-transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}
.cell-chart-wrapper .highcharts-treemap-series .highcharts-point-hover {
  stroke: #999999;
  -webkit-transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}
.cell-chart-wrapper .highcharts-treemap-series .highcharts-above-level {
  display: none;
}
.cell-chart-wrapper .highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}
.cell-chart-wrapper .highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}
.cell-chart-wrapper .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}
.cell-chart-wrapper .highcharts-vector-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}
.cell-chart-wrapper .highcharts-windbarb-series .highcharts-point {
  fill: none;
  stroke-width: 2px;
}
.cell-chart-wrapper .highcharts-lollipop-stem {
  stroke: #000000;
}
.cell-chart-wrapper .highcharts-focus-border {
  fill: none;
  stroke-width: 2px;
}
.cell-chart-wrapper .highcharts-legend-item-hidden .highcharts-focus-border {
  fill: none !important;
}
.cell-chart-wrapper .highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-legend-item > text {
  fill: #333333;
  font-weight: bold;
  font-size: 11px;
  cursor: pointer;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-legend-item:hover text {
  fill: #000000;
}
.cell-chart-wrapper .highcharts-legend-item-hidden * {
  fill: #cccccc !important;
  stroke: #cccccc !important;
  -webkit-transition: fill 250ms;
  transition: fill 250ms;
}
.cell-chart-wrapper .highcharts-legend-nav-active {
  fill: #003399;
  cursor: pointer;
}
.cell-chart-wrapper .highcharts-legend-nav-inactive {
  fill: #cccccc;
}
.cell-chart-wrapper circle.highcharts-legend-nav-active,
.cell-chart-wrapper circle.highcharts-legend-nav-inactive {
  /* tracker */
  fill: rgba(192, 192, 192, 0.0001);
}
.cell-chart-wrapper .highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}
.cell-chart-wrapper .highcharts-bubble-legend-connectors {
  stroke-width: 1;
}
.cell-chart-wrapper .highcharts-bubble-legend-labels {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-loading {
  position: absolute;
  background-color: transparent;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  -webkit-transition: opacity 250ms;
  transition: opacity 250ms;
}
.cell-chart-wrapper .highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: opacity 250ms, height 250ms step-end;
  transition: opacity 250ms, height 250ms step-end;
}
.cell-chart-wrapper .highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}
.cell-chart-wrapper .highcharts-plot-band,
.cell-chart-wrapper .highcharts-pane {
  fill: #000000;
  fill-opacity: 0.05;
}
.cell-chart-wrapper .highcharts-plot-line {
  fill: none;
  stroke: #999999;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-boxplot-box {
  fill: transparent;
}
.cell-chart-wrapper .highcharts-boxplot-median {
  stroke-width: 2px;
}
.cell-chart-wrapper .highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}
.cell-chart-wrapper .highcharts-errorbar-series .highcharts-point {
  stroke: #000000;
}
.cell-chart-wrapper .highcharts-gauge-series .highcharts-data-label-box {
  stroke: #cccccc;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-gauge-series .highcharts-dial {
  fill: #000000;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-waterfall-series .highcharts-graph {
  stroke: #333333;
  stroke-dasharray: 1, 3;
}
.cell-chart-wrapper .highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-sankey-series .highcharts-link {
  -webkit-transition: fill 250ms, fill-opacity 250ms;
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}
.cell-chart-wrapper .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  -webkit-transition: fill 50ms, fill-opacity 50ms;
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}
.cell-chart-wrapper .highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #cccccc;
  -webkit-transition: stroke 250ms, fill-opacity 250ms;
  transition: stroke 250ms, fill-opacity 250ms;
}
.cell-chart-wrapper .highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #cccccc;
}
.cell-chart-wrapper .highcharts-navigator-mask-outside {
  fill-opacity: 0;
}
.cell-chart-wrapper .highcharts-navigator-mask-inside {
  fill: rgba(166, 188, 198, 0.5); /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}
.cell-chart-wrapper .highcharts-navigator-outline {
  stroke: #cccccc;
  fill: none;
}
.cell-chart-wrapper .highcharts-navigator-handle {
  stroke: #cccccc;
  fill: #f2f2f2;
  cursor: ew-resize;
}
.cell-chart-wrapper .highcharts-navigator-series {
  fill: rgba(59, 231, 103, 0.3);
  stroke: rgba(59, 231, 103, 0.3);
}
.cell-chart-wrapper .highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}
.cell-chart-wrapper .highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #e6e6e6;
}
.cell-chart-wrapper .highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #999999;
}
.cell-chart-wrapper .highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-scrollbar-thumb {
  fill: #cccccc;
  stroke: #cccccc;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-scrollbar-button {
  fill: #e6e6e6;
  stroke: #cccccc;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-scrollbar-arrow {
  fill: #666666;
}
.cell-chart-wrapper .highcharts-scrollbar-rifles {
  stroke: #666666;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-scrollbar-track {
  fill: #f2f2f2;
  stroke: #f2f2f2;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-button {
  fill: #f7f7f7;
  stroke: #cccccc;
  cursor: default;
  stroke-width: 1px;
  -webkit-transition: fill 250ms;
  transition: fill 250ms;
}
.cell-chart-wrapper .highcharts-button text {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-button-hover {
  -webkit-transition: fill 0ms;
  transition: fill 0ms;
  fill: #e6e6e6;
  stroke: #cccccc;
}
.cell-chart-wrapper .highcharts-button-hover text {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-button-pressed {
  font-weight: bold;
  fill: #e6ebf5;
  stroke: #cccccc;
}
.cell-chart-wrapper .highcharts-button-pressed text {
  fill: #333333;
  font-weight: bold;
}
.cell-chart-wrapper .highcharts-button-disabled text {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}
.cell-chart-wrapper .highcharts-range-label rect {
  fill: none;
}
.cell-chart-wrapper .highcharts-range-label text {
  fill: #666666;
}
.cell-chart-wrapper .highcharts-range-input rect {
  fill: none;
}
.cell-chart-wrapper .highcharts-range-input text {
  fill: #333333;
}
.cell-chart-wrapper .highcharts-range-input {
  stroke-width: 1px;
  stroke: #cccccc;
}
.cell-chart-wrapper input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px; /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em; /* #4798 */
}
.cell-chart-wrapper .highcharts-crosshair-label text {
  fill: transparent;
  font-size: 1.1em;
}
.cell-chart-wrapper .highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}
.cell-chart-wrapper .highcharts-candlestick-series .highcharts-point {
  stroke: #000000;
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-candlestick-series .highcharts-point-up {
  fill: transparent;
}
.cell-chart-wrapper .highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}
.cell-chart-wrapper .highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #999999;
  fill: transparent;
  -webkit-transition: fill 250ms;
  transition: fill 250ms;
}
.cell-chart-wrapper .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #000000;
  fill: #ccd6eb;
}
.cell-chart-wrapper .highcharts-flags-series .highcharts-point text {
  fill: #000000;
  font-size: 0.9em;
  font-weight: bold;
}
.cell-chart-wrapper .highcharts-map-series .highcharts-point {
  -webkit-transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #cccccc;
}
.cell-chart-wrapper .highcharts-map-series .highcharts-point-hover {
  -webkit-transition: fill 0ms, fill-opacity 0ms;
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}
.cell-chart-wrapper .highcharts-mapline-series .highcharts-point {
  fill: none;
}
.cell-chart-wrapper .highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}
.cell-chart-wrapper .highcharts-coloraxis {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-coloraxis-marker {
  fill: #999999;
}
.cell-chart-wrapper .highcharts-null-point {
  fill: #f7f7f7;
}
.cell-chart-wrapper .highcharts-3d-frame {
  fill: transparent;
}
.cell-chart-wrapper .highcharts-contextbutton {
  fill: transparent; /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}
.cell-chart-wrapper .highcharts-contextbutton:hover {
  fill: #e6e6e6;
  stroke: #e6e6e6;
}
.cell-chart-wrapper .highcharts-button-symbol {
  stroke: #666666;
  stroke-width: 3px;
}
.cell-chart-wrapper .highcharts-menu {
  border: 1px solid #999999;
  background: transparent;
  padding: 5px 0;
  -webkit-box-shadow: 3px 3px 10px #888;
  box-shadow: 3px 3px 10px #888;
}
.cell-chart-wrapper .highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #333333;
  cursor: pointer;
  -webkit-transition: background 250ms, color 250ms;
  transition: background 250ms, color 250ms;
}
.cell-chart-wrapper .highcharts-menu-item:hover {
  background: #335cad;
  color: transparent;
}
.cell-chart-wrapper .highcharts-drilldown-point {
  cursor: pointer;
}
.cell-chart-wrapper .highcharts-drilldown-data-label text,
.cell-chart-wrapper text.highcharts-drilldown-data-label,
.cell-chart-wrapper .highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #003399;
  font-weight: bold;
  text-decoration: underline;
}
.cell-chart-wrapper .highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #666666;
}
.cell-chart-wrapper .highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}
.cell-chart-wrapper .highcharts-bullet-target {
  stroke-width: 0;
}
.cell-chart-wrapper .highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #333333;
}
.cell-chart-wrapper .highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #333333;
}
.cell-chart-wrapper .highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #000000;
  fill: #000000;
  fill-opacity: 0.75;
}
.cell-chart-wrapper .highcharts-annotation-label text {
  fill: #e6e6e6;
}
.cell-chart-wrapper .highcharts-treegrid-node-collapsed,
.cell-chart-wrapper .highcharts-treegrid-node-expanded {
  cursor: pointer;
}
.cell-chart-wrapper .highcharts-point-connecting-path {
  fill: none;
}
.cell-chart-wrapper .highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}
.cell-chart-wrapper .highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}

/*! autoprefixer grid: autoplace */
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* .cell-row {
   &--flex {
     display: flex;
     justify-content: space-between;
   }
 }

 .cell-table {
   // tbody specific
   tbody {
     // tbody general
     tr {
       white-space: nowrap;
       &:hover td {
         opacity: 0.4;
       }
       td {
         > * {
           vertical-align: middle;
         }
         > span {
           display: inline-block;
           &[class*="--whitespace"] {
             white-space: pre;
           }
         }
       }
     }

     // tbody main item specific
     &.cell-table__tbody--main {
       * {
         font-weight: 700;
       }
       tr {
         background-color:  #08678d;
         td:first-child {
           border-radius: 3px 0 0 3px;
         }
         td:last-child {
           border-radius: 0 3px 3px 0;
         }
       }
     }

     // tbody items specific
     &.cell-table__tbody--list {
       tr {
         &:nth-child(even) {
           background-color:  #07394D;
           td:first-child {
             border-radius: 3px 0 0 3px;
           }
           td:last-child {
             border-radius: 0 3px 3px 0;
           }
         }
       }
     }
   }

   &__txt {
     text-align: left;
   }

   &__num {
     text-align: center;
   }

   &__custom {
     text-align: left;

     &--score-span {
       margin-right: 2px;
     }
     &--scoring-boxes-span {
       display: inline-block;
       background-color: #0F9BD9;
       width: 10px;
       height: 10px;
       margin: 0px 2px;
     }
   }

 }*/
.cell-alert-searchbox .cell-input {
  width: 100%;
}
.cell-alert-row-label {
  margin-bottom: 3px;
}
.cell-alert-row-label--horizontal {
  float: left;
  margin: 10px 0 0;
}
.cell-alert-row-switch {
  float: right;
  margin-right: 10px;
}
.cell-alert-list-mini {
  border-style: solid;
  border-width: 0 1px 1px 1px;
  width: 343px;
}
.cell-alert-list-mini--hidden {
  visibility: hidden;
}
.cell-alert-list-mini__alert-container {
  position: relative;
  width: 100%;
}
.cell-alert-list-mini__alert-container--expanded > .cell-alert-list-mini__options-container {
  display: block;
  height: 63px;
  -webkit-transition: height ease-in 0.2s;
  transition: height ease-in 0.2s;
}
.cell-alert-list-mini-row {
  padding: 2px;
  position: relative;
  width: 100%;
}
.cell-alert-list-mini-row--no-alerts {
  display: none;
  height: 62px;
  padding: 25px 0;
  width: 343px;
}
.cell-alert-list-mini-row--no-alerts--active {
  display: block;
  font-style: italic;
  opacity: 0.6;
  text-align: center;
  visibility: visible;
}
.cell-alert-list-mini-row__item {
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.cell-alert-list-mini-row__item--option {
  padding: 7px 0 8px 8px;
  text-transform: uppercase;
}
.cell-alert-list-mini-row__item--flag {
  padding: 8px 0 0 9px;
}
.cell-alert-list-mini-row__item--ticker {
  font-size: 14px;
  font-weight: bold;
  padding: 8px 0 0 1px;
}
.cell-alert-list-mini-row__item--condition {
  padding: 2px 0 10px 8px;
}
.cell-alert-list-mini-row__item--recurrence {
  padding: 8px 13px 4px;
}
.cell-alert-list-mini-row__item--comment {
  font-style: italic;
  height: 27px;
  opacity: 0.5;
  padding: 7px 0 8px 8px;
}
.cell-alert-list-mini__options-container {
  height: 0;
  overflow: hidden;
  -webkit-transition: height ease-in 0.2s;
  transition: height ease-in 0.2s;
  width: 100%;
}
.cell-alert-list-mini-togglebutton {
  background-position: left 4px top 4px;
  background-repeat: no-repeat;
  bottom: 2px;
  cursor: pointer;
  display: block;
  height: 26px;
  padding: 0;
  position: absolute;
  right: 2px;
  width: 28px;
}
.cell-alert-list-mini-togglebutton::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 7px;
}
.cell-alert-list-mini--func {
  cursor: pointer;
  float: left;
  max-width: 35%;
  padding: 7px 10px 8px 10px;
}

.cell-custom-dropdown-selector {
  max-height: 288px;
  min-width: 70px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.cell-custom-dropdown-selector::after {
  bottom: 0;
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  width: 100%;
}
.cell-custom-dropdown-selector-scroller,
.cell-custom-dropdown-selector-scroller__upper {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.cell-custom-dropdown-selector-scroller {
  max-height: 258px;
}
.cell-custom-dropdown-selector-scroller__upper {
  max-height: 228px;
}
.cell-custom-dropdown-selector__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #dddddd;
  border: 0 solid #dddddd;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  float: left;
  height: 30px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.cell-custom-dropdown-selector__item__isAuto {
  position: relative;
}
.cell-custom-dropdown-selector__item:last-child {
  border-bottom: 0;
}
.cell-custom-dropdown-selector__item--selected:hover,
.cell-custom-dropdown-selector__item--selected:hover {
  background-color: #cccccc;
}
.cell-custom-dropdown-selector__item__label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: 100%;
  padding: 0 7px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-custom-dropdown-selector__item__label--flash-news-only {
  font-weight: bold;
}
.cell-custom-dropdown-selector__item__label--unselect-select {
  font-weight: bold;
}
.cell-custom-dropdown-selector__item__label--unavailable {
  color: #0b5874;
}
.cell-custom-dropdown-selector__item__label--unavailable:hover {
  background-color: #00374f;
}
.cell-custom-dropdown-selector__item__label--has-children {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 25px;
}
.cell-custom-dropdown-selector__item__label--has-children span {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}
.cell-custom-dropdown-selector__item__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  height: 100%;
  padding-left: 7px;
  width: 100%;
}
.cell-custom-dropdown-selector__item__button {
  font-size: 13px;
  font-weight: bold;
  padding-top: 6px;
}
.cell-custom-dropdown-selector__button {
  border: 1px solid #cccccc;
  float: left;
  padding: 5px;
}
.cell-custom-dropdown-selector__button--right {
  margin-left: auto;
  margin-right: 5px;
}
.cell-custom-dropdown-selector__button-tray {
  background-color: #dddddd;
  border: 0;
  -webkit-box-shadow: 0 -5px 10px -6px rgba(0, 10, 13, 0.4);
  box-shadow: 0 -5px 10px -6px rgba(0, 10, 13, 0.4);
  cursor: pointer;
  float: left;
  font-size: 13px;
  font-weight: bold;
  height: 40px;
  overflow: hidden;
  padding: 8px 7px 0;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.cell-custom-dropdown-selector__button-tray .cell-button--cancel {
  margin-left: 6px;
}
.cell-custom-dropdown-selector-wrapper {
  background-color: #000a0d;
  border: 0;
  -webkit-box-shadow: 0 2px 5px 1px rgba(0, 10, 13, 0.85);
  box-shadow: 0 2px 5px 1px rgba(0, 10, 13, 0.85);
  min-width: 260px;
  position: absolute;
  right: 0;
  width: 260px;
  z-index: 3;
}
.cell-custom-dropdown-selector-wrapper--adjusted {
  width: auto;
}
.cell-custom-dropdown-selector-wrapper--indicators {
  width: 185px;
}
.cell-custom-dropdown-selector-wrapper--chart-type {
  width: 105px;
}
.cell-custom-dropdown-selector-wrapper--time-period {
  width: 125px;
}
.cell-custom-dropdown-selector-wrapper--time-period > .cell-custom-dropdown-selector > .cell-custom-dropdown-selector__item {
  padding: 0;
  width: 33.33333333%;
}
.cell-custom-dropdown-selector-wrapper--time-period
  > .cell-custom-dropdown-selector
  > .cell-custom-dropdown-selector__item
  > .cell-custom-dropdown-selector__item__label {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.cell-custom-dropdown-selector-wrapper--time-period
  > .cell-custom-dropdown-selector
  > .cell-custom-dropdown-selector__item
  > .cell-custom-dropdown-selector__item__label::after {
  background: transparent;
}
.cell-custom-dropdown-selector-wrapper--resolution {
  width: 100px;
}

.cell-custom-popup {
  height: inherit;
  overflow: auto;
  padding: 10px 34px;
}
.cell-custom-popup-wrapper {
  overflow-y: initial;
}
.cell-custom-popup-wrapper-260px {
  height: 100px;
  position: absolute;
  width: 260px;
  z-index: 10;
}

.cell-custom-tooltip {
  position: relative;
  display: block;
}
.cell-custom-tooltip .cell-custom-tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.cell-custom-tooltip:hover .cell-custom-tooltip-text {
  visibility: visible;
}

.cell-dropdown-list {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
}
.cell-dropdown-list-icon-button {
  width: 20px;
  padding: 5px 0 0 5px;
}
.cell-dropdown-list__label {
  height: 16px;
  overflow: hidden;
}
.cell-dropdown-list__label-icon {
  display: inline-block;
  vertical-align: middle;
}
.cell-dropdown-list__label span {
  display: inline-block;
  line-height: 16px;
  vertical-align: middle;
}
.cell-dropdown-list__label::after {
  visibility: hidden;
  content: "";
  height: 4px;
  padding: 0;
  position: absolute;
  right: 0;
  width: 4px;
  top: 50%;
}
.cell-dropdown-list__label-icon .icon-svg {
  width: 16px;
  height: 16px;
}
.cell-dropdown-list__row {
  width: 100%;
  display: inline-block;
  white-space: nowrap;
}
.cell-dropdown-list__row span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cell-dropdown-list__content {
  min-width: 180px;
  position: absolute;
  right: 0;
  width: 180px;
  z-index: 3;
}
.cell-dropdown-list__content--adjusted {
  width: auto;
}
.cell-dropdown-list__content--indicators {
  width: 185px;
}
.cell-dropdown-list__content--indicators .cel-dropdown-list__item-label {
  position: relative;
}
.cell-dropdown-list__content--indicators .cel-dropdown-list__item-label::after {
  content: "";
  height: 20px;
  position: absolute;
  right: 3px;
  width: 20px;
}
.cell-dropdown-list__content--chart-type {
  width: 105px;
}
.cell-dropdown-list__content--time-period {
  width: 125px;
}
.cell-dropdown-list__content--time-period > .cell-dropdown-list__selector > .cell-dropdown-list__selector__item {
  padding: 0;
  width: 33.33333333%;
}
.cell-dropdown-list__content--time-period
  > .cell-dropdown-list__selector
  > .cell-dropdown-list__selector__item
  > .cell-dropdown-list__selector__item-label {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.cell-dropdown-list__content--time-period
  > .cell-dropdown-list__selector
  > .cell-dropdown-list__selector__item
  > .cell-dropdown-list__selector__item-label::after {
  background: transparent;
}
.cell-dropdown-list__content--resolution {
  width: 100px;
}
.cell-dropdown-list__content-item {
  padding: 6px 7px 5px;
  height: 28px;
  position: relative;
}
.cell-dropdown-list__content-item--expanded .cell-dropdown-list__content-item-icon {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cell-dropdown-list__content-item--separator-label {
  line-height: 14px;
  font-weight: bold;
}
.cell-dropdown-list__content-item-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cell-dropdown-list__content-item-icon .icon-svg {
  height: 16px;
}
.cell-dropdown-list__content-item-icon,
.cell-dropdown-list__content-item-label {
  display: inline-block;
  vertical-align: middle;
}
.cell-dropdown-list__content-selector {
  border: 0;
  padding: 5px;
  overflow: auto;
}
.cell-dropdown-list__content-selector-label {
  float: left;
  margin-top: 1px;
}
.cell-dropdown-list__content-selector-button {
  cursor: pointer;
  border: none;
  float: right;
  height: 16px;
  width: 16px;
}
.cell-dropdown-list__content-selector-wrapper--indicators .cell-dropdown-list-selector__item__label {
  position: relative;
}
.cell-dropdown-list__content-selector-wrapper--indicators .cell-dropdown-list-selector__item__label::after {
  content: "";
  height: 20px;
  position: absolute;
  right: 3px;
  width: 20px;
}
.cell-dropdown-list__content-selector__item__label {
  position: relative;
}
.cell-dropdown-list__content-selector__item__label::after {
  content: "";
  height: 20px;
  position: absolute;
  right: 4px;
  top: 5px;
  width: 20px;
}
.cell-dropdown-list__content-selector__item__label--selected {
  position: relative;
}
.cell-dropdown-list__content-selector__item__label--selected::after {
  content: "";
  height: 20px;
  position: absolute;
  right: 4px;
  top: 5px;
  width: 20px;
}
.cell-dropdown-list__content-selector__item__header--close-button::after {
  content: "";
  height: 20px;
  position: absolute;
  right: 4px;
  width: 20px;
}
.cell-dropdown-list__content-selector__item__header--back-button {
  background-position: top 2px right 2px;
  background-repeat: no-repeat;
  background-size: 20px;
}
.cell-dropdown-list__content-selector__item__header__icon--add-button {
  height: 20px;
  width: 20px;
}
.cell-dropdown-list__content-selector__item__header__text {
  padding: 0 8px;
}
.cell-dropdown-list__content .cell-list-block {
  margin: 0;
}

*[class^="cell"],
*[class^=" cell"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1;
}

.cell {
  float: left;
  min-height: 1px;
  padding: 0 5px;
}
.cell:first-of-type {
  padding-left: 0;
}
.cell:last-of-type {
  padding-right: 0;
}
.cell-wrapper {
  margin: 0 auto;
  min-width: 600px;
  width: 85%;
}
.cell-row {
  float: left;
  margin-bottom: 10px;
  width: 100%;
}
.cell-content {
  padding: 12px;
}
.cell-flex-content {
  padding: 12px 0;
}
.cell-content,
.cell-flex-content {
  float: left;
  height: 100%;
  overflow: hidden;
  width: 100%;
  position: relative;
}
.cell--w12 {
  width: 100%;
}
.cell--w10 {
  width: 83.333333%;
}
.cell--w9 {
  width: 75%;
}
.cell--w8 {
  width: 66.666666%;
}
.cell--w7 {
  width: 58.333333%;
}
.cell--w6 {
  width: 50%;
}
.cell--w5 {
  width: 41.666667%;
}
.cell--w4 {
  width: 33.333333%;
}
.cell--w3 {
  width: 25%;
}
.cell--w2 {
  width: 16.666667%;
}
.cell--w1 {
  width: 8.333333%;
}

.cell-portfolio-select__label,
.cell-portfolio-select__title {
  border-left: 1px solid #777777;
}
.cell-portfolio-select__label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-portfolio-select__title--interaction {
  cursor: pointer;
}
.cell-portfolio-select__title--interaction:hover {
  text-decoration: underline;
}
.cell-portfolio-select__inline-wrapper {
  display: inline-block;
  margin-bottom: -2px;
}
.cell-portfolio-select__scaling-button {
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: 1em;
  font-weight: inherit;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media screen and (max-width: 39.99875em) {
  .cell-portfolio-select__scaling-button {
    display: block;
    width: 100%;
    text-align: left;
  }
}
.cell-portfolio-select__scaling-wrapper {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.cell-portfolio-select__scaling__dd {
  text-align: left;
  width: 100%;
}
.cell-portfolio-select__scaling__dd-wrapper {
  -webkit-box-shadow: 0 2px 5px 1px rgba(0, 10, 13, 0.85);
  box-shadow: 0 2px 5px 1px rgba(0, 10, 13, 0.85);
  display: none;
  margin-top: 2px;
  position: absolute;
  z-index: 800;
}
.cell-portfolio-select__scaling__dd__label {
  cursor: pointer;
  padding: 5px 6px 3px;
  text-align: left;
}

.cell-preloader {
  border: 0 solid #dddddd;
  height: 10px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 10;
}
.cell-preloader__box {
  border: 0 solid #dddddd;
  float: left;
  height: 10px;
  margin: 2px;
  width: 10px;
}

.cell-chart-default {
  position: relative;
}
.cell-chart-wrapper {
  float: left;
  height: 100%;
  position: relative;
  width: 100%;
}
.cell-chart__toolbar {
  margin: 0;
  padding: 5px 5px 1px 5px;
  width: 100%;
}
.cell-chart__toolbar.empty {
  display: none;
}
.cell-chart-actionline {
  margin-right: 30px;
}
.cell-chart-resize-button {
  background-repeat: no-repeat;
  border-radius: 2px;
  cursor: pointer;
  height: 20px;
  margin: 0 0 0 5px;
  width: 20px;
}
.cell-chart-maximized {
  height: 80vh;
  left: 10vw;
  position: fixed;
  top: 10vh;
  width: 80vw;
}
.cell-chart-maximized_background {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 90;
}
.cell-chart__label-wrapper {
  float: left;
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 1;
}
.cell-chart__label-element {
  clear: left;
  margin: 0;
  padding: 0;
}
.cell-chart__label-element__label {
  float: left;
  font-weight: 700;
  height: 15px;
  line-height: 1.5;
  margin-bottom: 5px;
  opacity: 1;
  padding: 0 0 0 3px;
}
.cell-chart__label-element__label__color_box {
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}
.cell-chart__label-element__label__text_box {
  float: left;
  font-size: 11px;
  font-weight: 700;
  height: 18px;
  line-height: 17px;
  margin-bottom: 5px;
  opacity: 1;
  padding: 0 4px;
}
.cell-chart__label-element__label__text_box > .cell-arrow {
  float: right;
  margin-top: 5px;
}
.cell-chart__label-element__inactive {
  cursor: default;
}
.cell-chart__label-element__values {
  float: left;
  padding: 3px 0 0 5px;
}
.cell-chart__label-element__values__element:not(:empty) {
  margin-left: 2px;
  margin-right: 4px;
}
.cell-chart__label-separate-axis {
  left: 10px;
  position: absolute;
}
.cell-chart__last-banner {
  position: absolute;
  z-index: 1;
}
.cell-chart__last-banner__label {
  padding: 2px;
}
.cell-chart__series-label-wrapper > .cell-chart__label-element:first-child > .cell-chart__label-element__label {
  font-size: 12px;
  height: 18px;
  line-height: 1.4;
}
.cell-chart__series-label-wrapper > .cell-chart__label-element:first-child > .cell-chart__label-element__label > .cell-arrow {
  margin-top: 7px;
}
.cell-chart-label-element__edit-button {
  float: right;
  margin: 2px 5px 0 0;
}
.cell-chart-label-element__remove-button {
  float: right;
  height: 15px;
  margin: 2px 5px 0 0;
  padding: 0;
  width: 14px;
}
.cell-chart-default {
  font-size: 10px;
}
.cell-chart-default text {
  font-size: 10px !important;
}
.cell-chart-default .highcharts-background {
  fill: transparent;
}
.cell-chart-default .highcharts-title {
  font-size: 1.5em;
}
.cell-chart-default .highcharts-legend-item {
  font-size: 11px;
  text-transform: uppercase;
}
.cell-chart-default .highcharts-axis-line {
  fill: none;
}
.cell-chart-default .highcharts-axis .highcharts-axis-line {
  stroke-width: 0;
}
.cell-chart-default .highcharts-axis-labels {
  cursor: default;
}
.cell-chart-default .highcharts-axis-labels tspan {
  cursor: default;
}
.cell-chart-default .highcharts-axis-labels text {
  cursor: default;
}
.cell-chart-default .highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 1;
}
.cell-chart-default .highcharts-yaxis .highcharts-tick {
  stroke-width: 1;
}
.cell-chart-default .highcharts-yaxis-grid .highcharts-grid-line {
  stroke-width: 1;
}
.cell-chart-default .cell-chart-default__volume-axis.highcharts-yaxis-grid .highcharts-grid-line {
  stroke-width: 0;
}
.cell-chart-default__separate-pane-odd .highcharts-grid-line {
  stroke: none;
}
.cell-chart-default-series--volume .highcharts-point {
  stroke: none;
}
.cell-chart-default .highcharts-crosshair-thin {
  stroke-width: 1px;
}
.cell-chart-default .highcharts-crosshair-category {
  stroke-width: 1px;
}
.cell-chart-default .highcharts-tooltip {
  border-radius: 0;
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
}
.cell-chart-default .highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}
.cell-chart-default .highcharts-area {
  fill-opacity: 0.25;
  stroke-width: 1px;
}
.cell-chart-default .highcharts-candlestick-series .highcharts-point {
  stroke-width: 1px;
}
.cell-chart-default .highcharts-ohlc-series .highcharts-point {
  stroke-width: 1;
}
.cell-chart-default .highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 2px;
}
.cell-chart-default .highcharts-navigator-mask-inside {
  cursor: ew-resize;
}
.cell-chart-default .highcharts-navigator-outline {
  fill: none;
}
.cell-chart-default .highcharts-navigator-handle {
  cursor: ew-resize;
}
.cell-chart-default .highcharts-navigator-series .highcharts-graph {
  stroke-width: 2px;
}
.cell-chart-default .highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}
.cell-chart-default .highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
}
.cell-chart-default .highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}
.cell-chart-default-series--atr .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--bb1 .highcharts-graph {
  stroke-dasharray: 1, 3;
  stroke-width: 2;
}
.cell-chart-default-series--bb2 .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--bb3 .highcharts-graph {
  stroke-dasharray: 1, 3;
  stroke-width: 2;
}
.cell-chart-default-series--ema .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--macd1 .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--macd2 .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--rsi .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--so1 .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--so2 .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--sma .highcharts-graph {
  stroke-width: 2;
}
.cell-chart-default-series--wma .highcharts-graph {
  stroke-width: 2;
}
.cell-chart__edit-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.cell-chart__edit-box {
  height: auto;
  position: absolute !important;
  width: 214px;
  z-index: 90;
}
.cell-chart__edit-box .cell-button--confirm {
  float: left;
  margin-top: 5px;
  width: 48%;
}
.cell-chart__edit-box .cell-button--cancel {
  float: right;
  margin-top: 5px;
  width: 48%;
}
.cell-chart__edit-box--centered {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cell-chart__edit-box__header {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  width: 100%;
}
.cell-chart__edit-box__field-select-label {
  margin-bottom: 3px;
  margin-top: 7px;
}
.cell-chart__edit-box__period-element {
  float: left;
  padding: 3px 0;
  width: 100%;
}
.cell-chart__edit-box__period-element__name {
  float: left;
  font-size: 11px;
  height: 20px;
  line-height: 20px;
}
.cell-chart__edit-box__period-element__value {
  float: right;
  font-size: 11px;
  height: 20px;
  margin-right: 0;
  width: 50px;
}
.cell-chart__edit-box__color_box {
  cursor: pointer;
  float: right;
  height: 20px;
  width: 50px;
}
.cell-chart__edit-box__submit_button {
  display: inline-block;
  padding: 10px;
}
.cell-chart__edit-box__color_table {
  float: left;
  margin: 5px 0 0 -2px;
  width: 100%;
}
.cell-chart__edit-box__color_table td {
  height: 30px;
  padding: 2px;
  width: 30px;
}
.cell-chart-indicator-custom {
  border-radius: 50%;
  cursor: pointer;
  height: 26px;
  margin: 1px auto;
  width: 26px;
}
.cell-chart__static-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
}
.cell-chart__static-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.188rem;
  padding: 0 1rem 0 0;
  white-space: nowrap;
}
.cell-chart__static-values {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
}
.cell-chart__static-values > div {
  font-size: 0.875rem;
  padding: 0 1rem 0 0;
  white-space: nowrap;
}

.highcharts-chart-watermark text {
  cursor: default;
  dominant-baseline: text-before-edge;
  font-weight: 900;
  opacity: 0.4;
  text-transform: uppercase;
}
.highcharts-exporting-group {
  display: none;
}

.cell-flex-list__expand-icon {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-repeat: no-repeat;
  border-color: #555555;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.cell-flex-list__row {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.cell-flex-list__row-wrapper {
  float: left;
  width: 100%;
}
.cell-flex-list__row--expanded {
  border: 0;
}
.cell-flex-list__row--header--offset-left::before {
  content: "";
  width: 20px;
}
.cell-flex-list__row__field {
  display: inline-block;
  padding: 7px 2px;
}
.cell-flex-list__row__field--footer,
.cell-flex-list__row__field--header {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 75px;
  flex: 1 1 75px;
  padding: 7px 0;
}
.cell-flex-list__row__field-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cell-flex-list__row__field-wrapper--full-source {
  float: left;
}
.cell-flex-list__row__field-wrapper--time {
  float: left;
}
.cell-flex-list__row__field-wrapper--heading {
  cursor: pointer;
  float: left;
  line-height: 1.2;
  overflow: hidden;
}
.cell-flex-list__row__field-wrapper--short-source {
  text-align: right;
}
.cell-flex-list__row__field--full-name--header,
.cell-flex-list__row__field-wrapper--full-name {
  -webkit-box-flex: 2;
  -ms-flex: 2 1 75px;
  flex: 2 1 75px;
}
.cell-flex-list__row__field--tips > .fund_tip_button {
  background: #1cb841;
  color: #ffffff;
  font-size: 11px;
  padding: 5px 7px;
  text-decoration: none;
  width: 40px;
}
.cell-flex-list__row__field--choose > .funds_choose_button {
  background: #3498db;
  border-radius: 2px;
  color: #ffffff;
  font-size: 11px;
  padding: 5px 7px;
  text-decoration: none;
  width: 40px;
}
.cell-flex-list__row__label {
  display: none;
}
.cell-flex-list__item {
  cursor: pointer;
  font-size: 1rem;
}
.cell-flex-list__item--expanded .cell-flex-list__item-extension {
  display: block;
}
.cell-flex-list__item-symbol,
.cell-flex-list__item-last-valid {
  margin-right: 5px;
}
.cell-flex-list__item-last-valid,
.cell-flex-list__item-percentage-change {
  font-size: 0.8125rem;
}
.cell-flex-list__item-extension {
  display: none;
}
.cell-flex-list__item-extension-row {
  font-size: 0.875rem;
  padding: 10px 0;
}
.cell-flex-list__item-extension-row .cell-button {
  font-size: 0.875rem;
  font-weight: normal;
  height: 28px;
}
.cell-flex-list__item-instrument {
  position: relative;
}
.cell-flex-list__item-instrument::after {
  content: "";
  height: 4px;
  position: absolute;
  right: 0;
  top: 0px;
  width: 4px;
}
.cell-flex-list__item-header {
  padding: 10px 0 0 0;
}
.cell-flex-list__item-content {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 0;
  text-align: center;
}
.cell-flex-list__item-label {
  display: inline-block;
  font-size: 14px;
  line-height: normal;
  margin-right: 5px;
}
.cell-flex-list__item-label--buy,
.cell-flex-list__item-label--sell,
.cell-flex-list__item-label--net {
  text-transform: uppercase;
}
.cell-flex-list__item-value {
  padding: 3px;
}
.cell-flex-list__no-data {
  left: 50%;
  position: absolute;
  text-transform: uppercase;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.cell-flex-news-list__first-row {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  padding: 2px 0;
}
.cell-flex-news-list__second-row {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.cell-flex-news-list__row {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-bottom: 1px solid #555555;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 3px;
}

.cell-funds-filter-list-wrapper {
  margin: 2px 0;
  -webkit-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
}
.cell-funds-filter-list-search--input {
  margin-right: 0;
}
.cell-funds-filter-list-search--input::-webkit-input-placeholder {
  font-size: 13px;
  line-height: 28px;
}
.cell-funds-filter-list-search--input::-moz-placeholder {
  font-size: 13px;
  line-height: 28px;
}
.cell-funds-filter-list-search--input:-ms-input-placeholder {
  font-size: 13px;
  line-height: 28px;
}
.cell-funds-filter-list-search--input::-ms-input-placeholder {
  font-size: 13px;
  line-height: 28px;
}
.cell-funds-filter-list-search--input::placeholder {
  font-size: 13px;
  line-height: 28px;
}
.cell-funds-filter-list-search--label {
  display: none;
}
.cell-funds-filter-list--search {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  font-size: 13px;
  margin-top: 5px;
}
.cell-funds-filter__header-wrapper {
  cursor: pointer;
  -webkit-transition: background-color 0.5s ease-out;
  transition: background-color 0.5s ease-out;
  width: 100%;
}
.cell-funds-filter--header {
  display: inline-block;
  font-size: 13px;
  height: 30px;
  line-height: 30px;
  padding: 0 7px;
}
.cell-funds-filter--groups {
  padding: 0;
}
.cell-funds-filter__group {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  padding: 5px 3px 5px 7px;
  width: 100%;
}
.cell-funds-filter__group__component {
  cursor: pointer;
  display: inline-block;
}
.cell-funds-filter__group__component--total-count {
  border-radius: 50%;
  float: right;
  font-size: 11px;
  height: 23px;
  padding: 5px 0;
  text-align: center;
  width: 23px;
}
.cell-funds-filter__range-wrapper {
  display: block;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
}
.cell-funds-filter__range-wrapper .cell-funds-filter__range-holder {
  position: relative;
}
.cell-funds-filter__range-wrapper .cell-range-filter-bullets-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  z-index: 2;
}
.cell-funds-filter__range-wrapper .cell-range-filter-bullets-container .cell-range-filter__group__component__range-bullet {
  cursor: pointer;
  height: 4px;
  margin-top: -4px;
  width: 4px;
}
.cell-funds-filter__range-wrapper .cell-range-filter-bullets-container .cell-range-filter__group__component--filter-name {
  cursor: pointer;
  float: left;
  margin-left: -23px;
  margin-top: 12px;
  position: absolute;
  text-align: center;
  width: 50px;
}
.cell-funds-filter__range-background {
  display: block;
  height: 4px;
  margin-top: -4px;
  width: 100%;
}
.cell-funds-filter__range-selected-background {
  display: block;
  height: 4px;
  margin-top: -4px;
  width: 50%;
}
.cell-funds-filter__range-bullet {
  border-radius: 50%;
  cursor: pointer;
  height: 15px;
  left: -5.5px;
  position: absolute;
  top: -5.5px;
  width: 15px;
  z-index: 3;
}
.cell-funds-filter--groups-wrapper--expanded {
  display: block;
  -webkit-transition: height ease-out 0.2s;
  transition: height ease-out 0.2s;
}
.cell-funds-filter--groups-wrapper {
  height: 0;
  overflow: hidden;
  -webkit-transition: height ease-out 0.2s;
  transition: height ease-out 0.2s;
  width: 100%;
}
.cell-funds-filter--groups-wrapper.cell-funds-filter--groups-wrapper--expanded {
  height: auto;
  overflow: hidden;
}
.cell-funds-filter--total-selected-filters {
  padding-left: 3px;
}
.cell-funds-filter--total-selected-filters::before {
  content: "(";
}
.cell-funds-filter--total-selected-filters::after {
  content: ")";
}
.cell-funds-filter--expand-arrow {
  float: right;
  height: 30px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 30px;
}
.cell-funds-filter--expand-arrow__icon {
  background-position: center;
  background-repeat: no-repeat;
  float: right;
  height: 30px;
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  width: 30px;
}
.cell-funds-filter--expand-arrow__icon--expanded {
  -webkit-transform: rotateZ(-135deg);
  transform: rotateZ(-135deg);
}

.cell-fx-arrow--fade-out {
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  opacity: 0;
}

@-webkit-keyframes cell-fx-arrow__key-frame--fade-out {
  0% {
    opacity: 1;
  }
}

@keyframes cell-fx-arrow__key-frame--fade-out {
  0% {
    opacity: 1;
  }
}
.cell-fx-carousel-indicator__dot {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
  width: 10px;
}
.cell-fx-carousel-indicator__dot--in-view {
  opacity: 1;
}
.cell-fx-carousel-indicator__dot--out-of-view {
  opacity: 0.4;
}

.cell-fx-currency-selector {
  min-height: 20px;
  min-width: 80px;
}
.cell-fx-currency-selector__table {
  border-spacing: 0;
  min-height: 20px;
  min-width: 80px;
  padding: 7px;
}
.cell-fx-currency-selector__group-heading {
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 5px;
}
.cell-fx-currency-selector__group-separator {
  height: 1px;
  margin: 5px 0 10px 0;
}
.cell-fx-currency-selector__currency-cell {
  padding: 1px;
}
.cell-fx-currency-selector__currency-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row, nowrap;
  flex-flow: row, nowrap;
  font-size: 11px;
  font-weight: normal;
  padding: 1px;
}
.cell-fx-currency-selector__currency-container:hover {
  cursor: pointer;
}
.cell-fx-currency-selector__currency-text {
  position: relative;
  top: 1px;
}
.cell-fx-currency-selector__currency-flag {
  margin: 1px 2px !important;
}
.cell-fx-currency-selector__currency-flag--not-found {
  background-position: 0 0 !important;
}
.cell-fx-currency-selector__currency--remove {
  background-color: transparent;
  height: 20px;
  position: absolute;
  right: 0;
  top: 1px;
  width: 20px;
}
.cell-fx-currency-selector__currency--remove:hover {
  cursor: pointer;
}
.cell-fx-currency-selector__currency--incompatible {
  opacity: 0.7;
}

.cell-fx-drop-down__container {
  display: inline-block;
  left: 0;
  margin-top: 2px;
  position: absolute;
  top: 0;
  z-index: 1000;
}

.cell-fx-numeric__placeholder {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  visibility: hidden;
}

.cell-fx-progress-steps__container {
  position: relative;
  width: 100%;
}
.cell-fx-progress-steps__step-container {
  position: absolute;
}
.cell-fx-progress-steps__step-tail {
  float: left;
}
.cell-fx-progress-steps__step-body {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  float: left;
}
.cell-fx-progress-steps__step-title {
  z-index: 1;
}
.cell-fx-progress-steps__step-head {
  float: left;
}
.cell-fx-progress-steps__step-animation {
  position: absolute;
}

.cell-fx-tenor-selector {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 220px;
}
.cell-fx-tenor-selector__title {
  font-size: 12px;
  font-weight: bold;
  padding: 8px 8px 0 8px;
  text-transform: uppercase;
}
.cell-fx-tenor-selector__separator {
  height: 1px;
  margin: 3px 0;
}
.cell-fx-tenor-selector__switch-table {
  border-spacing: 0;
  padding: 0 8px;
  width: 100%;
}
.cell-fx-tenor-selector__switch-table-scroller {
  max-height: 200px;
  overflow: hidden;
  position: relative;
}
.cell-fx-tenor-selector__tenor-switch-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 4px;
}
.cell-fx-tenor-selector__tenor-switch-box > .cell-w-switch {
  padding: 6px 0;
}
.cell-fx-tenor-selector__control-buttons-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 5px;
  padding: 0 8px 8px 8px;
}
.cell-fx-tenor-selector__control-button {
  width: 50%;
}

.cell-heading-dropdown {
  cursor: pointer;
  float: left;
  position: relative;
}
.cell-heading-dropdown__label-full-width {
  height: 100%;
  width: 100%;
}
.cell-heading-dropdown--arrow {
  border-color: #555555;
  display: inline-block;
  margin-top: 4px;
}
.cell-heading-dropdown__arrow {
  border-color: #555555;
  display: inline-block;
  margin-top: 4px;
}
.cell-heading-dropdown--multi-selection {
  border: 0;
  border-radius: 2px;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
  margin: 0 0 5px 5px;
  padding: 5px 4px 0 6px;
}

.cell-instrument-values--horizontal td,
.cell-instrument-values--horizontal th {
  text-align: center;
}
.cell-instrument-values--vertical tr:first-child td {
  border-top-width: 0;
}
.cell-instrument-values--vertical__value.cell-table__txt {
  text-align: right;
}

.cell-loginbox {
  left: 50%;
  margin: 0;
  padding: 40px 50px 35px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 106;
}
.cell-loginbox--thin {
  padding: 40px 26px 35px;
}
@media screen and (max-width: 39.99875em) {
  .cell-loginbox {
    -webkit-box-shadow: 0 0 0 200px rgba(0, 10, 13, 0.8);
    box-shadow: 0 0 0 200px rgba(0, 10, 13, 0.8);
    position: absolute;
    width: calc(100% - 30px);
  }
}
.cell-loginbox input:focus {
  outline: 0;
}
.cell-loginbox .cell-h3 {
  font-size: 20px;
  font-weight: normal;
  text-align: center;
}
.cell-loginbox__label {
  display: block;
  font-size: 14px;
  height: 23px;
  line-height: 23px;
  text-align: left;
}
.cell-loginbox__input,
.cell-loginbox__select {
  border: 1px solid #cccccc;
  font-size: 14px;
  height: 35px;
  padding: 9px;
  vertical-align: top;
}
.cell-loginbox__button {
  border: 0;
  border-radius: 2px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  font-family: inherit;
  font-size: 17px;
  font-weight: normal;
  margin: 0;
  padding: 12px 8px;
}
.cell-loginbox__input,
.cell-loginbox__select,
.cell-loginbox__button {
  width: 260px;
}
@media screen and (max-width: 39.99875em) {
  .cell-loginbox__input,
  .cell-loginbox__select,
  .cell-loginbox__button {
    width: 100%;
  }
}
.cell-loginbox__row {
  margin: 15px 0 0;
}
.cell-loginbox__message {
  background-color: #ff4b37;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 8px;
  margin-top: 15px;
}
.cell-loginbox__warning {
  font-size: 14px;
  padding: 8px 0 0 0;
  text-align: center;
  width: 100%;
}
.cell-loginbox__select {
  font-size: 0.875rem;
  padding-left: 4px;
}
.cell-loginbox__select,
.cell-loginbox__input {
  margin-bottom: 15px;
}
.cell-loginbox__session {
  position: relative;
  padding-top: 30px;
}
.cell-loginbox__session-label,
.cell-loginbox__session-reference {
  text-align: center;
}
.cell-loginbox__session-label {
  text-align: center;
  margin-bottom: 10px;
  font-size: 0.875rem;
}
.cell-loginbox__session-reference {
  font-weight: bold;
}
.cell-loginbox__session .cell-preloader {
  top: 5px;
}

.cell-mininav {
  display: inline-block;
  margin: 0;
}
.cell-mininav--chart {
  margin-top: 3px;
}
.cell-mininav__btn {
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  float: left;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
  margin: 0 0 0 5px;
  padding: 5px 4px 0 6px;
}
.cell-mininav__btn--disabled {
  cursor: default;
}
.cell-mininav__btn--disabled:hover {
  cursor: default;
}
.cell-mininav__btn--dd {
  width: 25px;
}
.cell-mininav__dd {
  display: block;
  overflow: hidden;
  position: absolute;
  top: 20px;
  width: 250px;
  z-index: 400;
}
.cell-mininav__dd__header {
  border: 0 solid #dddddd;
  cursor: pointer;
  height: 30px;
  overflow: hidden;
  padding: 0;
  position: relative;
  white-space: nowrap;
  width: 100%;
}
.cell-mininav__dd__header__label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  font-weight: bold;
  height: 100%;
  padding-left: 7px;
  width: 100%;
}
.cell-mininav__dd__func--edit {
  color: #ffffff;
  cursor: pointer;
  height: 21px;
  position: absolute;
  right: 20px;
  top: 0;
  width: 20px;
}
.cell-mininav__dd__func--delete {
  color: #ffffff;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}
.cell-mininav__dd__func--confirm,
.cell-mininav__dd__func--cancel {
  cursor: pointer;
  float: left;
  font-weight: bold;
  padding: 5px 0;
  text-align: center;
  width: 50%;
}
.cell-mininav__dd--left {
  left: 0;
}
.cell-mininav__dd--right {
  right: 0;
}
.cell-mininav__dd li:last-child {
  border-bottom: 0;
}
.cell-mininav__dd__label {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  padding: 0 8px;
  position: relative;
}
.cell-mininav__dd__table {
  width: 100%;
}
.cell-mininav__dd__table td {
  cursor: pointer;
  padding: 5px;
}

.cell-news-lightbox {
  height: 100%;
  min-height: 100%;
  position: relative;
}
.cell-news-pager-control {
  clear: both;
}
.cell-news-reader_header {
  font-size: 18px;
  font-weight: bold;
  padding: 0 0 5px 10px;
}
.cell-news-reader_toprow {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 0 5px 10px;
}
.cell-news-reader__source,
.cell-news-reader__date {
  display: inline-block;
}
.cell-news-reader__source {
  margin-right: 5px;
}
.cell-news-togglebutton {
  background-position: left 4px top 5px;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 26px;
  padding: 0;
  width: 26px;
}

.cell-notifications {
  bottom: 0;
  position: fixed;
}
.cell-notifications__row {
  background: #626262;
  border: 1px solid #555555;
  color: #ffffff;
  height: 33px;
  padding: 10px;
  position: relative;
}
.cell-notifications__row--date {
  float: left;
  opacity: 0.65;
}
.cell-notifications__row--message {
  float: left;
  margin-top: 13px;
  width: 100%;
}
.cell-notifications__row--close-button {
  cursor: pointer;
  height: 20px;
  opacity: 0.2;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
}
.cell-notifications__row--close-button::after {
  content: "";
  height: 18px;
  position: absolute;
  width: 18px;
}
.cell-notifications__row--close-button:hover {
  opacity: 1;
}

.cell-radio-filter__group {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 30px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 30px;
  padding: 5px 3px 5px 7px;
  width: 100%;
}
.cell-radio-filter__group__component {
  cursor: pointer;
  display: inline-block;
}
.cell-radio-filter__group__component--radio-button {
  display: inline-block;
  height: 13px;
  margin: 2px 4px;
  position: relative;
  width: 13px;
}
.cell-radio-filter__group__component--radio-button:before {
  background: #ffffff;
  border: 1px solid #000a0d;
  border-radius: 100%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  height: 13px;
  left: 0;
  position: absolute;
  top: 0;
  width: 13px;
}
.cell-radio-filter__group__component--radio-button:after {
  -webkit-transform: scale(0);
  -webkit-transition: all 0.2s ease;
  background: #555555;
  border-radius: 100%;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  content: "";
  height: 7px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: scale(0);
  transition: all 0.2s ease;
  width: 7px;
}
.cell-radio-filter__group__component--radio-button--selected:after {
  -webkit-transform: scale(1);
  opacity: 1;
  transform: scale(1);
}
.cell-radio-filter__group__component--radio-button + label {
  vertical-align: text-bottom;
}
.cell-radio-filter__group__component--total-count {
  border-radius: 50%;
  color: #ffffff;
  float: right;
  font-size: 11px;
  height: 23px;
  padding: 5px 0;
  text-align: center;
  width: 23px;
}
.cell-radio-filter__group__component--filter-name {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 7px;
}
.cell-radio-filter__group--selected {
  background-color: #555555;
}

.cell-search-result__wrapper {
  margin-top: 5px;
  max-height: 336px;
  overflow: hidden;
  width: 450px;
  z-index: 99;
}
.cell-search-result__item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000a0d;
  border-bottom: 1px solid #414141;
}
.cell-search-result__item:last-of-type {
  border-bottom: 0;
}
.cell-search-result__item-instrument {
  display: block;
  font-size: 10px;
  font-weight: bold;
  height: 30px;
  line-height: 30px;
  margin: 4px;
  padding-bottom: 1px;
  padding-left: 0;
  padding-right: 1px;
  text-align: center;
  width: 30px;
}
.cell-search-result__item-name {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-search-result__item--selected {
  background-color: #333333;
}
.cell-search-result--equity::before {
  content: "EQ";
}
.cell-search-result--bond::before {
  content: "BND";
}
.cell-search-result--feed::before {
  content: "MKT";
}
.cell-search-result--future::before {
  content: "FUT";
}
.cell-search-result--option::before {
  content: "OPT";
}
.cell-search-result--fund::before {
  content: "FND";
}
.cell-search-result--forex::before {
  content: "FX";
}
.cell-search-result--chain::before {
  content: "CHN";
}
.cell-search-result--index::before {
  content: "INX";
}
.cell-search-result--certificate::before {
  content: "CTF";
}
.cell-search-result--other::before {
  content: "-";
}

.cell-status-connection-ok {
  background-size: 100%;
  display: inline-block;
  height: 20px;
  width: 20px;
}
.cell-status-connection-fail {
  background-size: 100%;
  display: inline-block;
  height: 20px;
  width: 20px;
}

.cell-table th {
  font-size: 11px;
  font-weight: 700;
  padding: 7px 3px;
  text-transform: uppercase;
}
.cell-table th.cell-table__arrow,
.cell-table th.cell-cursor-pointer {
  padding-right: 10px;
}
.cell-table td {
  padding: 7px 3px;
  vertical-align: top;
}
.cell-table-wrapper {
  clear: both;
  overflow: auto;
}
.cell-table-fixed {
  position: relative;
  table-layout: fixed;
  white-space: nowrap;
}
.cell-table-fixed .cell-table th {
  position: sticky;
  top: 0;
  z-index: 1;
}
.cell-table-fixed td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-table--news-list__url-news:not(.cell-table__txt--news-date):not(.cell-table__txt--news-short-source) {
  position: relative;
}
.cell-table--financial-calendar__description {
  line-height: 1.15;
  margin: 2px 0 0 0;
}
.cell-table .cell-tablecell--interaction {
  cursor: pointer;
}
.cell-table .cell-tablecell--interaction--full-button {
  padding: 0;
  width: 26px;
}
.cell-table--net-trades td {
  width: 10%;
}
.cell-table--financial-calendar--itemheader {
  font-weight: bold;
  margin-bottom: 1px;
}
.cell-table__txt {
  text-align: left;
}
.cell-table__txt--news-heading {
  cursor: pointer;
  overflow: hidden;
  padding-left: 16px !important;
}
.cell-table__txt--news-heading:not(th):not(.cell-table--news-list__flash-news) {
  position: relative;
}
.cell-table__txt--news-heading__default-pointer {
  cursor: default;
}
.cell-table__txt--news-source {
  white-space: nowrap;
}
.cell-table__txt--news-short-source {
  text-align: right;
}
.cell-table__txt--investment-mandatev {
  line-height: 1.25;
}
.cell-table__img--flag-header {
  width: 16px;
}
.cell-table--historical-overview {
  width: 100%;
}
.cell-table--historical-overview__header-row td,
.cell-table--historical-overview__data-row td {
  padding: 4px;
  text-align: center;
}
.cell-table__cell--drag {
  position: relative;
}
.cell-table__cell--current-column {
  -webkit-transition: background-color 0.3s linear, border-color 0.3s linear;
  transition: background-color 0.3s linear, border-color 0.3s linear;
}
.cell-table__cell--next-column {
  border-right: 1px solid;
}
.cell-table__cell--prev-column {
  border-left: 1px solid;
}
.cell-table-editable th {
  position: relative;
}
.cell-table-editable th:hover {
  overflow: visible !important;
}
.cell-table-resizeable th {
  border-left: 1px solid transparent;
}
.cell-table-resizeable th:hover::after,
.cell-table-resizeable th:focus::after {
  content: "";
  height: var(--resizable-table-hight);
  left: -1px;
  position: absolute;
  width: 100%;
}
.cell-table-resizeable th:first-of-type:hover,
.cell-table-resizeable th:first-of-type:focus {
  border-left-color: transparent;
}
.cell-table-resizeable th:first-of-type:hover::after,
.cell-table-resizeable th:first-of-type:focus::after {
  border-left-color: transparent;
}
.cell-table-resizeable th:last-of-type:hover,
.cell-table-resizeable th:last-of-type:focus {
  border-right: 0;
}
.cell-table-resizeable th:last-of-type:hover::after,
.cell-table-resizeable th:last-of-type:focus::after {
  border-right: 0;
}
.cell-table-resizeable .cell-flex-list__row__field--header:hover {
  border-left: 1px solid;
  border-right: 1px solid;
  overflow: visible;
}
.cell-table__col--setting {
  cursor: pointer;
  display: none;
  height: 22px;
  padding: 1px 2px;
  position: absolute;
  right: 0;
  top: 20px;
  width: 60px;
  z-index: 1;
}
.cell-table__col--setting--choose-col {
  cursor: pointer;
  float: left;
  height: 20px;
  width: 20px;
}
.cell-table__col--setting--remove-col {
  float: left;
  height: 18px;
  width: 18px;
}
.cell-table__col--setting--reset-col {
  float: left;
  height: 18px;
  width: 18px;
}
.cell-table__col-sortable {
  position: relative;
}
.cell-table__col-sortable::before {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  position: absolute;
  right: -9px;
  top: 50%;
  width: 0;
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
.cell-table__col-sortable::after {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  bottom: 50%;
  content: "";
  height: 0;
  position: absolute;
  right: -9px;
  width: 0;
  -webkit-transform: translateY(4px);
  transform: translateY(4px);
}
.cell-table__col-sortable--ascending::before {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  position: absolute;
  right: -9px;
  top: 50%;
  width: 0;
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}
.cell-table__col-sortable--ascending::after {
  border: 0;
  content: "";
}
.cell-table__col-sortable--descending::before {
  border: 0;
  content: "";
}
.cell-table__col-sortable--descending::after {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  bottom: 50%;
  content: "";
  height: 0;
  position: absolute;
  right: -9px;
  width: 0;
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}
.cell-table__num {
  text-align: right;
}
.cell-table__fnc {
  text-align: center;
}
.cell-table .cell-sumrow td,
.cell-table .cell-sumrow td {
  border-bottom: 3px double #555555;
  border-top: 2px solid #555555;
}
.cell-table__deletebutton,
.cell-table__editbutton {
  display: block;
  height: 26px;
  -webkit-transition: background-color 0.35s ease-out;
  transition: background-color 0.35s ease-out;
  width: 26px;
}
.cell-table__arrow {
  text-align: left;
}
.cell-table__arrow-indicator {
  border-bottom-width: 5px;
  border-left-color: transparent;
  border-left-width: 4px;
  border-right-color: transparent;
  border-right-width: 4px;
  border-style: solid;
  border-top: 0;
  display: inline-block;
  margin-bottom: 2px;
}
.cell-table__arrow-hidden .cell-table__arrow-indicator {
  display: none;
}
.cell-table__arrow.cell-update-up .cell-table__arrow-indicator {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  visibility: visible;
}
.cell-table__arrow.cell-update-down .cell-table__arrow-indicator {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  visibility: visible;
}
.cell-table__arrow.cell-update-neutral .cell-table__arrow-indicator {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  visibility: visible;
}
.cell-table__arrow--arrow-last,
.cell-table__arrow--arrow-bid,
.cell-table__arrow--arrow-ask {
  text-align: right;
}

.cell-tabs {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 0 4px rgba(0, 10, 13, 0.4);
  box-shadow: 0 0 4px rgba(0, 10, 13, 0.4);
  display: none;
  min-width: 160px;
  padding: 5px;
  position: absolute;
  width: 160px;
  z-index: 700;
}
.cell-tabs__arrow::after {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 699;
}
.cell-tabs__arrow::before {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 698;
}
.cell-tabs__arrow--top-left::after {
  border-color: #ffffff transparent;
  border-width: 0 6px 6px;
  left: 12px;
  top: -6px;
}
.cell-tabs__arrow--top-left::before {
  border-color: #dddddd transparent;
  border-width: 0 7px 7px;
  left: 11px;
  top: -7px;
}
.cell-tabs__arrow--top-right::after {
  border-color: #ffffff transparent;
  border-width: 0 6px 6px;
  right: 12px;
  top: -6px;
}
.cell-tabs__arrow--top-right::before {
  border-color: #dddddd transparent;
  border-width: 0 7px 7px;
  right: 11px;
  top: -7px;
}
.cell-tabs__arrow--bottom-left::after {
  border-color: #ffffff transparent;
  border-width: 6px 6px 0;
  bottom: -6px;
  left: 12px;
}
.cell-tabs__arrow--bottom-left::before {
  border-color: #dddddd transparent;
  border-width: 7px 7px 0;
  bottom: -7px;
  left: 11px;
}
.cell-tabs__arrow--bottom-right::after {
  border-color: #ffffff transparent;
  border-width: 6px 6px 0;
  bottom: -6px;
  right: 12px;
}
.cell-tabs__arrow--bottom-right::before {
  border-color: #dddddd transparent;
  border-width: 7px 7px 0;
  bottom: -7px;
  right: 11px;
}
.cell-tabs__horizontal {
  float: left;
  margin: 1px 0 15px;
  padding: 5px 5px 0;
  width: 100%;
}
.cell-tabs__horizontal__tab {
  cursor: pointer;
  float: left;
  font-size: 0.75rem;
  margin-right: 6px;
  padding: 8px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.cell-tabs__horizontal__tab:last-child {
  margin-right: 0;
}
.cell-tabs__horizontal__tab--selected {
  margin-bottom: -1px;
}
.cell-tabs__horizontal__tab--mobile-phone {
  padding: 6px 8px 6px 8px;
  text-align: center;
}
.cell-tabs__horizontal__tab--password {
  padding: 6px 8px 6px 8px;
  text-align: center;
}
.cell-tabs__horizontal__tab__button {
  border: 0;
  cursor: pointer;
  float: left;
  height: 18px;
  padding: 3px;
  position: relative;
  width: 20px;
}

.cell-tooltip {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  -webkit-box-shadow: 0 0 4px rgba(0, 10, 13, 0.4);
  box-shadow: 0 0 4px rgba(0, 10, 13, 0.4);
  display: none;
  min-width: 160px;
  padding: 5px;
  position: absolute;
  width: 160px;
  z-index: 700;
}
.cell-tooltip__arrow::after {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 699;
}
.cell-tooltip__arrow::before {
  border-style: solid;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  z-index: 698;
}
.cell-tooltip__arrow--top-left::after {
  border-color: #ffffff transparent;
  border-width: 0 6px 6px;
  left: 12px;
  top: -6px;
}
.cell-tooltip__arrow--top-left::before {
  border-color: #dddddd transparent;
  border-width: 0 7px 7px;
  left: 11px;
  top: -7px;
}
.cell-tooltip__arrow--top-right::after {
  border-color: #ffffff transparent;
  border-width: 0 6px 6px;
  right: 12px;
  top: -6px;
}
.cell-tooltip__arrow--top-right::before {
  border-color: #dddddd transparent;
  border-width: 0 7px 7px;
  right: 11px;
  top: -7px;
}
.cell-tooltip__arrow--bottom-left::after {
  border-color: #ffffff transparent;
  border-width: 6px 6px 0;
  bottom: -6px;
  left: 12px;
}
.cell-tooltip__arrow--bottom-left::before {
  border-color: #dddddd transparent;
  border-width: 7px 7px 0;
  bottom: -7px;
  left: 11px;
}
.cell-tooltip__arrow--bottom-right::after {
  border-color: #ffffff transparent;
  border-width: 6px 6px 0;
  bottom: -6px;
  right: 12px;
}
.cell-tooltip__arrow--bottom-right::before {
  border-color: #dddddd transparent;
  border-width: 7px 7px 0;
  bottom: -7px;
  right: 11px;
}

.cell-shared-indicator {
  float: left;
  height: 88px;
  position: relative;
  width: 100%;
}
.cell-shared-indicator--small {
  height: 40px;
}
.cell-shared-indicator__up,
.cell-shared-indicator__down {
  margin: 0 0 0 50%;
  min-width: 2px;
  width: 30%;
}
.cell-shared-indicator__up {
  height: 50%;
}
.cell-shared-indicator__down {
  height: 50%;
}
.cell-shared-indicator__current {
  height: 0;
  left: 20%;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  width: 0;
}

.cell-overlay-root {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  width: 100%;
  z-index: 20;
}
.cell-overlay-fade {
  height: 100%;
  left: 0;
  opacity: 0.75;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 21;
}
.cell-overlay_lightbox {
  float: none;
  height: 90%;
  margin: 5% auto;
  position: relative;
  width: 90%;
  z-index: 99;
}
@media screen and (min-width: 64em) {
  .cell-overlay_lightbox {
    height: 80%;
    max-width: 1000px;
    min-width: 500px;
  }
}
.cell-overlay-lightbox__close-btn {
  background-size: 20px;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  z-index: 24;
}

@media screen and (max-width: 39.99875em) {
  .cell-modal {
    height: 100%;
    padding: 0;
    width: 100%;
  }
}
@media screen and (max-width: 39.99875em) {
  .cell-modal__my-list,
  .cell-modal__news-list {
    left: 0 !important;
    top: 0 !important;
  }
}

.cell-esg-errors {
  height: auto !important;
  width: 100%;
  font-weight: 500;
}
.cell-esg-errors-title {
  padding: 4px 0;
}
.cell-esg-errors-value {
  font-size: 20px;
  padding: 0 5px;
}
.cell-esg-errors--global {
  min-width: 200px;
}
.cell-esg-errors--details {
  min-width: 300px;
}
.cell-esg-errors--warning {
  background-image: url("../infrontUI/images/svg/warning-red.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
}

.cell-sdk-search-box {
  width: 100%;
  font-size: inherit;
}

.cell-w-active-filter {
  background-color: #1cb841;
  color: #ffffff;
  cursor: pointer;
  height: 46px;
  margin: 2px;
  padding: 7px;
  position: relative;
}
.cell-w-active-filter__header {
  font-size: 15px;
  margin-bottom: 3px;
}
.cell-w-active-filter__parameters {
  margin-bottom: 2px;
}
.cell-w-active-filter__parameters__element:hover {
  color: #2e7d32;
}
.cell-w-active-filter__parameters__element:not(:first-child)::before {
  content: ", ";
}
.cell-w-active-filter__remove-button {
  background-position: top 0 left 0;
  background-repeat: no-repeat;
  height: 18px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 18px;
}
.cell-w-active-filter__remove-button:hover {
  background-color: #2e7d32;
}

.cell-w-bond-trader {
  width: 100%;
}
.cell-w-bond-trader-input-container {
  float: left;
  margin: 6px 0 0;
  position: relative;
}
.cell-w-bond-trader__tabs {
  float: left;
  width: 100%;
}
.cell-w-bond-trader__tabs__tab {
  border: 1px solid #00374f;
  float: left;
  padding: 8px 0;
  text-align: center;
  width: 50%;
}
.cell-w-bond-trader__tabs__tab:last-child {
  border-left: 0;
}
.cell-w-bond-trader__tabs__tab--selected {
  background-color: #00374f;
}
.cell-w-bond-trader__panel {
  background-color: #001721;
  float: left;
  width: 100%;
}
.cell-w-bond-trader__panel__section {
  display: inline-block;
  height: auto;
  padding: 7px 0;
  width: 100%;
}
.cell-w-bond-trader__panel__section__item {
  display: none;
  width: 100%;
}
.cell-w-bond-trader__panel__section__item__name {
  font-size: 14px;
  font-weight: 600;
}
.cell-w-bond-trader__panel__section__item__instrument-fields {
  margin: 6px 0;
}
.cell-w-bond-trader__panel__section__item--instrument-info {
  min-height: 104px;
}
.cell-w-bond-trader__panel__section__name {
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 5px;
}
.cell-w-bond-trader__focus-mini {
  float: left;
  margin: 15px 0;
  width: 100%;
}
.cell-w-bond-trader__instrument__label {
  color: #555555;
  font-size: 11px;
  font-weight: 700;
}
.cell-w-bond-trader__instrument__value--forward-price {
  font-size: 30px;
}
.cell-w-bond-trader__instrument__value--spot-price {
  font-size: 30px;
}
.cell-w-bond-trader__instrument__value--clean-spot-price {
  font-size: 21px;
}
.cell-w-bond-trader__instrument__value--margin {
  font-size: 21px;
}
.cell-w-bond-trader__instrument__value--last-updated {
  top: 16px;
  position: relative;
}
.cell-w-bond-trader__instrument__value--manual-order {
  top: 16px;
  position: relative;
}

.cell-w-column-chooser__panel {
  position: relative;
  z-index: 100;
}
.cell-w-column-chooser__link {
  text-decoration: underline;
}
.cell-w-column-chooser__columns-list {
  max-height: 327px;
  min-height: 327px;
  padding: 0;
  position: relative;
}
.cell-w-column-chooser__category {
  margin: 5px 0 10px 5px;
}
.cell-w-column-chooser__category-title {
  border-style: solid;
  border-width: 1px;
  margin: 5px;
  padding: 5px;
}
.cell-w-column-chooser__category--collapsed > .cell-w-column-chooser__category-content {
  visibility: hidden;
}
.cell-w-column-chooser__category-content {
  margin: 5px 5px 5px 25px;
}
.cell-w-column-chooser__item {
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  margin: 5px;
  padding: 5px;
  position: relative;
  -webkit-transition: background-color 0.3s linear, border-color 0.3s linear;
  transition: background-color 0.3s linear, border-color 0.3s linear;
}
.cell-w-column-chooser__item .cell-list-inline-block {
  margin: 0;
}
.cell-w-column-chooser__item .cell-list-inline-block li {
  margin-right: 5px;
}
.cell-w-column-chooser__item--locked {
  height: 18px;
  left: calc(100% - 22px);
  position: absolute;
  top: calc(50% - 9px);
  width: 18px;
}
.cell-w-column-chooser__item--draggable {
  position: relative;
}
.cell-w-column-chooser__item-drag {
  float: left;
  margin-left: -2px;
  margin-right: 7px;
  margin-top: -3px;
  min-height: 18px;
  min-width: 18px;
}
.cell-w-column-chooser__item-hint::before {
  content: "(";
}
.cell-w-column-chooser__item-hint::after {
  content: ")";
}
.cell-w-column-chooser__content-col {
  margin-bottom: 15px;
  width: calc(50% - 50px);
}
.cell-w-column-chooser__seperator-col {
  width: 100px;
}
.cell-w-column-chooser__button-close {
  margin-left: 5px;
  margin-right: 5px;
}
.cell-w-column-chooser__button-ok {
  margin-left: 5px;
  margin-right: 5px;
}
.cell-w-column-chooser__button-cancel {
  margin-left: 5px;
  margin-right: 5px;
}
.cell-w-column-chooser__button-add {
  height: 28px;
  left: 50%;
  margin: -28px 0 0 -25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-28px);
  transform: translateY(-28px);
  width: 50px;
}
.cell-w-column-chooser__button-remove {
  height: 28px;
  left: 50%;
  margin: 10px 0 0 -25px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-28px);
  transform: translateY(-28px);
  width: 50px;
}
.cell-w-column-chooser__button-row {
  width: 100%;
}
.cell-w-column-chooser__button-row-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}
.cell-w-column-chooser__divider {
  float: left;
  height: 1px;
  margin: 10px 0;
  width: 100%;
}

.cell-w-date-selector {
  background-color: #111111;
  border: 3px solid #ffffff;
  color: #ffffff;
  height: auto;
  left: 0;
  padding: 10px;
  width: 200px;
  z-index: 51;
}
.cell-w-date-selector__arrow {
  cursor: pointer;
  float: left;
  margin-top: 5px¨;
}
.cell-w-date-selector__super-absolute {
  padding-top: 2px;
  position: absolute;
  z-index: 1000;
}
.cell-w-date-selector__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0 10px;
  position: relative;
}
.cell-w-date-selector__year-label {
  float: left;
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
}
.cell-w-date-selector__month-label {
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
}
.cell-w-date-selector__arrow-container {
  cursor: pointer;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.cell-w-date-selector__arrow-container-left {
  padding-left: 10px;
  text-align: left;
}
.cell-w-date-selector__arrow-container-right {
  padding-right: 10px;
  text-align: right;
}
.cell-w-date-selector__table {
  width: 100%;
}
.cell-w-date-selector__table__row__th,
.cell-w-date-selector__table__row__td {
  font-size: 10px;
  font-weight: bold;
  padding: 4px;
  text-align: center;
}
.cell-w-date-selector__table__row__td {
  cursor: pointer;
}

.cell-w-focus {
  width: 100%;
}
.cell-w-focus .cell-w-focus__toprow {
  border-bottom-width: 1px;
  border-width: 0;
  float: left;
  margin-top: 5px;
  padding: 1px 0 3px 0;
  width: 100%;
}
.cell-w-focus--not-traded-today .cell-w-focus__main-num {
  opacity: 0.6;
}
.cell-w-focus__leftcol {
  float: left;
  width: 70%;
}
.cell-w-focus__row--symbol {
  float: left;
  font-size: 16px;
  font-weight: bold;
}
.cell-w-focus__row--change {
  font-size: 12px;
  font-weight: bold;
  margin: 8px 0 7px;
  text-align: right;
}
.cell-w-focus__row--change__indicator {
  height: 6px;
  margin: 2px auto 0 2.5%;
}
.cell-w-focus__row--change__value {
  margin-right: 5px;
}
.cell-w-focus__row--ticks {
  margin-top: 4px;
  text-align: right;
}
.cell-w-focus__row--highvalue {
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin-top: 4px;
  text-align: right;
}
.cell-w-focus__botrow {
  border-top-width: 1px;
  border-width: 0;
  float: left;
  padding: 2px 0;
  width: 100%;
}
.cell-w-focus__lowvalue {
  float: right;
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  width: 100%;
}
.cell-w-focus__rightcol {
  float: left;
  width: 30%;
}
.cell-w-focus-mini__main-num {
  font-size: 28px;
  padding-left: 20px;
}
.cell-w-focus-mini--not-traded-today .cell-w-focus-mini__main-num {
  opacity: 0.6;
}
.cell-w-focus-mini__indicator {
  height: 28px;
  margin: 1px 7px 0 5px;
  width: 6px;
}
.cell-w-focus-mini__indicator__current {
  margin-top: 0;
}
.cell-w-focus-mini__values {
  float: left;
  font-size: 11px;
}
.cell-w-focus-mini__values__pct {
  margin-top: 3px;
  width: 100%;
}
.cell-w-focus-mini__values__value {
  margin-top: 3px;
  width: 100%;
}

.cell-w-fund-style-map {
  margin: 0 auto;
  position: relative;
  width: 160px;
}
.cell-w-fund-style-map__row {
  float: left;
  width: 100%;
}
.cell-w-fund-style-map__row__element {
  float: left;
  height: 35px;
  margin: 2px;
  width: 35px;
}
.cell-w-fund-style-map__row__size {
  float: left;
  font-size: 10px;
  padding-left: 5px;
  padding-top: 15px;
  width: 40px;
}
.cell-w-fund-style-map__row__class {
  float: left;
  font-size: 10px;
  padding-top: 4px;
  text-align: center;
  width: 39px;
}
.cell-w-fund-morningstar-rating {
  float: left;
  height: 18px;
  width: 100px;
}
.cell-w-fund-morningstar-rating__star {
  background-position: top -1px left -1px;
  background-repeat: no-repeat;
  float: left;
  height: 16px;
  width: 16px;
}
.cell-w-fund-order-entry__panel {
  width: 300px;
}
.cell-w-fund-order-entry__panel-kiid {
  text-decoration: underline;
  cursor: pointer;
}
.cell-w-fund-order-entry__title {
  border-bottom: 2px solid #004a69;
  padding: 5px 0;
}
.cell-w-fund-order-entry__search {
  margin-bottom: 15px;
}
.cell-w-fund-order-entry__search-panel {
  margin: 20px 0;
}
.cell-w-fund-order-entry__search-label {
  margin-bottom: 5px;
}
.cell-w-fund-order-entry__search-box .cell-input {
  width: 100%;
  padding-right: 20px;
  font-size: 0.75rem;
}
.cell-w-fund-order-entry__search-box .cell-input.cell-input--confirmed {
  background: none;
}
.cell-w-fund-order-entry__search-box input:disabled {
  background-color: #002738;
  color: #00affd;
  border-color: #335260;
}
.cell-w-fund-order-entry__value {
  text-align: right;
  padding-right: 0;
}
.cell-w-fund-order-entry__value-label {
  opacity: 0.5;
}
.cell-w-fund-order-entry__value-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  padding: 10px 0;
}
.cell-w-fund-order-entry__feedback-label {
  opacity: 0.5;
  margin-right: 10px;
}
.cell-w-fund-order-entry__feedback-error {
  margin-top: 5px;
}
.cell-w-fund-order-entry__amount-label {
  opacity: 0.5;
}
.cell-w-fund-order-entry__amount > .cell-flex-row {
  height: 100px;
}
.cell-w-fund-order-entry__amount-default-label {
  font-size: 14px;
  text-align: center;
}
.cell-w-fund-order-entry__amount-toggle {
  background-color: #00394f;
  border-radius: 3px;
  display: inline-block;
  overflow: auto;
}
.cell-w-fund-order-entry__amount-input-wrapper {
  position: relative;
  margin-bottom: 10px;
}
.cell-w-fund-order-entry__amount-input-filed {
  width: 100%;
  padding-right: 36px;
  margin: 0;
  font-size: 0.75rem;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.cell-w-fund-order-entry__amount-input-filed::-webkit-outer-spin-button,
.cell-w-fund-order-entry__amount-input-filed::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cell-w-fund-order-entry__amount-input-suffix {
  color: #777;
  padding: 3px;
  position: absolute;
  right: 3px;
  text-align: right;
  top: 3px;
  width: 34px;
  font-size: 0.75rem;
}
.cell-w-fund-order-entry__input {
  background-color: transparent;
  position: relative;
}
.cell-w-fund-order-entry__input-group {
  font-size: 14px;
}
.cell-w-fund-order-entry__input-label {
  font-size: 14px;
  margin: 10px 0;
}
.cell-w-fund-order-entry__info-group {
  font-size: 14px;
}
.cell-w-fund-order-entry__info-label {
  opacity: 0.5;
}
.cell-w-fund-order-entry__info-portfolio .cell-select-wrapper::before {
  top: 5px;
}
.cell-w-fund-order-entry__info-portfolio .cell-select {
  margin-top: 2px;
}
.cell-w-fund-order-entry__buy-sell-buttons {
  margin: 20px 0;
}
.cell-w-fund-order-entry__buy-button {
  width: 100px;
  border-radius: 3px 0 0 3px;
}
.cell-w-fund-order-entry__sell-button {
  width: 100px;
  border-radius: 0 3px 3px 0;
}
.cell-w-fund-order-entry__mode-button {
  border: 0;
  cursor: pointer;
  border-radius: 3px;
  font-family: inherit;
  font-size: 14px;
  padding: 5px 8px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #0088b6;
  background-color: #00394f;
}
.cell-w-fund-order-entry__button-group .cell-button--confirm {
  margin-right: 5px;
}
.cell-w-fund-order-entry__button-group .cell-button--cancel {
  margin-left: 5px;
}
.cell-w-fund-order-entry__button-selected {
  color: #ffffff;
  background-color: #005c7a;
}

.cell-w-funds-risk-level__wrapper {
  float: left;
  width: 100%;
}
.cell-w-funds-risk-level__label {
  float: left;
  height: 36px;
  line-height: 36px;
  width: 23%;
}
.cell-w-funds-risk-level__levels {
  float: left;
  height: 36px;
  width: 77%;
}
.cell-w-funds-risk-level__level {
  float: left;
  height: 36px;
  line-height: 36px;
  opacity: 0.25;
  text-align: center;
  width: 14.2857142857%;
}
.cell-w-funds-risk-level__level--selected {
  opacity: 1;
}
.cell-w-funds-risk-level__level__num {
  float: left;
  font-size: 15px;
  font-weight: bold;
  height: 36px;
  line-height: 36px;
  text-align: center;
  width: 100%;
}

.cell-w-fx-focus-grid__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  width: 100%;
}
.cell-w-fx-focus-grid__tool-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  margin-left: auto;
  position: relative;
}
.cell-w-fx-focus-grid__tool-box-button {
  height: 16px;
  margin-left: 5px;
  width: 16px;
}
.cell-w-fx-focus-grid__tool-box-button::after {
  content: "";
  height: 16px;
  position: absolute;
  width: 16px;
}
.cell-w-fx-focus-grid__tool-box-button:hover {
  cursor: pointer;
}
.cell-w-fx-focus-grid__chains-navigator {
  clear: both;
  overflow: hidden;
  position: relative;
}
.cell-w-fx-focus-grid__chains-container {
  -ms-flex-line-pack: start;
  align-content: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.cell-w-fx-focus-grid__chain-wrapper {
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin: 2px 2px 2px 0;
}
.cell-w-fx-focus-grid__chain-add {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0.6;
  -webkit-transition: opacity 0.15s ease-in;
  transition: opacity 0.15s ease-in;
}
.cell-w-fx-focus-grid__chain-add-text {
  font-size: 16px;
}
.cell-w-fx-focus-grid__chain-add:hover {
  cursor: pointer;
  opacity: 1;
}
.cell-w-fx-focus-grid__drop-placeholder {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 2px 2px 2px 0;
}
.cell-w-fx-focus-grid__carousel-indicator-host {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 2px;
}

.cell-w-fx-quote__block {
  border-radius: 3px 3px 0 0;
  position: relative;
  -webkit-transition: background-color 0.15s ease-in;
  transition: background-color 0.15s ease-in;
  width: 140px;
}
@media screen and (min-width: 40em) {
  .cell-w-fx-quote__block {
    width: 110px;
  }
}
.cell-w-fx-quote__block-large {
  height: 60px;
}
.cell-w-fx-quote__block-small {
  height: 60px;
}
.cell-w-fx-quote__block--clickable {
  cursor: pointer;
}
.cell-w-fx-quote__block--clickable:hover .cell-w-fx-quote__label {
  opacity: 1;
}
.cell-w-fx-quote__value-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.cell-w-fx-quote__value-box-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.cell-w-fx-quote__value-box--large {
  top: 6px;
}
.cell-w-fx-quote__value-box--small {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cell-w-fx-quote__label {
  bottom: -18px;
  font-size: 0.8125rem;
  height: 18px;
  padding: 0 5px 0 5px;
  position: absolute;
  text-align: center;
  width: 94px;
}
@media screen and (min-width: 40em) {
  .cell-w-fx-quote__label {
    width: 59px;
    font-size: 0.6875rem;
  }
}
.cell-w-fx-quote__label--clickable,
.cell-w-fx-quote__label--left,
.cell-w-fx-quote__label--right {
  -webkit-transition: background-color 0.15s ease-in, color 0.15s ease-in, opacity 0.15s ease-in;
  transition: background-color 0.15s ease-in, color 0.15s ease-in, opacity 0.15s ease-in;
}
.cell-w-fx-quote__label--left {
  left: 0px;
  border-radius: 0 0 5px 3px;
}
.cell-w-fx-quote__label--right {
  right: 0px;
  border-radius: 0 0 3px 5px;
}
.cell-w-fx-quote__rate-container {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.cell-w-fx-quote__rate-digit--normal {
  font-size: 14px;
}
.cell-w-fx-quote__rate-digit--enlarged {
  font-size: 20px;
}
.cell-w-fx-quote__rate-digit--reduced {
  font-size: 12px;
  position: relative;
  top: -5px;
}
.cell-w-fx-quote__arrow {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.cell-w-fx-quote__forward-points {
  font-size: 11px;
  position: absolute;
  right: 50%;
  top: 35px;
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}
.cell-w-fx-quote__forward-points-separator {
  height: 1px;
  position: absolute;
  top: 30px;
  width: 100%;
}

.cell-w-fx-quote-chain__box {
  display: inline-block;
  padding: 6px;
  white-space: nowrap;
}
.cell-w-fx-quote-chain__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.cell-w-fx-quote-chain__flag-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  position: relative;
  top: -3px;
}
.cell-w-fx-quote-chain__currency {
  padding: 2px 0 1px 0;
  position: relative;
  top: -2px;
}
.cell-w-fx-quote-chain__currency-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  font-size: 14px;
  margin-left: 6px;
  position: relative;
}
.cell-w-fx-quote-chain__currency-flag--left {
  margin: 3px 2px 0 0 !important;
}
.cell-w-fx-quote-chain__currency-flag--right {
  margin: 3px 0 0 2px !important;
}
.cell-w-fx-quote-chain__currency-flag--not-found {
  background-position: 0 0 !important;
}
.cell-w-fx-quote-chain__currency-separator {
  text-align: center;
  width: 8px;
}
.cell-w-fx-quote-chain__currency--swap:hover {
  background-color: transparent;
  color: transparent;
  cursor: pointer;
  width: 14px;
}
.cell-w-fx-quote-chain__currency--selectable:hover {
  cursor: pointer;
}
.cell-w-fx-quote-chain__currency--undefined > * {
  visibility: hidden;
}
.cell-w-fx-quote-chain__tool-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.cell-w-fx-quote-chain__tool-box-button {
  height: 16px;
  margin-left: 5px;
  width: 16px;
}
.cell-w-fx-quote-chain__tool-box-button::after {
  content: "";
  height: 16px;
  position: absolute;
  width: 16px;
}
.cell-w-fx-quote-chain__tool-box-button:hover {
  cursor: pointer;
}
.cell-w-fx-quote-chain__quote-pair {
  margin-bottom: 6px;
  margin-top: 6px;
}
.cell-w-fx-quote-chain__quote-pair:first-child {
  margin-top: 0;
}
.cell-w-fx-quote-chain__quote-pair:last-child {
  margin-bottom: 0;
}
.cell-w-fx-quote-chain__quote-pair-labels-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  font-size: 14px;
  margin-top: 3px;
}
.cell-w-fx-quote-chain__quote-pair-label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.cell-w-fx-quote-chain__quote-pair-container {
  margin-top: 3px;
}

.cell-w-fx-quote-pair__box {
  position: relative;
}
.cell-w-fx-quote-pair__quote-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.cell-w-fx-quote-pair__quote-spacer {
  width: 4px;
}
.cell-w-fx-quote-pair__quote--left,
.cell-w-fx-quote-pair__quote--right {
  height: 78px;
}
.cell-w-fx-quote-pair__title-bar {
  border-radius: 3px 3px 5px 5px;
  bottom: 0;
  font-size: 0.8125rem;
  left: 50%;
  position: absolute;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 88px;
  padding: 3px 0 4px;
}
@media screen and (min-width: 40em) {
  .cell-w-fx-quote-pair__title-bar {
    width: 98px;
  }
}
.cell-w-fx-quote-pair__title-bar--spot {
  font-weight: bold;
  text-transform: uppercase;
}

.cell-w-fx-order-entry__error-section,
.cell-w-fx-order-entry__receipt-section,
.cell-w-fx-order-entry__accept-section,
.cell-w-fx-order-entry__request-section {
  overflow: hidden;
  padding: 0 2px;
  position: relative;
  top: 0;
  -webkit-transition: top 0.4s ease-out, height 0.4s ease-out;
  transition: top 0.4s ease-out, height 0.4s ease-out;
}

.cell-w-fx-order-entry__accept-time-remaining-container,
.cell-w-fx-order-entry__request-withdraw-button,
.cell-w-fx-order-entry__request-quote-button,
.cell-w-fx-order-entry__request-label,
.cell-w-fx-order-entry__receipt-order-id-value,
.cell-w-fx-order-entry__receipt-value-date-value,
.cell-w-fx-order-entry__receipt-trading-rate-value,
.cell-w-fx-order-entry__receipt-sold-amount,
.cell-w-fx-order-entry__receipt-bought-amount,
.cell-w-fx-order-entry__receipt-order-id-label,
.cell-w-fx-order-entry__receipt-value-date-label,
.cell-w-fx-order-entry__receipt-trading-rate-label,
.cell-w-fx-order-entry__receipt-sold-container,
.cell-w-fx-order-entry__receipt-bought-container,
.cell-w-fx-order-entry__accept-forward-points-value,
.cell-w-fx-order-entry__accept-spot-rate-value,
.cell-w-fx-order-entry__accept-sell-amount,
.cell-w-fx-order-entry__accept-buy-amount,
.cell-w-fx-order-entry__accept-forward-points-label,
.cell-w-fx-order-entry__accept-spot-rate-label,
.cell-w-fx-order-entry__accept-sell-container,
.cell-w-fx-order-entry__accept-buy-container,
.cell-w-fx-order-entry__request-kid,
.cell-w-fx-order-entry__request-comment-input,
.cell-w-fx-order-entry__request-ndf-settlement-currency-select,
.cell-w-fx-order-entry__request-ndf-type-select,
.cell-w-fx-order-entry__request-ndf-switch-control,
.cell-w-fx-order-entry__request-order-container--second,
.cell-w-fx-order-entry__request-order-container--first,
.cell-w-fx-order-entry__request-order-amount--second,
.cell-w-fx-order-entry__request-order-amount--first,
.cell-w-fx-order-entry__request-portfolio-select,
.cell-w-fx-order-entry__request-tenor-select,
.cell-w-fx-order-entry__request-trading-rate-box,
.cell-w-fx-order-entry__request-forward-points-value,
.cell-w-fx-order-entry__request-spot-rate-box,
.cell-w-fx-order-entry__request-pair-container {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.cell-w-fx-order-entry__request-kid,
.cell-w-fx-order-entry__request-comment-input,
.cell-w-fx-order-entry__request-ndf-settlement-currency-select,
.cell-w-fx-order-entry__request-ndf-type-select,
.cell-w-fx-order-entry__request-ndf-switch-control,
.cell-w-fx-order-entry__request-order-container--second,
.cell-w-fx-order-entry__request-order-container--first,
.cell-w-fx-order-entry__request-order-amount--second,
.cell-w-fx-order-entry__request-order-amount--first,
.cell-w-fx-order-entry__request-portfolio-select,
.cell-w-fx-order-entry__request-tenor-select,
.cell-w-fx-order-entry__request-trading-rate-box,
.cell-w-fx-order-entry__request-forward-points-value,
.cell-w-fx-order-entry__request-spot-rate-box,
.cell-w-fx-order-entry__request-pair-container {
  margin-bottom: 12px;
}

.cell-w-fx-order-entry__accept-forward-points-label,
.cell-w-fx-order-entry__accept-spot-rate-label,
.cell-w-fx-order-entry__accept-sell-container,
.cell-w-fx-order-entry__accept-buy-container {
  margin-bottom: 2px;
}

.cell-w-fx-order-entry__accept-forward-points-value,
.cell-w-fx-order-entry__accept-spot-rate-value,
.cell-w-fx-order-entry__accept-sell-amount,
.cell-w-fx-order-entry__accept-buy-amount {
  margin-bottom: 12px;
}

.cell-w-fx-order-entry__receipt-order-id-label,
.cell-w-fx-order-entry__receipt-value-date-label,
.cell-w-fx-order-entry__receipt-trading-rate-label,
.cell-w-fx-order-entry__receipt-sold-container,
.cell-w-fx-order-entry__receipt-bought-container {
  margin-bottom: 2px;
  margin-left: 20px;
  text-align: left;
  white-space: nowrap;
}

.cell-w-fx-order-entry__receipt-order-id-value,
.cell-w-fx-order-entry__receipt-value-date-value,
.cell-w-fx-order-entry__receipt-trading-rate-value,
.cell-w-fx-order-entry__receipt-sold-amount,
.cell-w-fx-order-entry__receipt-bought-amount {
  margin-bottom: 12px;
  margin-right: 20px;
  text-align: right;
  white-space: nowrap;
}

.cell-w-fx-order-entry__select {
  padding-bottom: 2px;
  padding-top: 2px;
}
.cell-w-fx-order-entry__section-container {
  overflow-y: hidden;
}
.cell-w-fx-order-entry__progress-steps {
  height: 40px;
  margin-bottom: 10px;
}
.cell-w-fx-order-entry__progress-steps-title {
  font-size: 14px;
  padding-left: 4px;
  padding-right: 4px;
}
.cell-w-fx-order-entry__separator {
  height: 1px;
  margin-bottom: 12px;
  width: 100%;
}
.cell-w-fx-order-entry__request-section-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(6) {
  -ms-grid-row: 1;
  -ms-grid-column: 6;
}
.cell-w-fx-order-entry__request-section-grid > *:nth-child(7) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.cell-w-fx-order-entry__request-label {
  margin-bottom: 2px;
}
.cell-w-fx-order-entry__request-pair-label {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-pair-scaling-description {
  font-size: 10px;
  white-space: nowrap;
}
.cell-w-fx-order-entry__request-pair-scaling-description::before {
  content: " ";
}
.cell-w-fx-order-entry__request-pair-container {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-pair-box {
  display: inline-block;
  padding-bottom: 4px;
  padding-top: 5px;
  vertical-align: baseline;
}
.cell-w-fx-order-entry__request-pair-box--active.cell-w-fx-order-entry__request-pair-box {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}
.cell-w-fx-order-entry__request-pair-currency {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cell-w-fx-order-entry__request-pair-currency--undefined {
  color: transparent;
}
.cell-w-fx-order-entry__request-pair-currency--selectable {
  cursor: pointer;
}
.cell-w-fx-order-entry__request-pair-separator {
  padding-left: 2px;
  padding-right: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cell-w-fx-order-entry__request-spot-rate-label {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-spot-rate-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-spot-rate-arrow {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.cell-w-fx-order-entry__request-spot-rate--normal-digit {
  font-family: Roboto;
  font-size: 12px;
}
.cell-w-fx-order-entry__request-spot-rate--enlarged-digit {
  font-family: Roboto;
  font-size: 14px;
}
.cell-w-fx-order-entry__request-spot-rate--reduced-digit {
  font-family: Roboto;
  font-size: 10px;
  position: relative;
  top: -3px;
}
.cell-w-fx-order-entry__request-forward-points-label {
  -ms-grid-column: 5 !important;
  grid-column: 5 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-forward-points-value {
  -ms-grid-column: 5 !important;
  grid-column: 5 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-trading-rate-label {
  -ms-grid-column: 7 !important;
  grid-column: 7 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-trading-rate-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-grid-column: 7 !important;
  grid-column: 7 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-trading-rate-arrow {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.cell-w-fx-order-entry__request-trading-rate--normal-digit {
  font-family: Roboto;
  font-size: 14px;
}
.cell-w-fx-order-entry__request-trading-rate--enlarged-digit {
  font-family: Roboto;
  font-size: 16px;
}
.cell-w-fx-order-entry__request-trading-rate--reduced-digit {
  font-family: Roboto;
  font-size: 12px;
  position: relative;
  top: -3px;
}
.cell-w-fx-order-entry__request-tenor-label {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 3 !important;
  grid-row: 3 !important;
}
.cell-w-fx-order-entry__request-tenor-select {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
  width: 100%;
}
.cell-w-fx-order-entry__request-value-date-label {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 3 !important;
  grid-row: 3 !important;
}
.cell-w-fx-order-entry__request-value-date-container {
  align-self: flex-start;
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
  margin-bottom: 12px;
}
.cell-w-fx-order-entry__request-value-date-container .cell-input {
  width: 100%;
}
.cell-w-fx-order-entry__request-portfolio-label {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 3 !important;
  grid-row: 3 !important;
}
.cell-w-fx-order-entry__request-portfolio-select {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
}
.cell-w-fx-order-entry__request-order-label--first {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 5 !important;
  grid-row: 5 !important;
}
.cell-w-fx-order-entry__request-order-label--second {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 7 !important;
  grid-row: 7 !important;
}
.cell-w-fx-order-entry__request-order-amount {
  text-align: right;
  width: 100%;
}
.cell-w-fx-order-entry__request-order-amount--first {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 6 !important;
  grid-row: 6 !important;
}
.cell-w-fx-order-entry__request-order-amount--second {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 8 !important;
  grid-row: 8 !important;
}
.cell-w-fx-order-entry__request-order-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
.cell-w-fx-order-entry__request-order-container--first {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 6 !important;
  grid-row: 6 !important;
}
.cell-w-fx-order-entry__request-order-container--second {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 8 !important;
  grid-row: 8 !important;
}
.cell-w-fx-order-entry__request-order-currency {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
.cell-w-fx-order-entry__request-order-currency-flag {
  position: relative;
}
.cell-w-fx-order-entry__request-order-invert {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 5 !important;
  -ms-grid-row-span: 4 !important;
  grid-row: 5 / span 4 !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cell-w-fx-order-entry__request-order-invert-icon {
  height: 24px;
  width: 24px;
}
.cell-w-fx-order-entry__request-order-invert-icon--enabled {
  cursor: pointer;
}
.cell-w-fx-order-entry__request-ndf {
  display: -ms-grid;
  display: grid;
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 5 !important;
  -ms-grid-row-span: 2 !important;
  grid-row: 5 / span 2 !important;
  -ms-grid-columns: auto 12px 1fr 12px 1fr;
  grid-template-columns: auto 12px 1fr 12px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__request-ndf > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__request-ndf > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.cell-w-fx-order-entry__request-ndf > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.cell-w-fx-order-entry__request-ndf > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.cell-w-fx-order-entry__request-ndf > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.cell-w-fx-order-entry__request-ndf-switch-label {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-ndf-switch-control {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-ndf-switch-control .cell-w-switch {
  padding: 0;
}
.cell-w-fx-order-entry__request-ndf-type-label {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-ndf-type-select {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-ndf-settlement-currency-label {
  -ms-grid-column: 5 !important;
  grid-column: 5 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__request-ndf-settlement-currency-select {
  -ms-grid-column: 5 !important;
  grid-column: 5 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__request-comment-label {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 7 !important;
  grid-row: 7 !important;
}
.cell-w-fx-order-entry__request-comment-input {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 8 !important;
  grid-row: 8 !important;
}
.cell-w-fx-order-entry__request-separator {
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 7 !important;
  grid-column: 1 / span 7 !important;
  -ms-grid-row: 9 !important;
  grid-row: 9 !important;
}
.cell-w-fx-order-entry__request-kid {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 7 !important;
  grid-column: 1 / span 7 !important;
  -ms-grid-row: 10 !important;
  grid-row: 10 !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cell-w-fx-order-entry__request-kid-link {
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
  white-space: nowrap;
}
.cell-w-fx-order-entry__request-kid-link:hover {
  text-decoration: underline;
}
.cell-w-fx-order-entry__request-quote-button {
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 1 / span 3 !important;
  -ms-grid-row: 11 !important;
  grid-row: 11 !important;
  margin-left: 10px;
  margin-right: 10px;
}
.cell-w-fx-order-entry__request-quote-button.cell-button {
  padding: 0;
}
.cell-w-fx-order-entry__request-withdraw-button {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 11 !important;
  grid-row: 11 !important;
  margin-left: 10px;
  margin-right: 10px;
}
.cell-w-fx-order-entry__request-withdraw-button.cell-button {
  padding: 0;
}
.cell-w-fx-order-entry__accept-title {
  font-size: 12px;
  margin-bottom: 12px;
}
.cell-w-fx-order-entry__accept-section-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(6) {
  -ms-grid-row: 1;
  -ms-grid-column: 6;
}
.cell-w-fx-order-entry__accept-section-grid > *:nth-child(7) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.cell-w-fx-order-entry__accept-trade--first {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
}
.cell-w-fx-order-entry__accept-trade--second {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
}
.cell-w-fx-order-entry__accept-buy-container {
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-buy-label {
  text-transform: uppercase;
}
.cell-w-fx-order-entry__accept-buy-currency::before {
  content: " ";
}
.cell-w-fx-order-entry__accept-buy-amount {
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-sell-container {
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-sell-label {
  text-transform: uppercase;
}
.cell-w-fx-order-entry__accept-sell-currency::before {
  content: " ";
}
.cell-w-fx-order-entry__accept-sell-amount {
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-spot-rate-label {
  -ms-grid-column: 5 !important;
  grid-column: 5 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-spot-rate-value {
  -ms-grid-column: 5 !important;
  grid-column: 5 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-forward-points-label {
  -ms-grid-column: 7 !important;
  grid-column: 7 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-forward-points-value {
  -ms-grid-column: 7 !important;
  grid-column: 7 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-separator {
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 7 !important;
  grid-column: 1 / span 7 !important;
  -ms-grid-row: 3 !important;
  grid-row: 3 !important;
}
.cell-w-fx-order-entry__accept-button {
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 1 / span 3 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
}
.cell-w-fx-order-entry__accept-button.cell-button {
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.cell-w-fx-order-entry__accept-button-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__accept-button-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__accept-button-grid > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.cell-w-fx-order-entry__accept-button-title {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  margin-bottom: 5px;
  text-align: left;
}
.cell-w-fx-order-entry__accept-button-trading-rate {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column: 2 !important;
  grid-column: 2 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  margin-bottom: 5px;
  text-align: right;
}
.cell-w-fx-order-entry__accept-button-instrument-type {
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 10px;
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: left;
}
.cell-w-fx-order-entry__accept-button-value-date {
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 10px;
  -ms-grid-column: 2 !important;
  grid-column: 2 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: right;
}
.cell-w-fx-order-entry__accept-reject-button {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
}
.cell-w-fx-order-entry__accept-reject-button.cell-button {
  height: auto;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.cell-w-fx-order-entry__accept-reject-button-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__accept-reject-button-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__accept-reject-button-title {
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  margin-bottom: 5px;
  text-align: center;
}
.cell-w-fx-order-entry__accept-reject-button-explanation {
  -ms-grid-row-align: center;
  align-self: center;
  font-size: 10px;
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
  text-align: center;
}
.cell-w-fx-order-entry__accept-time-remaining-container {
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 7 !important;
  grid-column: 1 / span 7 !important;
  -ms-grid-row: 5 !important;
  grid-row: 5 !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 12px;
  position: relative;
}
.cell-w-fx-order-entry__accept-time-remaining-title {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.cell-w-fx-order-entry__accept-time-remaining-title-label::after {
  content: ":";
}
.cell-w-fx-order-entry__accept-time-remaining-title-value::before {
  content: " ";
}
.cell-w-fx-order-entry__accept-time-remaining-title-seconds::before {
  content: " ";
}
.cell-w-fx-order-entry__accept-time-remaining-bar {
  height: 20px;
}
.cell-w-fx-order-entry__receipt-title {
  font-size: 12px;
  margin-bottom: 12px;
}
.cell-w-fx-order-entry__receipt-section-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 5fr 20px 3fr 20px 4fr 20px 4fr;
  grid-template-columns: 5fr 20px 3fr 20px 4fr 20px 4fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(6) {
  -ms-grid-row: 1;
  -ms-grid-column: 6;
}
.cell-w-fx-order-entry__receipt-section-grid > *:nth-child(7) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.cell-w-fx-order-entry__receipt-trade--first {
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
}
.cell-w-fx-order-entry__receipt-trade--second {
  -ms-grid-row: 2 !important;
  grid-row: 2 !important;
}
.cell-w-fx-order-entry__receipt-bought-container {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
}
.cell-w-fx-order-entry__receipt-bought-label {
  text-transform: uppercase;
}
.cell-w-fx-order-entry__receipt-bought-currency::before {
  content: " ";
}
.cell-w-fx-order-entry__receipt-bought-amount {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
}
.cell-w-fx-order-entry__receipt-sold-container {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
}
.cell-w-fx-order-entry__receipt-sold-label {
  text-transform: uppercase;
}
.cell-w-fx-order-entry__receipt-sold-currency::before {
  content: " ";
}
.cell-w-fx-order-entry__receipt-sold-amount {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
}
.cell-w-fx-order-entry__receipt-trading-rate-label {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 3 !important;
  grid-row: 3 !important;
}
.cell-w-fx-order-entry__receipt-trading-rate-value {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 3 !important;
  grid-row: 3 !important;
}
.cell-w-fx-order-entry__receipt-value-date-label {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
}
.cell-w-fx-order-entry__receipt-value-date-value {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 4 !important;
  grid-row: 4 !important;
}
.cell-w-fx-order-entry__receipt-order-id-label {
  -ms-grid-column: 1 !important;
  grid-column: 1 !important;
  -ms-grid-row: 5 !important;
  grid-row: 5 !important;
}
.cell-w-fx-order-entry__receipt-order-id-value {
  -ms-grid-column: 3 !important;
  grid-column: 3 !important;
  -ms-grid-row: 5 !important;
  grid-row: 5 !important;
}
.cell-w-fx-order-entry__receipt-bottom-separator {
  -ms-grid-column: 1 !important;
  -ms-grid-column-span: 7 !important;
  grid-column: 1 / span 7 !important;
  -ms-grid-row: 6 !important;
  grid-row: 6 !important;
}
.cell-w-fx-order-entry__receipt-new-deal-button {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 7 !important;
  grid-row: 7 !important;
  margin-left: 10px;
  margin-right: 10px;
}
.cell-w-fx-order-entry__receipt-new-deal-button.cell-button {
  padding: 0;
}
.cell-w-fx-order-entry__error-container {
  border-style: dashed;
  border-width: 2px;
  margin: 0 10px 12px 10px;
  padding: 10px;
}
.cell-w-fx-order-entry__error-title {
  font-size: 12px;
  margin-bottom: 5px;
}
.cell-w-fx-order-entry__error-message {
  font-size: 10px;
}
.cell-w-fx-order-entry__error-acknowledge-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 4;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(6) {
  -ms-grid-row: 1;
  -ms-grid-column: 6;
}
.cell-w-fx-order-entry__error-acknowledge-grid > *:nth-child(7) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.cell-w-fx-order-entry__error-acknowledge-button {
  -ms-grid-column: 5 !important;
  -ms-grid-column-span: 3 !important;
  grid-column: 5 / span 3 !important;
  -ms-grid-row: 1 !important;
  grid-row: 1 !important;
  margin-left: 10px;
  margin-right: 10px;
}

.cell-w-heatmap {
  height: 100%;
  min-height: 100%;
}
.cell-w-heatmap__instrument {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 7px;
  min-height: 75px;
  position: relative;
  width: 125px;
}
.cell-w-heatmap__instrument-ticker {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  z-index: 2;
}
.cell-w-heatmap__instrument-pct {
  font-size: 22px;
  font-weight: 100;
  z-index: 2;
}
.cell-w-heatmap__instrument-column {
  height: 100%;
  position: absolute;
  width: 100%;
}
.cell-w-heatmap__instrument--placeholder {
  margin-bottom: 0;
  margin-top: 0;
  min-height: 0;
  padding-bottom: 0;
  padding-top: 0;
  visibility: hidden;
  width: 125px;
}
.cell-w-heatmap__instrument > div {
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cell-w-historical-overview__labels {
  font-weight: bold;
  height: 12px;
  margin: 10px 0 6px;
}
.cell-w-historical-overview__labels--low {
  float: left;
}
.cell-w-historical-overview__labels--high {
  float: right;
}
.cell-w-historical-overview__hidden-labels {
  margin: 15px 0 0 0;
}
.cell-w-historical-overview-bar {
  background-color: #999999;
  height: 12px;
  margin: 5px 0 0;
  position: relative;
  width: 100%;
}
.cell-w-historical-overview-bar--low {
  float: left;
  height: 100%;
  -webkit-transition: width ease-out 0.5s;
  transition: width ease-out 0.5s;
}
.cell-w-historical-overview-bar--high {
  float: left;
  height: 100%;
  -webkit-transition: width ease-out 0.5s;
  transition: width ease-out 0.5s;
}
.cell-w-historical-overview-bar--unchanged {
  float: left;
  height: 100%;
  -webkit-transition: width ease-out 0.5s;
  transition: width ease-out 0.5s;
}
.cell-w-historical-overview-bar--last {
  border-left: 1px solid #000a0d;
  border-right: 1px solid #000a0d;
  bottom: -2px;
  height: 16px;
  margin-left: 0;
  position: absolute;
  -webkit-transition: left ease-out 0.5s;
  transition: left ease-out 0.5s;
  width: 2px;
}
.cell-w-historical-overview__value,
.cell-w-historical-overview__period {
  cursor: pointer;
  width: 10%;
}
.cell-w-historical-overview__value--selected,
.cell-w-historical-overview__period--selected {
  border: 1px solid white;
}

.cell-w-index-overview__contentrow {
  float: left;
  width: 100%;
}
.cell-w-index-overview__contentrow .icon-arrow {
  margin: 0 5px 5px 0;
}
.cell-w-index-overview__rowmini--top {
  font-size: 11px;
}
.cell-w-index-overview__rowmini--top div + div {
  margin-left: 10px;
}
.cell-w-index-overview__row--top {
  font-size: 11px;
}
.cell-w-index-overview__row--top div + div {
  margin-left: 10px;
}
.cell-w-index-overview__contentrow--spaced {
  margin: 0 0 4px;
}
.cell-w-index-overview .cell-tbl-change-labels {
  font-size: 11px;
  margin-top: 21px;
  width: 100%;
}
.cell-w-index-overview .cell-tbl-change-labels td {
  font-weight: bold;
  padding: 0;
  text-align: left;
}
.cell-w-index-overview .cell-tbl-change-labels td + td {
  text-align: center;
}
.cell-w-index-overview .cell-tbl-change-labels td + td + td {
  text-align: right;
}
.cell-w-index-overview .cell-tbl-historic {
  margin-top: 10px;
  width: 100%;
}
.cell-w-index-overview .cell-tbl-historic td {
  border-bottom-width: 1px;
  padding: 5px 0 4px;
}
.cell-w-index-overview .changevalue {
  display: inline-block;
  margin-left: 6px;
}
.cell-w-index-overview--mini__name {
  float: left;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
  overflow: hidden;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
}
.cell-w-index-overview--mini__pct-change {
  font-size: 26px;
}

.ticker-text {
  font-size: 24px;
  font-weight: normal;
  margin: 6px 0;
}

.cell-w-instrument-overview__labels {
  font-weight: bold;
  height: 12px;
  margin: 10px 0 6px;
}
.cell-w-instrument-overview__labels--low {
  float: left;
}
.cell-w-instrument-overview__labels--high {
  float: right;
}
.cell-w-instrument-overview__hidden-labels {
  margin: 15px 0 0 0;
}
.cell-w-instrument-overview__ticker_text {
  font-size: 23px;
}
.cell-w-instrument-overview__period {
  outline: none;
  color: #8e8e8e !important;
}
.cell-w-instrument-overview__contentrow {
  float: left;
  width: 100%;
}
.cell-w-instrument-overview__contentrow .icon-arrow {
  margin: 0 5px 5px 0;
}
.cell-w-instrument-overview__line-daily {
  margin: 0 20px 0 15px;
}
.cell-w-instrument-overview__line-daily:before {
  color: #cccccc;
  content: "-";
}
.cell-w-instrument-overview__line:before {
  color: #cccccc;
  content: "-";
  font-weight: bolder;
}
.cell-w-instrument-overview__thirdrow {
  font-size: 16px;
}
.cell-w-instrument-overview__historic-view-label {
  font-size: 18px;
  margin-bottom: 6px;
}
.cell-w-instrument-overview__rowmini--top {
  font-size: 12px;
}
.cell-w-instrument-overview__rowmini--ticker {
  font-size: 14px;
}
.cell-w-instrument-overview__rowmini--secondrow {
  margin-top: 6px;
  font-size: 16px;
  font-weight: bold;
}
.cell-w-instrument-overview__row--top {
  font-size: 11px;
}
.cell-w-instrument-overview__row--top div + div {
  margin-left: 10px;
}
.cell-w-instrument-overview__contentrow--spaced {
  margin: 0 0 4px;
}
.cell-w-instrument-overview__header-row {
  outline: none;
}
.cell-w-instrument-overview .cell-tbl-change-labels {
  font-size: 11px;
  margin-top: 12px;
  width: 100%;
}
.cell-w-instrument-overview .cell-tbl-change-labels td {
  font-weight: bold;
  padding: 0;
  text-align: left;
}
.cell-w-instrument-overview .cell-tbl-change-labels td + td {
  text-align: center;
}
.cell-w-instrument-overview .cell-tbl-change-labels td + td + td {
  text-align: right;
}
.cell-w-instrument-overview .cell-tbl-historic {
  margin-top: 10px;
  width: 100%;
}
.cell-w-instrument-overview .cell-tbl-historic td {
  border-bottom-width: 1px;
  padding: 5px 0 4px;
}
.cell-w-instrument-overview .changevalue {
  display: inline-block;
  margin-left: 6px;
}
.cell-w-instrument-overview--mini__name {
  float: left;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
  overflow: hidden;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;
}
.cell-w-instrument-overview--mini__pct-change {
  font-size: 16px;
  font-weight: bold;
}
.cell-w-instrument-overview-bar {
  background-color: #999999;
  height: 12px;
  margin: 5px 0 4px 0;
  position: relative;
  width: 100%;
}
.cell-w-instrument-overview-bar--low {
  float: left;
  height: 100%;
  -webkit-transition: width ease-out 0.5s;
  transition: width ease-out 0.5s;
}
.cell-w-instrument-overview-bar--high {
  float: left;
  height: 100%;
  -webkit-transition: width ease-out 0.5s;
  transition: width ease-out 0.5s;
}
.cell-w-instrument-overview-bar--unchanged {
  float: left;
  height: 100%;
  -webkit-transition: width ease-out 0.5s;
  transition: width ease-out 0.5s;
}
.cell-w-instrument-overview-bar--last {
  border-left: 3px solid #000a0d;
  bottom: -2px;
  height: 16px;
  margin-left: 0;
  position: absolute;
  -webkit-transition: left ease-out 0.5s;
  transition: left ease-out 0.5s;
  width: 1px;
}
.cell-w-instrument-overview-bar--extra-margin {
  margin-bottom: 17px;
}

.cell-w-latest-trades {
  width: 100%;
}
.cell-w-latest-trades__header-row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 700;
  height: 27px;
  padding: 0 3px;
  position: relative;
}
.cell-w-latest-trades__item-row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 27px;
  padding: 0 3px;
  position: relative;
}
.cell-w-latest-trades-item__vol-bg {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 5%;
  z-index: 1;
}
.cell-w-latest-trades-item__time {
  z-index: 2;
  max-width: 20%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
}
.cell-w-latest-trades-item__buyer-seller {
  text-align: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
}
.cell-w-latest-trades-item__buyer {
  padding: 0;
  text-align: center;
}
.cell-w-latest-trades-item__seller {
  padding: 0;
  text-align: center;
}
.cell-w-latest-trades-item__details {
  text-align: right;
  z-index: 2;
  max-width: 30%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
}
.cell-w-latest-trades-item__details .cell-arrow {
  display: block;
  float: right;
  margin-top: 3px;
}
.cell-w-latest-trades-item__vol {
  text-align: right;
  max-width: 23%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 23%;
  flex: 0 0 23%;
  z-index: 2;
}
.cell-w-latest-trades-item__volume::after {
  content: " @ ";
  white-space: pre;
}
.cell-w-latest-trades-item__up-down {
  width: 18px;
}
.cell-w-latest-trades-item__price {
  width: 40%;
}
.cell-w-latest-trades-item__spacer::after {
  content: " ";
}
.cell-w-latest-trades-item__devider::after {
  content: " | ";
}

.cell-w-order-book {
  height: 100%;
  min-height: 100%;
}
.cell-w-order-book__payload {
  position: relative;
}
@media screen and (min-width: 40em) {
  .cell-w-order-book__payload {
    min-height: calc(100% - 36px);
  }
}
.cell-w-order-book__footer {
  bottom: 0;
  width: 100%;
}
.cell-w-order-book__footer-spread {
  text-align: right;
}
.cell-w-order-book__footer-label {
  display: inline-block;
  padding-top: 1px;
}
.cell-w-order-book__footer-bid-bar,
.cell-w-order-book__footer-ask-bar {
  text-align: center;
  position: relative;
}
.cell-w-order-book__footer-bid-bar-label,
.cell-w-order-book__footer-ask-bar-label {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cell-w-order-book__footer-bid-bar-element,
.cell-w-order-book__footer-ask-bar-element {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  height: 17px;
  width: 100%;
}
.cell-w-order-book__table {
  margin-bottom: 5px;
  table-layout: fixed;
}
.cell-w-order-book__table th {
  overflow: hidden;
  white-space: nowrap;
}
.cell-w-order-book__wide > .cell-w-order-book__table-header-bid-orders,
.cell-w-order-book__wide > .cell-w-order-book__table-header-ask-orders {
  width: 15%;
}
.cell-w-order-book__wide > .cell-w-order-book__table-header-bid-volume,
.cell-w-order-book__wide > .cell-w-order-book__table-header-ask-volume {
  width: 25%;
}
.cell-w-order-book__wide > .cell-w-order-book__table-header-bid-price,
.cell-w-order-book__wide > .cell-w-order-book__table-header-ask-price {
  width: 30%;
}
.cell-w-order-book__compact > .cell-w-order-book__table-header-bid-orders,
.cell-w-order-book__compact > .cell-w-order-book__table-header-ask-orders {
  width: 12%;
}
.cell-w-order-book__compact > .cell-w-order-book__table-header-bid-volume,
.cell-w-order-book__compact > .cell-w-order-book__table-header-ask-volume {
  width: 44%;
}
.cell-w-order-book__compact > .cell-w-order-book__table-header-bid-price,
.cell-w-order-book__compact > .cell-w-order-book__table-header-ask-price {
  width: 44%;
}
.cell-w-order-book__compact th {
  text-align: center;
  width: 25%;
}
.cell-w-order-book__compact td {
  text-align: center;
}
.cell-w-order-book__detail {
  display: none;
}
.cell-w-order-book__detail td {
  background-color: #eaeaea;
}
.cell-w-order-book__detail__price {
  color: #bfbfbf;
}
.cell-w-order-book__toggle {
  margin: 4px;
  text-align: center;
}
.cell-w-order-book--size-bar {
  width: 30%;
}
.cell-w-order-book--bid-bar-size {
  background-color: transparent;
  background-position: right center;
  background-repeat: no-repeat;
  border-right: 1px solid #ffffff;
  padding: 10px;
}
.cell-w-order-book--ask-bar-size {
  background-color: transparent;
  background-position: left center;
  background-repeat: no-repeat;
  border-left: 1px solid #ffffff;
}
.cell-w-order-book--bid-bar-container,
.cell-w-order-book--ask-bar-container {
  position: relative;
}
.cell-w-order-book--bid-bar-element,
.cell-w-order-book--ask-bar-element {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
}
.cell-w-order-book--bid-bar-element {
  right: 0;
}
.cell-w-order-book--ask-bar-element {
  left: 0;
}
.cell-w-order-book--bid-bar-volume,
.cell-w-order-book--ask-bar-volume {
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cell-w-order-book--bid-bar-volume {
  right: 5px;
}
.cell-w-order-book--ask-bar-volume {
  left: 5px;
}
.cell-w-order-book--bid-market,
.cell-w-order-book--ask-market {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-w-order-book__bid-bar-size,
.cell-w-order-book__ask-bar-size {
  overflow: hidden;
  text-align: center;
}
.cell-w-order-book--bid-price,
.cell-w-order-book--bid-market,
.cell-w-order-book__table-header-bid-orders,
.cell-w-order-book__table-header-bid-volume,
.cell-w-order-book__table-header-bid-price {
  overflow: hidden;
  text-align: left;
}
.cell-w-order-book--ask-orders,
.cell-w-order-book--ask-volume,
.cell-w-order-book--ask-price,
.cell-w-order-book--ask-market,
.cell-w-order-book__table-header-ask-orders,
.cell-w-order-book__table-header-ask-volume,
.cell-w-order-book__table-header-ask-price {
  overflow: hidden;
  text-align: right;
}

.cell-w-order-entry-panel {
  position: relative;
  z-index: 100;
}
@media screen and (min-width: 40em) {
  .cell-w-order-entry-panel {
    float: left;
  }
}
.cell-w-order-entry-panel__advanced {
  float: left;
  margin-left: -220px;
  position: relative;
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
  z-index: 97;
}
.cell-w-order-entry-panel__advanced--show {
  margin-left: -1px;
  -webkit-transition: margin-left ease-out 0.1s;
  transition: margin-left ease-out 0.1s;
}
.cell-w-order-entry-panel__advanced--compact {
  display: none;
  height: calc(100% - 30px);
  margin-left: 0;
  width: 100%;
  z-index: unset;
}
.cell-w-order-entry-panel__advanced--compact--show {
  display: block;
}
.cell-w-order-entry-panel__advanced--compact .cell-row:only-child {
  height: 100%;
}
.cell-w-order-entry-panel__advanced .cell-order-entry-panel__content {
  height: 325px;
}
.cell-w-order-entry-panel--compact {
  height: calc(100% - 30px);
  width: 100%;
  z-index: unset;
}
.cell-w-order-entry-panel--compact .cell-order-entry-panel__content {
  padding-bottom: 40px;
  min-height: 100%;
}
.cell-w-order-entry-panel--compact .cell-row:only-child {
  height: 100%;
}
.cell-w-order-entry__buttons {
  z-index: 100;
  height: 325px;
  position: relative;
  pointer-events: none;
}
.cell-w-order-entry__buttons--compact {
  position: relative;
  height: calc(100% - 30px);
  width: 100%;
}
.cell-w-order-entry__buttons__wrapper {
  position: absolute;
  bottom: 0;
}
.cell-w-order-entry__buttons__wrapper--compact {
  position: relative;
}
.cell-w-order-entry__buttons__button-content {
  padding: 0px 12px 2px 12px;
  border: 1px solid;
  width: 100%;
  height: 100%;
  pointer-events: visible;
}
.cell-w-order-entry__buttons__button-content--compact {
  padding: 6px;
  border-style: none solid solid solid;
}
.cell-w-order-entry__buttons .cell-trading-button-well .cell-button {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.cell-w-order-entry-flex input,
.cell-w-order-entry-flex select,
.cell-w-order-entry-flex button {
  width: 100%;
}
.cell-w-order-entry-flex label,
.cell-w-order-entry-flex span {
  line-height: 23px;
  white-space: nowrap;
}
.cell-w-order-entry-flex select .cell-input {
  padding-top: 1px;
}
.cell-w-order-entry-flex__separator {
  border-top: 1px solid "";
  margin: 10px 0;
}
.cell-w-order-entry-flex__panel {
  z-index: 2;
}
.cell-w-order-entry-flex__panel--advanced {
  margin-left: -100%;
  z-index: 1;
}
.cell-w-order-entry-flex__panel--advanced--show {
  margin-left: unset;
}
.cell-w-order-entry-flex__advanced-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.cell-w-order-entry-flex__algo-row .cell-flex-row {
  margin-top: 5px;
}
.cell-w-order-entry-flex__algo .cell-w-order-entry-flex__limited {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  max-width: 100%;
}
.cell-w-order-entry-flex__algo .cell-w-order-entry-flex__limited > div {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
}
.cell-w-order-entry-flex__algo .cell-w-order-entry-flex__limited > div:first-of-type {
  padding-right: 10px;
}
.cell-w-order-entry-flex__algo .cell-w-order-entry-flex__limited > div:last-of-type {
  padding-left: 10px;
}
.cell-w-order-entry-flex__limit-row .cell-flex-row {
  margin-top: 5px;
}
.cell-w-order-entry-flex__custom-fields-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.cell-w-order-entry-flex__custom-fields-row .cell-flex-row {
  margin-top: 5px;
}
.cell-w-order-entry-flex__data-group .cell-flex-row .cell-flex-column + .cell-flex-column {
  text-align: right;
}
.cell-w-order-entry-flex__sub-type-column .cell-flex-row {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.cell-w-order-entry-flex__values-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
}
.cell-w-order-entry-flex__input--ccy {
  line-height: 23px;
  margin-left: 5px;
  opacity: 0.5;
  position: absolute;
}
.cell-w-order-entry-flex__search-modify .cell-w-order-entry-flex__search-label {
  float: left;
}
.cell-w-order-entry-flex__search-modify .cell-w-order-entry-flex__search-box {
  float: right;
}
.cell-w-order-entry__single-value {
  height: 26px;
  line-height: 26px;
}
.cell-w-order-entry__input-ccy-label {
  left: 111px;
  opacity: 0.5;
  position: absolute;
  top: 0px;
}
.cell-w-order-entry__button-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.cell-w-order-entry__button-row--compact {
  position: relative;
}
.cell-w-order-entry__adv-toggler {
  cursor: pointer;
  float: left;
  height: 25px;
  line-height: 25px;
  text-align: center;
  width: 100%;
}
.cell-w-order-entry__status-panel {
  float: left;
  height: 100px;
}
.cell-w-order-entry__status-panel--compact {
  width: 100%;
}
.cell-w-order-entry__tick-size-alert {
  height: 20px;
  position: absolute;
  right: 110px;
  top: 1px;
  width: 20px;
}
.cell-w-order-entry__feedback__header {
  font-weight: bold;
  margin: 11px 0 0;
}
.cell-w-order-entry__feedback__body {
  margin: 6px 0 0;
}
.cell-w-order-entry__feedback__error {
  margin-top: 7px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-top: 5px;
}
.cell-w-order-entry__input-pair {
  margin: 6px 0 0;
  position: relative;
  width: 100%;
}
.cell-w-order-entry__input-pair .cell-w-date-selector {
  left: auto;
  right: 0;
}
.cell-w-order-entry__input-pair input[type="checkbox"] {
  margin: 3px 5px 7px 0;
}
.cell-w-order-entry__input-pair input[type="number"] {
  -moz-appearance: textfield;
}
.cell-w-order-entry__input-pair input[type="number"]::-webkit-inner-spin-button,
.cell-w-order-entry__input-pair input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cell-w-order-entry__input-pair input,
.cell-w-order-entry__input-pair select {
  margin-bottom: 5px;
  margin-right: 0;
}
.cell-w-order-entry__input-pair input:not([type="checkbox"]),
.cell-w-order-entry__input-pair select:not([type="checkbox"]) {
  width: 100%;
}
.cell-w-order-entry__input-pair textarea {
  margin-top: -3px;
  width: 100%;
}
.cell-w-order-entry__input-pair label {
  line-height: 22px;
}
.cell-w-order-entry__input-validity-date > .cell-flex-row {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
}
.cell-w-order-entry__tick-button {
  height: 22px;
  position: relative;
  width: 22px;
}
.cell-w-order-entry__divider {
  float: left;
  height: 1px;
  margin: 10px 0 0;
  width: 100%;
}
.cell-w-order-entry__order-info-item {
  margin: 9px 0 0;
}
.cell-w-order-entry__order-info-item select {
  background-color: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: auto;
  line-height: normal;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-w-order-entry__order-info-item select:focus {
  border: 0;
}
.cell-w-order-entry__order-info-item div {
  line-height: normal;
}
.cell-w-order-entry__buy-or-sell {
  padding-right: 6px;
}
.cell-w-order-entry-algo__algo-name {
  float: left;
  font-size: 13px;
  font-style: italic;
  margin-top: 12px;
  width: 100%;
}
.cell-w-order-entry-algo__algo-desc {
  float: left;
  line-height: 1.35;
  margin-top: 5px;
  width: 100%;
}
.cell-w-order-entry__kid {
  display: none;
}
@media screen and (min-width: 40em) {
  .cell-w-order-entry__kid {
    display: block;
    float: right;
  }
}
.cell-w-order-entry__kid--mobile {
  display: none;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-order-entry__kid--mobile {
    display: block;
  }
}
.cell-w-order-entry__kid--mobile .cell-w-order-entry__kid__link {
  display: inline-block;
  padding: 20px 10px;
}
.cell-w-order-entry--compact {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.cell-w-order-entry--compact .cell-tabs__horizontal {
  height: 30px;
  margin: 0;
  padding: 0;
  width: 100%;
}
.cell-w-order-entry--compact .cell-tabs__horizontal__tab {
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  margin: 0;
  position: relative;
  text-align: center;
  width: 50%;
}
.cell-w-order-entry--compact .cell-tabs__horizontal__tab--invalid {
  -webkit-animation: flash-negative 2s;
  animation: flash-negative 2s;
  padding-right: 23px;
  position: relative;
}
.cell-w-order-entry--compact .cell-tabs__horizontal__tab--invalid::after {
  content: "";
  cursor: none;
  height: 18px;
  position: absolute;
  right: 10px;
  top: 5px;
  -webkit-transition: background-color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  width: 18px;
}
.cell-w-order-entry--compact .cell-tabs__horizontal__tab > a {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cell-w-order-entry--compact .cell-w-order-entry-flex__search,
.cell-w-order-entry--compact .cell-w-order-entry-flex__volume,
.cell-w-order-entry--compact .cell-w-order-entry-flex__price,
.cell-w-order-entry--compact .cell-w-order-entry-flex__input--open-volume,
.cell-w-order-entry--compact .cell-w-order-entry-flex__input--validity-date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.cell-w-order-entry--compact .cell-w-order-entry-flex__search > div,
.cell-w-order-entry--compact .cell-w-order-entry-flex__volume > div,
.cell-w-order-entry--compact .cell-w-order-entry-flex__price > div,
.cell-w-order-entry--compact .cell-w-order-entry-flex__input--open-volume > div,
.cell-w-order-entry--compact .cell-w-order-entry-flex__input--validity-date > div {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0;
}

.cell-order-entry-panel__content {
  height: 293px;
}
.cell-order-entry-panel__content--compact {
  height: unset;
  min-height: 100%;
}

.cell-order-entry-panel__content--advanced {
  overflow: auto;
}

.cell-order-entry-wrapper {
  width: 220px;
}
.cell-order-entry-wrapper--compact {
  height: 100%;
  width: 100%;
}

@-webkit-keyframes flash-negative {
  50% {
    background-color: "";
    opacity: 0.35;
  }
}

@keyframes flash-negative {
  50% {
    background-color: "";
    opacity: 0.35;
  }
}
.cell-w-order-widget {
  border: 1px solid #ffffff;
}
.cell-w-order-widget-row {
  background-color: #555555;
  border: 1px solid #333333;
  border-width: 0 0 1px 0;
  cursor: pointer;
  float: left;
  height: 48px;
  padding: 9px;
  position: relative;
  width: 100%;
}
.cell-w-order-widget__ticker {
  float: left;
  font-size: 14px;
  font-weight: bold;
}
.cell-w-order-widget__buy-or-sell {
  float: left;
  font-size: 14px;
  padding-right: 6px;
}
.cell-w-order-widget__volume {
  float: left;
  padding-left: 4px;
  padding-right: 4px;
}
.cell-w-order-widget__price {
  float: left;
}
.cell-w-order-widget__price::before {
  content: " @ ";
}
.cell-w-order-widget__details {
  float: right;
  text-align: right;
}
.cell-w-order-widget__order-id {
  float: left;
  padding-left: 3px;
}
.cell-w-order-widget__status {
  float: right;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.5;
  text-transform: uppercase;
}
.cell-w-order-widget__bid-ask-lbl,
.cell-w-order-widget__avg-price-lbl {
  margin-right: 3px;
}
.cell-w-order-widget__currency {
  float: left;
  padding-left: 4px;
}
.cell-w-order-widget__condition {
  float: left;
  opacity: 0.5;
}
.cell-w-order-widget__volume-filled {
  float: left;
}
.cell-w-order-widget__volume-filled::after {
  content: " /";
}
.cell-w-order-widget__actionbox {
  position: absolute;
  right: 0;
  top: 0;
}

.cell-w-orders__order-fill-bar {
  background-color: #dddddd;
  height: 11px;
  padding: 0;
  position: relative;
  width: 100%;
}
.cell-w-orders__order-fill-bar__filled {
  background-color: #dddddd;
  height: 11px;
  padding: 0;
  position: relative;
}

.cell-w-portfolio-details {
  font-size: 15px;
}
.cell-w-portfolio-details__label {
  font-size: 13px;
  font-weight: normal;
  margin: 18px 0 4px;
  text-transform: uppercase;
}
.cell-w-portfolio-details__value {
  font-size: 22px;
}
.cell-w-portfolio-selected__label,
.cell-w-portfolio-selected__title {
  margin-left: 8px;
  padding-left: 8px;
}
.cell-w-portfolio-select__title--interaction {
  cursor: pointer;
}
.cell-w-portfolio-select__title--interaction:hover {
  text-decoration: underline;
}
.cell-w-portfolio-select__inline-wrapper {
  display: inline-block;
  margin-bottom: -2px;
}
.cell-w-portfolio-select__scaling-button {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
}
.cell-w-portfolio-select--scaling-wrapper {
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.cell-w-portfolio-select--scaling-text {
  float: left;
}
.cell-w-portfolio-select--scaling-buttonContainer {
  float: left;
  height: 100%;
  margin-left: 3px;
}
.cell-w-portfolio-select--scaling__dd-wrapper {
  display: none;
  margin-top: 2px;
  position: absolute;
  z-index: 800;
}
.cell-w-portfolio-select--scaling__dd {
  text-align: left;
  width: 100%;
}
.cell-w-portfolio-select--scaling__dd__label {
  cursor: pointer;
  padding: 5px 6px 3px;
  text-align: left;
}
.cell-w-portfolio-ranking__item {
  padding: 8px 0;
}
.cell-w-portfolio-ranking__item:last-child {
  border-bottom-width: 0;
}
.cell-w-portfolio-ranking__top-row {
  padding: 2px 0;
}
.cell-w-portfolio-ranking__top-row--ticker {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 3px;
}
.cell-w-portfolio-ranking__top-row--values {
  font-size: 12px;
}
.cell-w-portfolio-ranking__bottom-row {
  margin: 0 0 3px;
}
.cell-w-portfolio-ranking__bottom-row--change-value {
  font-size: 12px;
  padding-bottom: 3px;
}
.cell-w-portfolio-ranking__bottom-row--bar {
  height: 10px;
  max-width: 100%;
}
.cell-w-portfolio-ranking--right .cell-w-portfolio-ranking__top-row--ticker,
.cell-w-portfolio-ranking--left .cell-w-portfolio-ranking__top-row--values,
.cell-w-portfolio-ranking--right .cell-w-portfolio-ranking__bottom-row--change-value {
  text-align: left;
}
.cell-w-portfolio-ranking--left .cell-w-portfolio-ranking__top-row--ticker,
.cell-w-portfolio-ranking--right .cell-w-portfolio-ranking__top-row--values,
.cell-w-portfolio-ranking--left .cell-w-portfolio-ranking__bottom-row--change-value {
  text-align: right;
}
.cell-w-portfolio-ranking--right .cell-w-portfolio-ranking__top-row--values,
.cell-w-portfolio-ranking--left .cell-w-portfolio-ranking__bottom-row--change-value,
.cell-w-portfolio-ranking--left .cell-w-portfolio-ranking__bottom-row--bar {
  float: right;
}
.cell-w-portfolio-ranking--left .cell-w-portfolio-ranking__top-row--values,
.cell-w-portfolio-ranking--right .cell-w-portfolio-ranking__bottom-row--change-value,
.cell-w-portfolio-ranking--right .cell-w-portfolio-ranking__bottom-row--bar {
  float: left;
}
.cell-w-portfolio-ranking--left,
.cell-w-portfolio-ranking--right {
  min-height: 10px;
  min-width: 10px;
}

.cell-w-positions__header-item {
  padding: 10px 0;
}
.cell-w-positions__header-label {
  font-size: 0.875rem;
  text-align: center;
}
.cell-w-positions__header-instrument {
  display: inline;
}
.cell-w-positions__header-ticker {
  cursor: pointer;
  padding: 10px 0 0;
  position: relative;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-positions__header-ticker {
    font-size: 1rem;
  }
}
.cell-w-positions__header-ticker::after {
  content: "";
  height: 4px;
  position: absolute;
  right: 0;
  top: 10px;
  width: 4px;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-positions__header-ticker .cell-flag {
    margin-top: 2px;
  }
}
.cell-w-positions__header-ticker:focus {
  outline: none;
}
.cell-w-positions__payload--compact .cell-w-positions__data-value span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-w-positions__item.active .cell-w-positions__extension {
  display: block;
}
.cell-w-positions__item.active .cell-w-positions__header-ticker::after {
  top: 15px;
}
.cell-w-positions__data {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 0;
  text-align: center;
}
@media screen and (min-width: 40em) {
  .cell-w-positions__data {
    font-size: 0.6875rem;
    padding: 5px 0;
  }
}
.cell-w-positions__data-label {
  display: block;
  line-height: normal;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-positions__data-label {
    font-size: 0.875rem;
  }
}
@media screen and (min-width: 40em) {
  .cell-w-positions__data-label {
    font-weight: bold;
  }
}
.cell-w-positions__data-value {
  padding: 3px;
}
.cell-w-positions__data-value .cell-flag {
  margin-top: 3px;
}
.cell-w-positions__extension {
  display: none;
}
.cell-w-positions__extension-item {
  font-size: 0.875rem;
  padding: 10px 0;
}
@media screen and (min-width: 40em) {
  .cell-w-positions__extension-item {
    font-size: 0.6875rem;
    padding: 5px 0;
  }
}
.cell-w-positions__extension-item-value {
  text-align: right;
}
.cell-w-positions__trading-info {
  width: 1.5rem;
  padding-top: 0.1875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: row wrap;
  -ms-flex-positive: row wrap;
  flex-grow: row wrap;
  padding-left: 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.cell-w-positions__trading-info-trades,
.cell-w-positions__trading-info-active-orders {
  display: inline-block;
  width: 0.5rem;
  height: 1.0625rem;
}
.cell-w-positions__trading-info-trades {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.cell-w-positions__trading-info-active-orders {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.cell-w-positions__trading-notification {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 7px;
  padding: 7px 10px;
}
.cell-w-positions__trading-notification--active-orders {
  text-align: center;
}
.cell-w-positions__trading-notification--trades {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  text-align: center;
}
.cell-w-positions__trading-notification--volume,
.cell-w-positions__trading-notification--value,
.cell-w-positions__trading-notification--realized {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  min-width: 0;
  text-transform: capitalize;
}
.cell-w-positions__empty-message {
  font-size: 0.875rem;
  text-align: center;
}
.cell-w-positions .cell-button-primary,
.cell-w-positions .cell-button-active {
  font-weight: normal;
  text-transform: none;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-positions .cell-button-primary,
  .cell-w-positions .cell-button-active {
    width: 100%;
  }
}

.cell-w-put-call__pct-change {
  padding: 0;
  position: relative;
  top: 4px;
}
.cell-w-put-call__symbol-panel--title {
  cursor: pointer;
  padding-left: 1px;
}
.cell-w-put-call__symbol-panel--bid div,
.cell-w-put-call__symbol-panel--ask div,
.cell-w-put-call__symbol-panel--last div {
  display: inline;
  margin-left: 2px;
}
.cell-w-put-call__sub-title--extra {
  display: inline-block;
  margin-left: 5px;
}
.cell-w-put-call__sub-title--extra div {
  display: inline-block;
}

.cell-w-simple-chart-overview {
  float: left;
  height: 100%;
  width: 100%;
}
.cell-w-simple-chart-overview__chart {
  left: 0;
  opacity: 0.4;
  position: absolute;
  width: 100%;
}
.cell-w-simple-chart-overview__content {
  height: 100%;
  padding: 11px 12px 10px 12px;
  width: 100%;
}
.cell-w-simple-chart-overview__name {
  float: left;
  font-size: 22px;
  font-weight: bold;
  position: relative;
  width: 100%;
}
.cell-w-simple-chart-overview__last,
.cell-w-simple-chart-overview__pct {
  float: left;
  font-size: 14px;
  font-weight: normal;
  padding: 5px 10px 0 0;
  position: relative;
}
.cell-w-simple-chart-overview__pct {
  padding: 5px 0 0 0;
}
.cell-w-simple-chart-overview__period {
  bottom: 5px;
  font-weight: bold;
  opacity: 0.3;
  position: absolute;
  right: 6px;
}

.cell-w-switch {
  cursor: pointer;
  float: left;
  padding: 10px 0;
}
.cell-w-switch--disabled {
  cursor: default;
  opacity: 0.5;
}
.cell-w-switch__case {
  border: 0;
  border-radius: 10px;
  height: 10px;
  position: relative;
  -webkit-transition: background linear 0.06s;
  transition: background linear 0.06s;
  width: 22px;
}
.cell-w-switch__case--on {
  -webkit-transition: background linear 0.06s;
  transition: background linear 0.06s;
}
.cell-w-switch__handle {
  border: 0;
  border-radius: 50%;
  height: 16px;
  left: -3px;
  padding: 0;
  position: absolute;
  top: -3px;
  -webkit-transition: background linear 0.1s, left ease-out 0.06s;
  transition: background linear 0.1s, left ease-out 0.06s;
  width: 16px;
}
.cell-w-switch__handle--on {
  left: 9px;
  -webkit-transition: background linear 0.1s, left ease-out 0.06s;
  transition: background linear 0.1s, left ease-out 0.06s;
}

.cell-w-top-entities__name-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-top-entities__toolbar {
    margin-bottom: 15px;
  }
}
.cell-w-top-entities__dropdown {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.cell-w-top-entities__entity-name {
  font-size: 22px;
  font-weight: normal;
  margin: 0 0 15px 0;
}
.cell-w-top-entities__to-label {
  margin: 0 3px 0 5px;
}
.cell-w-top-entities__to-label:after {
  content: ":";
}
.cell-w-top-entities__from-label {
  margin: 0 3px 0 0;
}
.cell-w-top-entities__from-label:after {
  content: ":";
}
.cell-w-top-entities__period {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.cell-w-top-entities__grouping {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}
.cell-w-top-entities__table-positions {
  fill: transparent;
  height: 20px;
  stroke-width: 1px;
}
.cell-w-top-entities__table-positions-chart {
  height: 20px;
  width: 100px;
}
.cell-w-top-entities__table-positions__padding-override {
  padding: 4px 3px 2px 3px !important;
}
.cell-w-top-entities__table-pct-weighted-ownership {
  height: 12px;
}
.cell-w-top-entities__table-pct-weighted-value {
  height: 12px;
}
.cell-w-top-entities__table-pct-max-weighted-ownership {
  height: 12px;
}
.cell-w-top-entities__table-pct-max-weighted-value {
  height: 12px;
}
.cell-w-top-entities__ownership-indicator-data__padding-override {
  padding: 1px !important;
}
.cell-w-top-entities__ownership-indicator-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: row nowrap;
  -ms-flex-positive: row nowrap;
  flex-grow: row nowrap;
}
.cell-w-top-entities__ownership-indicator-left-separator {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 2px;
  margin-right: 10px;
}
.cell-w-top-entities__ownership-indicator-text {
  font-size: 11px;
}
.cell-w-top-entities__ownership-indicator-text:before {
  content: "> ";
}
.cell-w-top-entities__ownership-indicator-text:after {
  content: "%";
}
.cell-w-top-entities__ownership-indicator-right-separator {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 2px;
  margin-left: 10px;
}

.cell-trading-button-well {
  width: calc(50% - 5px);
}

.cell-w-trading-1__top-row {
  margin-bottom: 6px;
  margin-top: 8px;
}
.cell-w-trading-1__mid-row {
  border: 1px solid #dddddd;
  margin-bottom: 15px;
  margin-top: 6px;
  padding-bottom: 18px;
  padding-top: 11px;
}
.cell-w-trading-1__bottom-row {
  float: left;
  padding-bottom: 5px;
  padding-top: 4px;
  width: 100%;
}
.cell-w-trading-1__confirm-top-row {
  border-color: #dddddd;
  border-style: solid;
  border-width: 5px 0 0;
  margin-bottom: 0;
  margin-top: 5px;
  padding: 7px;
}
.cell-w-trading-1__confirm-msg {
  font-size: 13px;
}
.cell-w-trading-1__confirm-details-table {
  border-collapse: collapse;
  width: 100%;
}
.cell-w-trading-1__confirm-details-table td {
  padding: 7px 3px;
}
.cell-w-trading-1__error-msg-row__msg {
  font-size: 13px;
  font-style: italic;
  line-height: 110%;
}
.cell-w-trading-1__confirm-details-table2 {
  margin-bottom: 0;
}
.cell-w-trading-1__confirm-details-buttons {
  bottom: 10px;
  position: absolute;
  right: 10px;
}
.cell-w-trading-1__exchange-message {
  line-height: 120%;
  padding: 0 10px;
}
.cell-w-trading-1__adv-method-row {
  border-top: 1px solid #dddddd;
  margin-bottom: 8px;
  margin-top: 1px;
  padding-top: 10px;
}
.cell-w-trading-1__adv-volume-row {
  margin: 2px 0 10px;
}

.cell-w-value-pair__leftcol {
  float: left;
  height: 35px;
  padding-right: 6px;
  padding-top: 3px;
  text-align: right;
  width: 50%;
}
.cell-w-value-pair__rightcol {
  border-left: 1px solid #ffffff;
  float: left;
  height: 35px;
  padding-left: 5px;
  padding-top: 3px;
  text-align: left;
  width: 50%;
}
.cell-w-value-pair__label {
  font-size: 11px;
  font-weight: bold;
  margin-top: 0;
  text-transform: uppercase;
  white-space: nowrap;
}
.cell-w-value-pair__value {
  margin-top: 4px;
}

/*.cell-w-wire {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 10px;

    &__card {
        animation: appear-kf 0.45s cubic-bezier(0.34, 0.44, 0.08, 0.92);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        font-size: 13px;
        margin-bottom: 10px;
        overflow-y: hidden;
        padding-bottom: 5px;
        position: relative;

        &--filtered {
            opacity: 0.7;

            .cell-w-wire {
                &__footer,
                &__market-data,
                &__card__pill-wrapper {
                    display: none;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__time {
            margin-bottom: 5px;
        }

        &__flashes {
            border-width: 0 0 0 2px;
            margin-bottom: 5px;
            padding: 6px 6px 0 6px;
            width: 100%;

            &:empty {
                display: none;
            }

            &--collapsed {
                .cell-w-wire__card__flashes__item {
                    &:nth-child(n+3) {
                        border-width: 0;
                        height: 0;
                        overflow: hidden;
                        padding-bottom: 0;
                        padding-top: 0;
                        transition: all ease-out 0.15s;
                    }
                }
            }

            &__item {
                line-height: 1.25;
                padding: 0 0 6px;
                transition: all ease-out 0.15s;

                &__time {
                    padding-right: 5px;
                }
            }
        }

        &__calendar {
            &__table {
                border-spacing: 0;
                table-layout: fixed;
                width: 100%;

                &__col1 {
                    text-align: left;
                    width: 50%;
                }
            }

            &__table__col2,
            &__table__col3,
            &__table__col4,
            &__table__col5 {
                text-align: right;
                width: 12.5%;
            }

            &__table__header-cell {
                font-weight: bold;
                padding: 0 0 4px;
            }

            &__table__cell {
                overflow: hidden;
                padding: 4px 0;
                white-space: nowrap;

                > .cell-flag {
                    margin-top: 1px;
                }

                &:empty {
                    &::after {
                        content: '-';
                    }
                }
            }

            &--collapsed {
                &:nth-child(n+3) {
                    td {
                        display: none;
                    }
                }
            }

            &__item {
                line-height: 1.25;
                padding: 0 0 3px;
                transition: all ease-out 0.15s;
            }
        }

        &__header {
            cursor: pointer;
            display: block;
            font-weight: bold;
            line-height: 1.25;
            margin-bottom: 5px;
            padding: 0;

            &:empty {
                display: none;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &__pill-wrapper {
            &:empty {
                display: none;
            }

            &__pill {
                border-radius: 5px;
                font-size: 10px;
                font-weight: bold;
                margin-right: 5px;
                padding: 2px 4px 1px;
                text-transform: uppercase;
                white-space: nowrap;
            }
        }

        &__score {
            background-color: transparent;
            font-size: 12px;
            font-weight: bold;
            margin: 0 0 0 3px;
            padding: 0 4px;
            position: absolute;
            right: 0;
            top: 0;
        }

        &__market-data {
            display: flex;
            flex-direction: row;
            font-size: 14px;
            justify-content: space-between;
            padding: 5px;

            &__item {
                margin-right: 6px;

                &:link,
                &:active,
                &:hover,
                &:visited {
                    text-decoration: none;
                }

                &:empty {
                    display: none;
                }
            }
        }
    }

    &__setup-button {
        background-image: url('https://trader.infrontfinance.com/Content/gfx/ic_more_vert_white_24px.svg'); //sass-lint:disable-line no-url-protocols TODO: HOTLINKED FROM WT
        background-position: top 1px left 1px;
        background-repeat: no-repeat;
        background-size: 24px;
        cursor: pointer;
        height: 24px;
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
    }

    &__header {
        font-size: 24px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        position: relative;

        &:link,
        &:active,
        &:hover,
        &:visited {
            text-decoration: none;
        }
    }

    &--read {
        opacity: 0.6;
    }

    &__description {
        display: block;
        font-style: italic;
        line-height: inherit;
        padding: 3px;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0;

        &__content {
            align-items: center;
            display: flex;
            height: 24px;

            &:link,
            &:active,
            &:hover,
            &:visited {
                text-decoration: none;
            }

            &:empty {
                display: none;
            }
        }

        &__clicker {
            align-items: center;
            background-image: url('baseline-keyboard_arrow_down-24px.svg');
            background-position: top right;
            background-repeat: no-repeat;
            background-size: 24px;
            cursor: pointer;
            display: flex;
            height: 24px;
            padding: 0 24px 0 5px;

            &:empty {
                display: none;
            }

            &--expanded {
                background-image: url('baseline-keyboard_arrow_up-24px.svg');
            }

            &--alert {
                background-image: url('ic_notifications_active_white_18px.svg');
                background-position: top 4px right 0;
                background-size: 18px;
            }
        }
    }
}

@keyframes appear-kf {
    0% {
        max-height: 0;
        opacity: 0.2;
    }

    100% {
        max-height: 120px;
        opacity: 1;
    }
}*/
.cell-w-company-fundamentals .cell-table .cell-w-company-fundamentals__list-item-label {
  display: none;
}
.cell-w-company-fundamentals__list {
  position: relative;
}
.cell-w-company-fundamentals__list-header {
  text-transform: uppercase;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-company-fundamentals__list-header {
    display: none;
  }
}
@media screen and (min-width: 40em) {
  .cell-w-company-fundamentals__list-cell {
    padding: 7px 3px;
  }
}
@media screen and (max-width: 39.99875em) {
  .cell-w-company-fundamentals__list-cell:not(:first-of-type) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (min-width: 40em) {
  .cell-w-company-fundamentals__list-cell:not(:first-of-type) {
    text-align: right;
  }
}
.cell-w-company-fundamentals__list-item {
  margin-bottom: 15px;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-company-fundamentals__list-item > .cell-flex-column {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}
@media screen and (min-width: 40em) {
  .cell-w-company-fundamentals__list-item {
    margin-bottom: 0;
  }
}
.cell-w-company-fundamentals__list-item-label {
  color: #ececec;
  display: block;
  padding: 7px 2px 0;
}
@media screen and (min-width: 40em) {
  .cell-w-company-fundamentals__list-item-label {
    display: none;
    padding: 7px 2px;
  }
}
.cell-w-company-fundamentals__list-item-field {
  display: inline-block;
}
@media screen and (max-width: 39.99875em) {
  .cell-w-company-fundamentals__list-item-field {
    padding: 7px 2px;
  }
  .cell-w-company-fundamentals__list-item-field:first-of-type {
    text-transform: uppercase;
  }
}
.cell-w-company-fundamentals__list-item--card {
  margin-bottom: 15px;
}
.cell-w-company-fundamentals__list-item--card-label {
  color: #ececec;
  display: inline-block;
  padding: 7px 2px;
  text-transform: uppercase;
}
.cell-w-company-fundamentals__list-item--card-field {
  display: block;
  float: right;
  padding: 7px 2px;
}

.cell-w-alert-list {
  min-width: 300px;
}
.cell-w-alert .cell-alert-focusMini {
  margin: 12px 0 0;
}

.cell-w-newslist__title {
  padding: 5px 0;
}
.cell-w-newslist__title-item {
  font-size: 11px;
  font-weight: 700;
}
.cell-w-newslist__title .cell-w-newslist-source,
.cell-w-newslist__title .cell-w-newslist-short-source {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8%;
  flex: 0 0 8%;
  max-width: 8%;
}
.cell-w-newslist__body {
  clear: both;
}
.cell-w-newslist__body-item {
  padding: 5px 0;
}
.cell-w-newslist__body-item-wrapper {
  cursor: pointer;
}
.cell-w-newslist__body-item-link {
  text-decoration: none;
}
@media screen and (min-width: 40em) {
  .cell-w-newslist__body-item .cell-w-newslist-heading .cell-w-newslist__body-item-headline {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.cell-w-newslist__body--compact .cell-w-newslist-source,
.cell-w-newslist__body--compact .cell-w-newslist-short-source {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8%;
  flex: 0 0 8%;
  max-width: 8%;
}
.cell-w-newslist__body--expanded .cell-w-newslist-heading {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-top: 5px;
  max-width: 100%;
}
.cell-w-newslist__source-dropdown {
  margin-right: 5px;
}
.cell-w-newslist-time {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 60px !important;
  flex: 0 0 60px !important;
  max-width: 60px !important;
}

.cell-w-financial-calendar__title {
  padding: 5px 0;
}
.cell-w-financial-calendar__title-ticker,
.cell-w-financial-calendar__title-category,
.cell-w-financial-calendar__title-description,
.cell-w-financial-calendar__title-date {
  font-size: 11px;
  font-weight: 700;
}
.cell-w-financial-calendar__title-date {
  -webkit-box-flex: 0 !important;
  -ms-flex: 0 0 60px !important;
  flex: 0 0 60px !important;
  max-width: 60px !important;
}
.cell-w-financial-calendar__title-ticker {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}
.cell-w-financial-calendar__payload-item {
  padding: 5px 0;
}
.cell-w-financial-calendar__payload-date {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60px;
  flex: 0 0 60px;
  max-width: 60px;
}
.cell-w-financial-calendar__payload--expanded .cell-w-financial-calendar__payload-description {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-top: 5px;
  max-width: 100%;
}
.cell-w-financial-calendar__payload--compact .cell-w-financial-calendar__payload-ticker {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}

.cell-w-chart-nextgen {
  width: 100%;
  height: 100%;
  position: relative;
  /* SETTING MODAL stylings */
  /* START chart dropdown container stylings @FIXME can be outsourced into own stylesheet? */
  /* DROPDOWN stylings */
  /* VARIOUS stylings */
}
.cell-w-chart-nextgen__wrapper {
  height: auto;
}
.cell-w-chart-nextgen__toolbar {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: auto;
  overflow-x: scroll;
  padding: 5px 5px;
  scrollbar-width: none;
}
.cell-w-chart-nextgen__toolbar::-webkit-scrollbar {
  display: none;
}
.cell-w-chart-nextgen__toolbar__general-settings,
.cell-w-chart-nextgen__toolbar__analytic-settings {
  padding: 2px 0 0 5px;
}
.cell-w-chart-nextgen__toolbar__general-settings li:first-child .cell-dropdown-list,
.cell-w-chart-nextgen__toolbar__analytic-settings li:first-child .cell-dropdown-list {
  padding-left: 2px;
}
.cell-w-chart-nextgen__toolbar__general-settings li:last-child > *,
.cell-w-chart-nextgen__toolbar__analytic-settings li:last-child > * {
  margin-right: 0;
  padding-right: 5px;
}
.cell-w-chart-nextgen__toolbar__advanced-settings {
  padding: 0 0 0 5px;
}
.cell-w-chart-nextgen__toolbar__advanced-settings li:first-child .cell-mininav {
  margin-left: -5px;
}
.cell-w-chart-nextgen__toolbar__advanced-settings li:last-child > * {
  margin-right: 0;
}
.cell-w-chart-nextgen__toolbar__instrument_header__chart-fields,
.cell-w-chart-nextgen__toolbar__instrument_header__quote-fields {
  padding: 0 0 0 5px;
}
.cell-w-chart-nextgen__toolbar__instrument_header__chart-fields div:first-child,
.cell-w-chart-nextgen__toolbar__instrument_header__quote-fields div:first-child {
  padding-left: 0px;
}
.cell-w-chart-nextgen__toolbar__full-screen-button {
  padding: 1px 0 0 5px !important;
}
.cell-w-chart-nextgen__toolbar__full-screen-button .cell-mininav {
  margin-right: 0 !important;
}
.cell-w-chart-nextgen__toolbar-separator-left {
  position: relative;
  padding: 0 0 0 5px;
}
.cell-w-chart-nextgen__toolbar-separator-left::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
}
.cell-w-chart-nextgen__toolbar-separator-right {
  position: relative;
  padding-right: 10px !important;
}
.cell-w-chart-nextgen__toolbar-separator-right::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
}
.cell-w-chart-nextgen__toolbar .cell-list-flex {
  margin: 0;
}
.cell-w-chart-nextgen__toolbar-top .cell-list-flex > li {
  margin-right: 0px;
}
.cell-w-chart-nextgen__toolbar .cell-mininav__btn {
  border-radius: 0;
  margin: 0;
  padding: 4px;
}
.cell-w-chart-nextgen__toolbar .cell-mininav__btn--unavailable {
  display: none;
}
.cell-w-chart-nextgen__toolbar .cell-mininav__label {
  border: 0;
  border-radius: 2px;
  font-size: 11px;
  height: 20px;
  padding: 5px 4px 0 5px;
  float: left;
}
.cell-w-chart-nextgen__toolbar-bottom .cell-dropdown-list,
.cell-w-chart-nextgen__toolbar-bottom .cell-mininav__btn-icon-calendar {
  margin-left: 10px;
}
.cell-w-chart-nextgen__edit-modal {
  height: auto;
  position: absolute !important;
  width: 290px;
  z-index: 90;
}
.cell-w-chart-nextgen__edit-modal-overlay {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 89;
}
.cell-w-chart-nextgen__edit-modal-header {
  text-transform: uppercase;
  margin: 5px 0;
}
.cell-w-chart-nextgen__edit-modal--centered {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.cell-w-chart-nextgen__edit-modal-multi-select {
  position: relative;
  display: block;
}
.cell-w-chart-nextgen__edit-modal-multi-select::after {
  content: "";
  cursor: pointer;
  height: 2px;
  position: absolute;
  right: 5px;
  top: -13px;
  -webkit-transition: border-color 0.35s ease-out;
  transition: border-color 0.35s ease-out;
  width: 2px;
}
.cell-w-chart-nextgen__edit-modal-color-picker {
  position: relative;
}
.cell-w-chart-nextgen__edit-modal-color-picker::after {
  content: "";
  cursor: pointer;
  height: 2px;
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-transition: border-color 0.35s ease-out;
  transition: border-color 0.35s ease-out;
  width: 2px;
}
.cell-w-chart-nextgen__edit-modal-color-matrix {
  margin: 5px 0;
  overflow: auto;
}
.cell-w-chart-nextgen__edit-modal-reset {
  cursor: pointer;
  height: 16px;
  margin-bottom: 5px;
  vertical-align: middle;
  width: 16px;
}
.cell-w-chart-nextgen__edit-modal-fieldset {
  border-width: 1px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.cell-w-chart-nextgen__edit-modal-fieldset .cell-flex-column {
  padding: unset;
}
.cell-w-chart-nextgen__edit-modal__div_wrapper {
  clear: both;
  width: 100%;
}
.cell-w-chart-nextgen__edit-modal-header,
.cell-w-chart-nextgen__edit-modal-subheader {
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: normal;
}
.cell-w-chart-nextgen__edit-modal-header {
  width: 100%;
}
.cell-w-chart-nextgen__edit-modal-subheader {
  margin-top: 7px;
  width: 90%;
}
.cell-w-chart-nextgen__edit-modal-subheader strong {
  font-weight: normal;
}
.cell-w-chart-nextgen__edit-modal-submit_button {
  display: inline-block;
  padding: 10px;
}
.cell-w-chart-nextgen__edit-modal-input_checkbox_label {
  vertical-align: middle;
  display: inline;
}
.cell-w-chart-nextgen__edit-modal-multi-checkbox,
.cell-w-chart-nextgen__edit-modal-checkbox {
  display: inline;
  margin-left: 0px;
  margin-right: 8px;
  margin-top: 0px;
  vertical-align: middle;
}
.cell-w-chart-nextgen__edit-modal-select-label {
  line-height: 20px;
}
.cell-w-chart-nextgen__edit-modal-select {
  width: 84%;
  vertical-align: middle;
  margin-right: 5px;
}
.cell-w-chart-nextgen__edit-modal-text-label {
  line-height: 20px;
}
.cell-w-chart-nextgen__edit-modal-text {
  width: 84%;
  vertical-align: middle;
}
.cell-w-chart-nextgen__edit-modal-date-label {
  line-height: 20px;
}
.cell-w-chart-nextgen__edit-modal-date {
  width: 100%;
  vertical-align: middle;
}
.cell-w-chart-nextgen__edit-modal-color-label {
  line-height: 18px;
}
.cell-w-chart-nextgen__edit-modal-color-alpha {
  width: 70%;
  vertical-align: middle;
}
.cell-w-chart-nextgen__edit-modal-color-box {
  height: 19px;
  padding: 0;
  vertical-align: middle;
  width: 24px;
}
.cell-w-chart-nextgen__edit-modal .cell-input,
.cell-w-chart-nextgen__edit-modal .cell-button {
  margin-bottom: 5px;
}
.cell-w-chart-nextgen__edit-modal .cell-button--confirm {
  float: left;
  margin-top: 15px;
  width: 48%;
}
.cell-w-chart-nextgen__edit-modal .cell-button--cancel {
  float: right;
  margin-top: 15px;
  width: 48%;
}
.cell-w-chart-nextgen__edit-modal .cell-button--reset {
  vertical-align: middle;
}
.cell-w-chart-nextgen__edit-modal input[type="color"] {
  cursor: pointer;
  opacity: 0;
  height: 19px;
  width: 24px;
  padding: 0;
  border: none;
  vertical-align: middle;
}
.cell-w-chart-nextgen__edit-modal-navigation {
  margin-bottom: 10px;
  margin: 1px 0 15px;
  padding: 5px 5px 0;
  width: 100%;
}
.cell-w-chart-nextgen__edit-modal-navigation .cell-button:not(:last-of-type) {
  margin-right: 5px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-scrollarea .icon-svg__schedule {
  width: 16px;
  height: 16px;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-scrollarea.cell-dropdown-list__selector-scrollarea--has-search-results {
  margin-top: 5px;
}
.cell-w-chart-nextgen-dropdown-container ::-webkit-scrollbar {
  width: 5px;
}
.cell-w-chart-nextgen-dropdown-container ::-webkit-scrollbar-track {
  opacity: 0;
}
.cell-w-chart-nextgen-dropdown-container ::-webkit-scrollbar-thumb {
  border-radius: 5px;
}
.cell-w-chart-nextgen-dropdown-container ::-webkit-scrollbar-thumb:hover {
  border-radius: 5px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list-icon--separator {
  position: absolute;
  right: 4px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list-icon--rotate180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper:not(.scrollbar-hidden)
  .cell-dropdown-list__selector-scrollarea
  > div
  > div:last-child {
  padding-right: 6px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector .scrollbar-hidden ::-webkit-scrollbar {
  display: none;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-scrollarea {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 360px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector__search-container .cell-input {
  font-size: 12px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector__item--separator-label {
  text-overflow: ellipsis;
  overflow-x: hidden;
  margin-right: 30px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector__item--separator-label::-webkit-scrollbar {
  display: none;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector__item--group {
  padding-left: 20px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector__button--right {
  margin-right: 10px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector::after {
  content: none;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--period {
  min-width: 120px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--chart-type {
  min-width: 200px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--resolution {
  min-width: 130px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--indicator {
  min-width: 230px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--annotation {
  min-width: 230px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--compare {
  min-width: 190px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--new-compare {
  min-width: 220px;
  /**
  *  CSS as the one below has to be added/adjusted by the toolkit user,
  *  there's no fast way to achieve this through code options
  *  it is extensible but requires some time
  **/
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  [class*="cell-dropdown-list__content-item__symbol-classification"]
  .cell-dropdown-list__row
  [class*="cell-dropdown-list__content-item--"] {
  vertical-align: middle;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--0 {
  width: 10%;
  position: relative;
  top: 1px;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--1 {
  width: 55%;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--2 {
  width: calc(25% - 5px);
  text-align: right;
  margin-right: 5px;
  opacity: 75%;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--3 {
  width: 10%;
  text-align: right;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Fund
  .cell-dropdown-list__content-item--0 {
  width: 65%;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Fund
  .cell-dropdown-list__content-item--1 {
  width: calc(25% - 5px);
  text-align: right;
  margin-right: 5px;
  opacity: 75%;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container
  .cell-dropdown-list__content-item__symbol-classification--Fund
  .cell-dropdown-list__content-item--2 {
  text-align: right;
  width: 10%;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container.cell-dropdown-list__selector__result-container--initial-list-field-config
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--0 {
  width: 10%;
  position: relative;
  top: 1px;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container.cell-dropdown-list__selector__result-container--initial-list-field-config
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--1 {
  width: 80%;
}
.cell-w-chart-nextgen-dropdown-container
  .cell-dropdown-list__selector-wrapper--new-compare
  .cell-dropdown-list__selector__result-container.cell-dropdown-list__selector__result-container--initial-list-field-config
  .cell-dropdown-list__content-item__symbol-classification--Unknown
  .cell-dropdown-list__content-item--2 {
  width: 10%;
  text-align: right;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--currency {
  min-width: 180px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector-wrapper--template {
  min-width: 190px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list-icon .icon-svg {
  stroke-width: 1px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list-icon .icon-image {
  margin-top: 2px;
}
.cell-w-chart-nextgen-dropdown-container .cell-dropdown-list__selector__item--unavailable {
  display: none;
}
.cell-w-chart-nextgen .cell-heading-dropdown--multi-selection {
  border-radius: 0;
  margin: 0;
}
.cell-w-chart-nextgen .cell-heading-dropdown--annotation .cell-heading-dropdown__label {
  top: -2px !important;
}
.cell-w-chart-nextgen .cell-mininav--chart {
  margin-top: unset;
}
.cell-w-chart-nextgen .cell-mininav__btn-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px 0 0 5px;
  margin-right: 10px;
}
.cell-w-chart-nextgen .cell-mininav__btn-icon-calendar {
  padding: 4px 3px 0px 3px;
}
.cell-w-chart-nextgen .cell-mininav__btn-icon-settings {
  padding: 2px 0 0 5px;
}
.cell-w-chart-nextgen .cell-mininav__btn-icon-expand {
  padding: 2px 0 0 0;
  width: 14px;
  height: 14px;
}
.cell-w-chart-nextgen .cell-dropdown-list-label-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.cell-w-chart-nextgen .cell-dropdown-list-label-icon .icon-svg {
  stroke-width: 1px;
}
.cell-w-chart-nextgen .cell-dropdown-list-selector-wrapper--chart-type {
  width: 120px !important;
  min-width: 120px;
}
.cell-w-chart-nextgen .highcharts-axis-resizer {
  stroke-width: 3 !important;
}
.cell-w-chart-nextgen .highcharts-draw-mode {
  cursor: crosshair;
}
.cell-w-chart-nextgen .highcharts-legend-item path,
.cell-w-chart-nextgen .highcharts-legend-item rect {
  display: none;
}
.cell-w-chart-nextgen .highcharts-legend-item .legend-wrapper {
  padding: 1px 2px 1px 2px;
  border-bottom-width: 2px;
  font-weight: bold;
  font-size: 11px;
}
.cell-w-chart-nextgen .highcharts-legend-item .legend-wrapper:hover {
  border-bottom-width: 3px;
}
.cell-w-chart-nextgen .highcharts-legend-item .legend-wrapper--nopointer {
  cursor: default;
}
.cell-w-chart-nextgen .highcharts-legend-item .legend-wrapper--delete span.legend-delete-series:hover {
  color: orangered;
}
.cell-w-chart-nextgen .highcharts-scrollbar {
  visibility: collapse;
}
.cell-w-chart-nextgen .highcharts-navigator-mask-inside {
  cursor: -webkit-grab !important;
  cursor: grab !important;
}
.cell-w-chart-nextgen .highcharts-navigator-xaxis text {
  display: none;
}
.cell-w-chart-nextgen .highcharts-navigator-xaxis path.highcharts-grid-line:not(:first-child, :last-child) {
  visibility: hidden;
}
.cell-w-chart-nextgen .highcharts-navigator-series {
  display: none;
}
.cell-w-chart-nextgen .highcharts-chart-watermark text {
  cursor: default;
  dominant-baseline: text-before-edge;
  font-weight: 900;
  opacity: 0.4;
  text-transform: uppercase;
}
.cell-w-chart-nextgen .highcharts-popup {
  display: none;
  font-size: 0.876em;
  left: 15%;
  max-height: 90%;
  max-width: 600px;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 5%;
  width: 75%;
  z-index: 100;
}
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar {
  right: 10%;
  left: auto;
  height: 32px;
  overflow: hidden;
  padding-right: 26px;
  width: auto;
  min-width: 0;
}
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar div.highcharts-popup-close {
  padding: 5px;
}
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar button {
  margin-top: 0px;
}
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar > span {
  display: block;
  float: left;
  padding: 12px;
}
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar span {
  padding: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 20px;
}
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar button.highcharts-annotation-remove-button,
.cell-w-chart-nextgen .highcharts-popup.highcharts-annotation-toolbar button.highcharts-annotation-edit-button {
  margin: 5px;
  height: 18px;
  cursor: pointer;
}
.cell-w-chart-nextgen .highcharts-popup div,
.cell-w-chart-nextgen .highcharts-popup span {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.cell-w-chart-nextgen .highcharts-popup input,
.cell-w-chart-nextgen .highcharts-popup label,
.cell-w-chart-nextgen .highcharts-popup select {
  clear: both;
  float: left;
  width: 49%;
  margin-bottom: 10px;
}
.cell-w-chart-nextgen .highcharts-popup input {
  padding: 5px;
  width: calc(100% - 12px);
}
.cell-w-chart-nextgen .highcharts-popup button {
  float: right;
  border: none;
  margin-left: 5px;
  margin-top: 12px;
}
.cell-w-chart-nextgen .highcharts-popup button:first-child {
  margin-left: 0;
}
.cell-w-chart-nextgen .highcharts-popup select {
  width: 50%;
  -moz-appearance: menulist;
  -webkit-appearance: menulist;
}
.cell-w-chart-nextgen .highcharts-popup .highcharts-popup-field[type="checkbox"] {
  width: auto;
}
.cell-w-chart-nextgen .highcharts-popup .highcharts-popup-field[type="checkbox"] + label {
  clear: both;
}
.cell-w-chart-nextgen .highcharts-tooltip > div > span {
  padding: 1px 0;
  position: relative;
}
.cell-w-chart-nextgen .highcharts-popup button.highcharts-annotation-edit-button,
.cell-w-chart-nextgen .highcharts-popup button.highcharts-annotation-remove-button {
  width: 18px;
  height: 18px;
  padding: 5px;
}
.cell-w-chart-nextgen .highcharts-popup button.highcharts-annotation-edit-button {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}
.cell-w-chart-nextgen .highcharts-popup button.highcharts-annotation-remove-button {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-indent: -9999px;
}
.cell-w-chart-nextgen .highcharts-popup .highcharts-annotation-title {
  display: block;
  float: left;
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
}
.cell-w-chart-nextgen .highcharts-popup .highcharts-popup-main-title {
  margin: 0px 0px 20px 0px;
  padding: 8px 0px 6px 20px;
}
.cell-w-chart-nextgen .highcharts-popup-lhs-col,
.cell-w-chart-nextgen .highcharts-popup-rhs-col {
  padding: 20px;
  height: calc(100% - 84px);
  /* 44px - padding, 40px - tabs*/
  float: left;
}
.cell-w-chart-nextgen .highcharts-popup-lhs-col.highcharts-popup-lhs-full {
  width: calc(100% - 52px);
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 140px);
  border: none;
  padding: 0px 20px 20px 20px;
}
.cell-w-chart-nextgen .highcharts-popup-lhs-col.highcharts-popup-lhs-full + .highcharts-popup-bottom-row {
  width: calc(100% - 32px);
}
.cell-w-chart-nextgen .highcharts-popup-lhs-col {
  clear: both;
  width: calc(30% - 44px);
}
.cell-w-chart-nextgen .highcharts-popup-bottom-row {
  float: left;
  padding: 0px 20px;
  width: calc(100% - 40px);
}
.cell-w-chart-nextgen .highcharts-popup-rhs-col {
  width: calc(70% - 40px);
}
.cell-w-chart-nextgen .highcharts-popup-rhs-col-wrapper {
  float: left;
  width: calc(100% - 20px);
  padding-right: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;
  overflow-x: hidden;
}
.cell-w-chart-nextgen .highcharts-popup-rhs-col-wrapper h3 {
  margin-top: 0px;
  padding-bottom: 0px;
}
.cell-w-chart-nextgen .highcharts-popup-close {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  padding: 10px;
  top: 0%;
  right: 0%;
}
.cell-w-chart-nextgen .highcharts-bindings-wrapper ul.highcharts-indicator-list,
.cell-w-chart-nextgen .highcharts-indicator-list {
  float: left;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
  padding: 0px;
}
.cell-w-chart-nextgen .highcharts-indicator-list li {
  cursor: pointer;
  padding: 0px 0px 5px 0px;
  margin: 0px;
  width: 100%;
  height: auto;
  overflow: hidden;
  word-break: break-all;
}
.cell-w-chart-nextgen .highcharts-tab-item {
  cursor: pointer;
  display: block;
  float: left;
  padding: 10px;
  height: 20px;
}
.cell-w-chart-nextgen .highcharts-tab-item-content {
  display: none;
  float: left;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.cell-w-chart-nextgen .highcharts-tab-item-show {
  display: block;
}
.cell-w-chart-nextgen .highcharts-indicator-title {
  float: left;
  padding-bottom: 15px;
}
.cell-w-chart-nextgen li:focus,
.cell-w-chart-nextgen button:focus {
  outline: none;
}

/* END general chart node stylings */
.cell-w-esg-scores {
  border-collapse: separate;
}
.cell-w-esg-scores-icon {
  position: absolute;
  margin-right: 22px;
}
.cell-w-esg-scores-icon__td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-w-esg-scores-child:first-child {
  padding-left: 20px !important;
}
.cell-w-esg-scores-parent {
  font-weight: 700;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 0px;
  display: inline-block;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle__td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle__td::before {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: purple;
  margin-right: 0px;
  display: inline-block;
  content: " ";
  position: absolute;
  margin-right: 25px;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle__1::before {
  background-color: red;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle__2::before {
  background-color: orange;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle__3::before {
  background-color: yellow;
}
.cell-w-esg-scores *.cell-w-esg-scores-circle__4::before {
  background-color: green;
}
.cell-w-esg-scores span.cell-table--txt-underline {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  color: white;
  display: inline-block;
}
.cell-w-esg-scores .cell-table td {
  padding: 7px 3px;
}

.cell-w-esg-sector-scores [class*="--scroll"]:not([class*="--scroll-y"]):not([class*="--scroll-x"]) {
  overflow-y: auto;
  overflow-x: auto;
}
.cell-w-esg-sector-scores [class*="--scroll-x"] {
  overflow-x: auto;
}
.cell-w-esg-sector-scores [class*="--scroll-y"] {
  overflow-y: auto;
}
.cell-w-esg-sector-scores .cell-button {
  border-radius: 3px;
  padding: 0 10px;
  text-transform: none;
}
.cell-w-esg-sector-scores .cell-table th,
.cell-w-esg-sector-scores .cell-table td {
  border: none;
  outline: none;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator {
  text-align: center;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span {
  background: #002d40;
  border: none;
  border-radius: 3px;
  display: inline-block;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator {
  white-space: nowrap;
  color: #ffffff;
  font-size: 12px;
  margin: 0 2px;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator:first-child {
  width: 40px;
  margin-left: 0px;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator:last-child {
  width: 40px;
  margin-right: 0px;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator:disabled {
  opacity: 0.4;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator:focus {
  outline: none;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator:hover:not(:disabled) {
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator--selected {
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator span {
  background: transparent;
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator--start span {
  border-radius: 0;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cell-w-esg-sector-scores tfoot tr td.cell-table-paginator span button.cell-button-paginator--end span {
  border-radius: 0;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cell-w-esg-sector-scores table {
  table-layout: fixed;
}
.cell-w-esg-sector-scores table tr th {
  word-break: break-word;
}
.cell-w-esg-sector-scores table tr th:not(:nth-child(1)) {
  width: 17.75%;
}
.cell-w-esg-sector-scores table tr th:nth-child(1) {
  width: 25%;
}
.cell-w-esg-sector-scores-pie {
  height: 18px;
  width: 18px;
  float: left;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-w-esg-sector-scores-pie-label {
  background: none;
  color: white;
}
.cell-w-esg-sector-scores-pie--content {
  height: 100%;
  width: 100%;
  clip: rect(0, 18px, 18px, 9px);
  position: absolute;
  margin-right: 22px;
}
.cell-w-esg-sector-scores-pie--content .half-circle {
  height: 100%;
  width: 100%;
  border: 4.5px solid;
  border-radius: 50%;
  clip: rect(0, 9px, 18px, 0);
  position: absolute;
  color: grey;
}
.cell-w-esg-sector-scores-pie--content .half-circle--color__1 {
  color: red;
}
.cell-w-esg-sector-scores-pie--content .half-circle--color__2 {
  color: orange;
}
.cell-w-esg-sector-scores-pie--content .half-circle--color__3 {
  color: yellow;
}
.cell-w-esg-sector-scores-pie--content .half-circle--color__4 {
  color: green;
}
.cell-w-esg-sector-scores table tr th:not(:nth-child(1)) {
  width: 7.75%;
}
.cell-w-esg-sector-scores .cell-table th {
  font-size: 10px;
}
.cell-w-esg-sector-scores .cell-table__tbody--median {
  font-weight: 700;
}
.cell-w-esg-sector-scores .cell-table__tbody--median * span.cell-w-esg-sector-scores-circle__td {
  display: inline-block;
  width: 10px;
  margin-right: 8px;
}
.cell-w-esg-sector-scores .cell-table__tbody--median tr {
  background-color: #07394d;
}
.cell-w-esg-sector-scores .cell-table__tbody--median tr td {
  border: none;
}
.cell-w-esg-sector-scores .cell-table__tbody--median tr td:first-child {
  border-radius: 3px 0 0 3px;
  cursor: default !important;
}
.cell-w-esg-sector-scores .cell-table__tbody--median tr td:first-child:hover {
  text-decoration: none !important;
}
.cell-w-esg-sector-scores .cell-table__tbody--median tr td:last-child {
  border-radius: 0 3px 3px 0;
}
.cell-w-esg-sector-scores .cell-table__tbody--items * span.cell-w-esg-sector-scores-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: purple;
  margin-right: 0px;
  display: inline-block;
}
.cell-w-esg-sector-scores .cell-table__tbody--items * span.cell-w-esg-sector-scores-circle__td {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: purple;
  margin-right: 0px;
  display: inline-block;
  content: " ";
  margin-right: 8px;
}
.cell-w-esg-sector-scores .cell-table__tbody--items * span.cell-w-esg-sector-scores-circle__1 {
  background-color: red !important;
}
.cell-w-esg-sector-scores .cell-table__tbody--items * span.cell-w-esg-sector-scores-circle__2 {
  background-color: orange !important;
}
.cell-w-esg-sector-scores .cell-table__tbody--items * span.cell-w-esg-sector-scores-circle__3 {
  background-color: yellow !important;
}
.cell-w-esg-sector-scores .cell-table__tbody--items * span.cell-w-esg-sector-scores-circle__4 {
  background-color: green !important;
}
.cell-w-esg-sector-scores .cell-table__tbody tr td:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cell-w-esg-sector-scores .cell-table__tbody tr:hover td:first-child {
  cursor: pointer;
  text-decoration: underline;
}
.cell-w-esg-sector-scores .selected-sector span {
  background-color: #009486;
  padding: 2px 5px;
  border-radius: 4px;
}

.cell-w-esg-icon--color__1 {
  background-color: red !important;
}
.cell-w-esg-icon--color__2 {
  background-color: orange !important;
}
.cell-w-esg-icon--color__3 {
  background-color: yellow !important;
}
.cell-w-esg-icon--color__4 {
  background-color: green !important;
}

.cell-w-esg-outlook {
  height: auto !important;
  width: 100%;
  font-weight: 500;
}
.cell-w-esg-outlook .esg-progress-circle-pie {
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cell-w-esg-outlook .esg-progress-circle-pie-label {
  font-size: 28px;
}
.cell-w-esg-outlook .esg-progress-circle-pie--content {
  height: 100%;
  width: 100%;
  clip: rect(0, 60px, 60px, 30px);
  margin-right: 0 !important;
  position: absolute;
}
.cell-w-esg-outlook .esg-progress-circle-pie--content .half-circle {
  border: 6px solid;
  clip: rect(0, 30px, 60px, 0);
}
.cell-w-esg-outlook-circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: grey;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-esg-outlook-circle--color__1 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-esg-outlook-circle--color__2 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: orange;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-esg-outlook-circle--color__3 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: yellow;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-esg-outlook-circle--color__4 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: green;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-esg-outlook-title {
  padding: 4px 0;
}
.cell-w-esg-outlook-value {
  font-size: 20px;
  padding: 0 5px;
}
.cell-w-esg-outlook--global {
  min-width: 180px;
}
.cell-w-esg-outlook--details {
  min-width: 220px;
}
.cell-w-esg-outlook--warning {
  background-image: url("../infrontUI/images/svg/warning-red.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
}

.cell-w-esg-top-peers [class*="--scroll"]:not([class*="--scroll-y"]):not([class*="--scroll-x"]) {
  overflow-y: auto;
  overflow-x: auto;
}
.cell-w-esg-top-peers [class*="--scroll-x"] {
  overflow-x: auto;
}
.cell-w-esg-top-peers [class*="--scroll-y"] {
  overflow-y: auto;
}
.cell-w-esg-top-peers .cell-button {
  border-radius: 3px;
  padding: 0 10px;
  text-transform: none;
}
.cell-w-esg-top-peers .cell-table th,
.cell-w-esg-top-peers .cell-table td {
  border: none;
  outline: none;
}
.cell-w-esg-top-peers-pie {
  height: 18px;
  width: 18px;
  float: left;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-w-esg-top-peers-pie-label {
  background: none;
  color: white;
}
.cell-w-esg-top-peers-pie--content {
  height: 100%;
  width: 100%;
  clip: rect(0, 18px, 18px, 9px);
  position: absolute;
  margin-right: 22px;
}
.cell-w-esg-top-peers-pie--content .half-circle {
  height: 100%;
  width: 100%;
  border: 4.5px solid;
  border-radius: 50%;
  clip: rect(0, 9px, 18px, 0);
  position: absolute;
  color: grey;
}
.cell-w-esg-top-peers-pie--content .half-circle--color__1 {
  color: red;
}
.cell-w-esg-top-peers-pie--content .half-circle--color__2 {
  color: orange;
}
.cell-w-esg-top-peers-pie--content .half-circle--color__3 {
  color: yellow;
}
.cell-w-esg-top-peers-pie--content .half-circle--color__4 {
  color: green;
}
.cell-w-esg-top-peers .cell-table {
  border-collapse: separate !important;
}
.cell-w-esg-top-peers .cell-table .cell-table__item .cell-w-esg-scores-circle__td::before {
  display: inline-block;
  content: " ";
}
.cell-w-esg-top-peers .cell-table .cell-table__item .cell-w-esg-scores-circle__1::before {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  margin-right: 8px;
  display: inline-block;
}
.cell-w-esg-top-peers .cell-table .cell-table__item .cell-w-esg-scores-circle__2::before {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: orange;
  margin-right: 8px;
  display: inline-block;
}
.cell-w-esg-top-peers .cell-table .cell-table__item .cell-w-esg-scores-circle__3::before {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: yellow;
  margin-right: 8px;
  display: inline-block;
}
.cell-w-esg-top-peers .cell-table .cell-table__item .cell-w-esg-scores-circle__4::before {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: green;
  margin-right: 8px;
  display: inline-block;
}
.cell-w-esg-top-peers .cell-table .cell-tablecell--interaction:hover {
  text-decoration: underline;
}
.cell-w-esg-top-peers .cell-table .cell-table__item.item__main-instrument td {
  background: #00374f;
  font-weight: 600;
}
.cell-w-esg-top-peers .cell-button {
  background-color: #0f9bd9;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin-left: 10px;
}
.cell-w-esg-top-peers .cell-button:hover {
  background-color: #0d7bab;
}

.cell-w-esg-company-sector-spider-chart table.cell-table {
  font-size: 11px;
}
.cell-w-esg-company-sector-spider-chart table.cell-table tr td {
  padding: 3px 3px;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cell-w-esg-company-sector-spider-chart table.cell-table .cell-table__thead tr td {
  text-align: center;
  width: 40px;
}
.cell-w-esg-company-sector-spider-chart table.cell-table .cell-table__thead tr :nth-child(2) {
  width: 70px;
  color: #69bcdf;
}
.cell-w-esg-company-sector-spider-chart table.cell-table .cell-table__thead tr :nth-child(3) {
  width: 70px;
  color: #ffee58;
}
.cell-w-esg-company-sector-spider-chart table.cell-table .cell-table__tbody > :nth-child(1) {
  color: rgba(105, 188, 223, 0.75) !important;
}
.cell-w-esg-company-sector-spider-chart table.cell-table .cell-table__tbody tr td {
  text-align: center;
  white-space: nowrap;
}
.cell-w-esg-company-sector-spider-chart table.cell-table .cell-table__tbody tr :nth-child(1) {
  text-align: left;
}
.cell-w-esg-company-sector-spider-chart--warning {
  display: block;
  content: "⚠";
  font-size: 20px;
  color: red;
}
.cell-w-esg-company-sector-spider-chart .cell-legend {
  width: 190px;
  background-color: #001721;
  border: 1px solid #07394d;
  padding: 5px;
  border-radius: 3px;
}
.cell-w-esg-company-sector-spider-chart .cell-chart {
  margin: 0px;
}
.cell-w-esg-company-sector-spider-chart .cell-esg-score-bar {
  visibility: collapse;
}
@media (min-width: 600px) {
  .cell-w-esg-company-sector-spider-chart .cell-chart {
    margin-top: 40px;
    margin-right: 0px;
  }
  .cell-w-esg-company-sector-spider-chart .cell-chart-wrapper {
    position: relative;
  }
  .cell-w-esg-company-sector-spider-chart .cell-esg-score-bar {
    visibility: visible;
    position: absolute;
    right: 0;
  }
  .cell-w-esg-company-sector-spider-chart table.cell-table {
    table-layout: fixed;
    width: 180px;
  }
}

.cell-spider-d3-tooltip {
  position: absolute;
  padding: 10px;
  background-color: #002536;
  border: 1px solid #006c8c;
  border-radius: 3px;
  display: none;
}

.cell-w-esg-performers {
  min-width: 330px;
  max-width: 420px;
}
.cell-w-esg-performers__background {
  background-color: #002d40 !important;
}
.cell-w-esg-performers__global-value {
  font-size: 20px;
  font-weight: bold;
}
.cell-w-esg-performers__global-score--name {
  position: absolute;
  top: -10px;
}
.cell-w-esg-performers__cell-table td {
  padding: 4px 3px !important;
  text-align: right;
  font-size: 12px;
}
.cell-w-esg-performers__average--positive {
  color: #3ce565;
}
.cell-w-esg-performers__average--positive::before {
  content: "+";
}
.cell-w-esg-performers__average--negative {
  color: #ff5a59;
}
.cell-w-esg-performers__status {
  padding: 3px 9px;
  border-radius: 3px;
}
.cell-w-esg-performers__status--top {
  background-color: #3ce565;
}
.cell-w-esg-performers__status--out {
  background-color: #ff5a59;
}
.cell-w-esg-performers__profile-button {
  text-transform: none;
}
.cell-w-esg-performers__scores-title {
  color: grey;
}
.cell-w-esg-performers .esg-progress-circle-pie {
  height: 45px;
  width: 45px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px 10px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cell-w-esg-performers .esg-progress-circle-pie-label {
  font-size: 22px;
}
.cell-w-esg-performers .esg-progress-circle-pie--content {
  height: 100%;
  width: 100%;
  clip: rect(0, 45px, 45px, 22.5px);
  margin-right: 0 !important;
  position: absolute;
}
.cell-w-esg-performers .esg-progress-circle-pie--content .half-circle {
  border: 4.5px solid;
  clip: rect(0, 22.5px, 45px, 0);
}

.cell-w-esg-sector-composition [class*="--scroll"]:not([class*="--scroll-y"]):not([class*="--scroll-x"]) {
  overflow-y: auto;
  overflow-x: auto;
}
.cell-w-esg-sector-composition [class*="--scroll-x"] {
  overflow-x: auto;
}
.cell-w-esg-sector-composition [class*="--scroll-y"] {
  overflow-y: auto;
}
.cell-w-esg-sector-composition .cell-button {
  border-radius: 3px;
  padding: 0 10px;
  text-transform: none;
}
.cell-w-esg-sector-composition .cell-table th,
.cell-w-esg-sector-composition .cell-table td {
  border: none;
  outline: none;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator {
  text-align: center;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span {
  background: #002d40;
  border: none;
  border-radius: 3px;
  display: inline-block;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator {
  white-space: nowrap;
  color: #ffffff;
  font-size: 12px;
  margin: 0 2px;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator:first-child {
  width: 40px;
  margin-left: 0px;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator:last-child {
  width: 40px;
  margin-right: 0px;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator:disabled {
  opacity: 0.4;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator:focus {
  outline: none;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator:hover:not(:disabled) {
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator--selected {
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator span {
  background: transparent;
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator--start span {
  border-radius: 0;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.cell-w-esg-sector-composition tfoot tr td.cell-table-paginator span button.cell-button-paginator--end span {
  border-radius: 0;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cell-w-esg-sector-composition table {
  table-layout: fixed;
}
.cell-w-esg-sector-composition table tr th {
  word-break: break-word;
}
.cell-w-esg-sector-composition table tr th:not(:nth-child(1)) {
  width: 17.75%;
}
.cell-w-esg-sector-composition table tr th:nth-child(1) {
  width: 25%;
}
.cell-w-esg-sector-composition-pie {
  height: 18px;
  width: 18px;
  float: left;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-w-esg-sector-composition-pie-label {
  background: none;
  color: white;
}
.cell-w-esg-sector-composition-pie--content {
  height: 100%;
  width: 100%;
  clip: rect(0, 18px, 18px, 9px);
  position: absolute;
  margin-right: 22px;
}
.cell-w-esg-sector-composition-pie--content .half-circle {
  height: 100%;
  width: 100%;
  border: 4.5px solid;
  border-radius: 50%;
  clip: rect(0, 9px, 18px, 0);
  position: absolute;
  color: grey;
}
.cell-w-esg-sector-composition-pie--content .half-circle--color__1 {
  color: red;
}
.cell-w-esg-sector-composition-pie--content .half-circle--color__2 {
  color: orange;
}
.cell-w-esg-sector-composition-pie--content .half-circle--color__3 {
  color: yellow;
}
.cell-w-esg-sector-composition-pie--content .half-circle--color__4 {
  color: green;
}
.cell-w-esg-sector-composition table tr th:not(:nth-child(1)) {
  width: 7.75%;
}
.cell-w-esg-sector-composition .cell-table th {
  font-size: 10px;
}
.cell-w-esg-sector-composition .cell-table__tbody--median {
  font-weight: 700;
}
.cell-w-esg-sector-composition .cell-table__tbody--median * span.cell-w-esg-sector-composition-circle__td {
  display: inline-block;
  width: 10px;
  margin-right: 8px;
}
.cell-w-esg-sector-composition .cell-table__tbody--median tr {
  background-color: #07394d;
}
.cell-w-esg-sector-composition .cell-table__tbody--median tr td {
  border: none;
}
.cell-w-esg-sector-composition .cell-table__tbody--median tr td:first-child {
  border-radius: 3px 0 0 3px;
  cursor: default !important;
}
.cell-w-esg-sector-composition .cell-table__tbody--median tr td:first-child:hover {
  text-decoration: none !important;
}
.cell-w-esg-sector-composition .cell-table__tbody--median tr td:last-child {
  border-radius: 0 3px 3px 0;
}
.cell-w-esg-sector-composition .cell-table__tbody--items * span.cell-w-esg-sector-composition-circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: purple;
  margin-right: 0px;
  display: inline-block;
}
.cell-w-esg-sector-composition .cell-table__tbody--items * span.cell-w-esg-sector-composition-circle__td {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: purple;
  margin-right: 0px;
  display: inline-block;
  content: " ";
  margin-right: 8px;
}
.cell-w-esg-sector-composition .cell-table__tbody--items * span.cell-w-esg-sector-composition-circle__1 {
  background-color: red !important;
}
.cell-w-esg-sector-composition .cell-table__tbody--items * span.cell-w-esg-sector-composition-circle__2 {
  background-color: orange !important;
}
.cell-w-esg-sector-composition .cell-table__tbody--items * span.cell-w-esg-sector-composition-circle__3 {
  background-color: yellow !important;
}
.cell-w-esg-sector-composition .cell-table__tbody--items * span.cell-w-esg-sector-composition-circle__4 {
  background-color: green !important;
}
.cell-w-esg-sector-composition .cell-table__tbody tr td:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cell-w-esg-sector-composition .cell-table__tbody tr td:first-child:hover {
  text-decoration: underline;
}

.cell-w-esg-rdistribution {
  height: auto !important;
  width: 100%;
  font-weight: 500;
}
.cell-w-esg-rdistribution .cell-chart-wrapper {
  height: auto;
}
.cell-w-esg-rdistribution .highcharts-grid-line {
  fill: none;
  stroke: #e6e6e6;
  display: none;
}
.cell-w-esg-rdistribution .highcharts-data-table table {
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
}
.cell-w-esg-rdistribution .highcharts-plot-line {
  z-index: 20;
  fill: none;
  stroke: #3fcbfe;
  stroke-width: 2px;
}
@media (min-width: 600px) {
  .cell-w-esg-rdistribution .cell-chart-wrapper {
    position: relative;
  }
  .cell-w-esg-rdistribution table.cell-table {
    table-layout: fixed;
    width: 210px;
  }
}
.cell-w-esg-rdistribution .highcharts-tooltip-box {
  fill: "";
  stroke: "";
}
.cell-w-esg-rdistribution .highcharts-tooltip text {
  fill: "";
}
.cell-w-esg-rdistribution
  g.highcharts-series.highcharts-series-0.highcharts-column-series.highcharts-tracker.highcharts-series-inactive {
  opacity: 1;
}

.cell-w-estimates-detail-table__tooltip {
  padding: 0 !important;
}
.cell-w-estimates-detail-table__tooltip::after {
  border-color: #006c8c transparent !important;
}
.cell-w-estimates-detail-table tr:not(:last-of-type) td {
  border-bottom: none !important;
}
.cell-w-estimates-detail-table tr td:first-child {
  padding-left: 20px;
}

.cell-table__item--expand {
  border-left: 1px solid #07394d;
  border-right: 1px solid #07394d;
  border-bottom: 1px dashed #07394d;
}
.cell-table__item--expand__level-1 td:first-child {
  padding-left: 15px !important;
}
.cell-table__item--expand__level-2 td:first-child {
  padding-left: 30px !important;
}
.cell-table__item--expand__level-3 td:first-child {
  padding-left: 45px !important;
}
.cell-table__item--master {
  cursor: pointer;
}
.cell-table__item--master td:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-table__item--master td:first-child:before {
  content: " ";
  border-bottom-color: #af9797;
  border-bottom-width: 5px;
  border-left-color: transparent;
  border-left-width: 5px;
  border-right-color: transparent;
  border-right-width: 5px;
  border-style: solid;
  border-top: 0;
  display: inline-block;
  height: 0;
  margin: 0 4px;
  vertical-align: middle;
  width: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  left: 13px;
}
.cell-table__item--expanded {
  border: 1px solid #07394d;
  border-bottom: 2px solid #07394d;
  cursor: pointer;
}
.cell-table__item--expanded td:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.cell-table__item--expanded td:first-child:before {
  content: " ";
  border-bottom-color: #af9797;
  border-bottom-width: 5px;
  border-left-color: transparent;
  border-left-width: 5px;
  border-right-color: transparent;
  border-right-width: 5px;
  border-style: solid;
  border-top: 0;
  display: inline-block;
  height: 0;
  margin: 0 4px;
  vertical-align: middle;
  width: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  position: absolute;
  left: 13px;
}

.cell-estimatesTable-tooltip {
  min-width: 120px;
  font-size: 11px !important;
}
.cell-estimatesTable-tooltip .cell-tooltip__arrow--top-left {
  background-color: #006c8c !important;
}
.cell-estimatesTable-tooltip .cell-title {
  text-align: center;
  padding: 4px 8px;
}
.cell-estimatesTable-tooltip .cell-p {
  margin: 8px 5px;
  font-size: 11px;
}
.cell-estimatesTable-tooltip .cell-p th {
  padding-left: 8px;
  text-align: left;
}
.cell-estimatesTable-tooltip .cell-p td.cell-data-inquiry-tooltip {
  text-align: right;
  padding-left: 8px;
}
.cell-estimatesTable-tooltip .cell-p td.cell-data-inquiry-tooltip .cell-value {
  padding-right: 5px;
}
.cell-estimatesTable-tooltip .cell-p td.cell-data-inquiry-tooltip .cell-unit {
  font-size: 8px;
}
.cell-estimatesTable-tooltip .cell-p td.cell-data-inquiry-tooltip .cell-currency {
  font-size: 8px;
}

.cell-w-lookup .cell-heading__search {
  background-color: #001721;
  height: 15px;
}
.cell-w-lookup .cell-heading__search-field {
  background-color: #001721;
}
.cell-w-lookup .cell-csearch-input {
  background-color: #000000;
  height: 25px;
  width: 60%;
}

.cell-w-documents-widget {
  border: 1px solid #ffffff;
}
.cell-w-documents-widget--input {
  display: block;
  width: 100%;
}
.cell-w-documents-widget-list {
  padding-top: 9px;
}
.cell-w-documents-widget-row {
  background-color: #555555;
  border: 1px solid #333333;
  border-width: 0 0 1px 0;
  float: left;
  height: 48px;
  padding: 9px 0;
  position: relative;
  width: 100%;
}
.cell-w-documents-widget-row-sub {
  opacity: 0.5;
}
.cell-w-documents-widget-row__empty {
  padding: 9px;
  text-align: center;
}
.cell-w-documents-widget-row__group {
  background-color: #555555;
  border: 1px solid #333333;
  border-width: 0 0 1px 0;
  margin-bottom: 3px;
  margin-top: 10px;
  padding-bottom: 10px;
}
.cell-w-documents-widget-row__group::after {
  content: "";
  height: 4px;
  position: absolute;
  right: 6px;
  width: 4px;
}
.cell-w-documents-widget-row__group-title {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
}
.cell-w-documents-widget-row__group > .cell-w-documents-widget-row {
  padding-left: 10px;
}
.cell-w-documents-widget-row__group-collapsed > .cell-w-documents-widget-row {
  display: none;
}
.cell-w-documents-widget-row__group-collapsed > .cell-w-documents-widget-row__group-title {
  padding-bottom: 0;
}
.cell-w-documents-widget__type {
  float: left;
  font-size: 14px;
  font-weight: bold;
}
.cell-w-documents-widget__language {
  float: left;
  font-size: 14px;
  opacity: 0.5;
  padding-left: 6px;
  text-transform: uppercase;
}
.cell-w-documents-widget__language::before {
  content: " (";
}
.cell-w-documents-widget__language::after {
  content: ")";
}
.cell-w-documents-widget__date {
  float: right;
  font-size: 14px;
  font-weight: bold;
}
.cell-w-documents-widget__country {
  float: left;
  padding-right: 4px;
}
.cell-w-documents-widget__size {
  float: right;
  text-align: right;
}

.cell-w-consensus-reco__intro {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px 0;
}
.cell-w-consensus-reco__intro-mean {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.cell-w-consensus-reco__intro-analysts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-right: 65px;
}
.cell-w-consensus-reco__progress-table {
  margin: 10px 0;
}
.cell-w-consensus-reco__progress-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 25px;
  margin: 5px 0;
}
.cell-w-consensus-reco__progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.cell-w-consensus-reco__progress-cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: grey;
  height: 18px;
}
.cell-w-consensus-reco__progress-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  margin-right: 5px;
}
.cell-w-consensus-reco__progress-mean {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 85px;
  margin-left: 5px;
}
.cell-w-consensus-reco__legend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.cell-w-consensus-reco__legend-square {
  margin-right: 3px;
  width: 12px;
  height: 12px;
}
.cell-w-consensus-reco__legend-cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: 3px;
  font-size: 11px;
}
.cell-w-consensus-reco__color--1 {
  background-color: #1cb841;
}
.cell-w-consensus-reco__color--2 {
  background-color: #4dc46d;
}
.cell-w-consensus-reco__color--3 {
  background-color: #777777;
}
.cell-w-consensus-reco__color--4 {
  background-color: #ff8340;
}
.cell-w-consensus-reco__color--5 {
  background-color: #f2564d;
}
.cell-w-consensus-reco__color-text--1 {
  color: #1cb841;
}
.cell-w-consensus-reco__color-text--2 {
  color: #4dc46d;
}
.cell-w-consensus-reco__color-text--3 {
  color: #777777;
}
.cell-w-consensus-reco__color-text--4 {
  color: #ff8340;
}
.cell-w-consensus-reco__color-text--5 {
  color: #f2564d;
}

.cell-w-multiples-chart .highcharts-yaxis-grid .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #0b6a93;
}
.cell-w-multiples-chart-color--0 {
  stroke: #00a4d4 !important;
}
.cell-w-multiples-chart-color--1 {
  stroke: #fdd835 !important;
}
.cell-w-multiples-chart-color--2 {
  stroke: #f2564d !important;
}
.cell-w-multiples-chart .highcharts-area {
  fill: linear-gradient(#00a4d4, #fdd835);
}
.cell-w-multiples-chart .highcharts-axis-labels text {
  fill: white !important;
}

.cell-w-gprv {
  width: auto;
}
.cell-w-gprv__title {
  position: absolute;
}
.cell-w-gprv__header {
  height: 35px;
  padding: 0 10px;
  border-bottom: 1px solid #07394d;
}
.cell-w-gprv-d3-tooltip {
  position: absolute;
  padding: 10px;
  background-color: #002536;
  border: 1px solid #006c8c;
  border-radius: 3px;
  display: none;
}
.cell-w-gprv-d3-tooltip__template {
  position: absolute;
  top: 2px;
  right: 2px;
  color: #00a5d6;
  font-size: 20px;
  font-weight: 600;
  opacity: 0.3;
}
.cell-w-gprv-d3-tooltip__serie {
  padding-top: 3px;
}
.cell-w-gprv__table--serie {
  padding: 10px;
  border: 1px solid #07394d;
  margin-bottom: 10px;
}
.cell-w-gprv__table--serie__title {
  color: #69bcdf;
  font-size: 14px;
}
.cell-w-gprv__table--serie__arrow {
  background-image: url("../infrontUI/images/svg/expand_more_white_24dp.svg");
  width: 24px;
  background-position: center;
}
.cell-w-gprv__table--serie__arrow--up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cell-w-gprv__table--serie__content {
  width: 100%;
  margin: 3px 0 0 3px;
}
.cell-w-gprv__table--serie__categorie {
  padding-left: 10px !important;
  padding-right: 15px !important;
  text-align: left !important;
}
.cell-w-gprv__table--serie__categorie--global {
  padding-left: 0 !important;
}
.cell-w-gprv__table--serie tr:nth-child(1) {
  font-weight: 700;
}
.cell-w-gprv__table--serie__td {
  padding: 4px;
  text-align: right;
}
.cell-w-gprv--color__1 {
  color: #fdd835;
  fill: #fdd835 !important;
}
.cell-w-gprv--color__2 {
  color: #00a4d4;
  fill: #00a4d4 !important;
}
.cell-w-gprv--color__3 {
  color: #f2564d;
  fill: #f2564d !important;
}
.cell-w-gprv--color-stroke__1 {
  stroke: #fdd835;
}
.cell-w-gprv--color-stroke__2 {
  stroke: #00a4d4;
}
.cell-w-gprv--color-stroke__3 {
  stroke: #f2564d;
}

.cell-w-chart-estimates-details .highcharts-breadcrumbs-group {
  -webkit-transform: translateX(55px) !important;
  transform: translateX(55px) !important;
}
.cell-w-chart-estimates-details .highcharts-point {
  fill: #006c8c !important;
}
.cell-w-chart-estimates-details span {
  color: #ffffff !important;
}
.cell-w-chart-estimates-details .dot-range {
  color: #ffffff !important;
}
.cell-w-chart-estimates-details .dot-column {
  color: #3ca9c9 !important;
}
.cell-w-chart-estimates-details .highcharts-boxplot-whisker {
  stroke: #ffffff;
}
.cell-w-chart-estimates-details .highcharts-boxplot-stem {
  stroke: #ffffff;
}
.cell-w-chart-estimates-details .highcharts-grid-line {
  stroke: #002536 !important;
}
.cell-w-chart-estimates-details .highcharts-axis-line {
  stroke: #002536 !important;
}
.cell-w-chart-estimates-details .highcharts-drilldown-axis-label {
  text-decoration: none !important;
  fill: #666666 !important;
}
.cell-w-chart-estimates-details .highcharts-button {
  fill: #006c8c;
}
.cell-w-chart-estimates-details .highcharts-button-hover {
  fill: #0387be;
}
.cell-w-chart-estimates-details .highcharts-button text {
  fill: #ffffff !important;
}
.cell-w-chart-estimates-details .highcharts-button-box {
  height: 24px !important;
  stroke: #005c7a !important;
}
.cell-w-chart-estimates-details .cell-w-chart-estimates__header {
  color: #999999 !important;
  font-size: 10px;
}

.cell-w-revisions-chart .chart-dropdown--field .cell-mininav__btn {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
}
.cell-w-revisions-chart .highcharts-yaxis-grid .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #154d65;
  opacity: 0.4;
}
.cell-w-revisions-chart .highcharts-xaxis .highcharts-axis-line {
  stroke: #154d65;
  opacity: 0.9;
}
.cell-w-revisions-chart .highcharts-color-0 {
  stroke: none;
}
.cell-w-revisions-chart .cell-modal {
  margin-top: 10px;
}
.cell-w-revisions-chart .cell-modal .cell-custom-dropdown-selector-wrapper {
  width: auto;
  min-width: 100px;
}
.cell-w-revisions-chart .cell-modal .cell-custom-dropdown-selector__item__label:after {
  display: none;
}

.cell-w-historical-reco.cell-chart-wrapper .highcharts-yaxis-grid .highcharts-grid-line {
  display: none;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-1 {
  fill: #ffb319 !important;
  stroke: none;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-1-tooltip__point {
  color: #ffb319;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-1 .highcharts-graph {
  stroke: #ffb319;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-2 {
  fill: #43b2e2 !important;
  stroke: none;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-2-tooltip__point {
  color: #43b2e2;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-2 .highcharts-point {
  stroke: none;
  fill: #43b2e2;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-3 {
  fill: #805e9d !important;
  stroke: none;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-3-tooltip__point {
  color: #805e9d;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-3 .highcharts-graph {
  stroke: none;
  stroke: #805e9d;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-4 {
  fill: #1cb841;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-4 .highcharts-point {
  fill: #1cb841;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-5 {
  fill: #4dc46d;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-6 {
  fill: #777777;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-7 {
  fill: #ff8340;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-color-8 {
  fill: #f2564d;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-areasplinerange-series .highcharts-area {
  fill: #43b2e2 !important;
  opacity: 0.4;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-areasplinerange-series .highcharts-graph {
  stroke: #43b2e2;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-label .highcharts-text-outline {
  stroke-width: 0;
  fill: white;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-series-inactive {
  opacity: 1 !important;
}
.cell-w-historical-reco.cell-chart-wrapper .highcharts-tooltip__historical-reco {
  color: white;
}

.cell-tooltip {
  position: fixed;
}

.cell-w-estimates-outlook {
  height: auto !important;
  width: 100%;
  font-weight: 500;
}
.cell-w-estimates-outlook-title {
  cursor: default;
  margin-bottom: 15px;
}
.cell-w-estimates-outlook-value {
  font-size: 22px;
}
.cell-w-estimates-outlook-value--info {
  color: #2e7d32;
}
.cell-w-estimates-outlook-value--success {
  color: #1cb841;
}
.cell-w-estimates-outlook-value--danger {
  color: #cc0000;
}
.cell-w-estimates-outlook-value--uncolor {
  color: #9f9f9f;
}
.cell-w-estimates-outlook-value--default {
  color: #999999;
}
.cell-w-estimates-outlook-value--warning {
  color: #ff8340;
}
.cell-w-estimates-outlook-col {
  border-left: 1px solid #fff;
  border-color: #07394d;
  padding-left: 12px;
}
.cell-w-estimates-outlook-col:first-child {
  border-left: none;
}
.cell-w-estimates-outlook--details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cell-w-esg-icon--color__1 {
  background-color: red !important;
}
.cell-w-esg-icon--color__2 {
  background-color: orange !important;
}
.cell-w-esg-icon--color__3 {
  background-color: yellow !important;
}
.cell-w-esg-icon--color__4 {
  background-color: green !important;
}

.esg-progress-circle-pie {
  margin: 10px 0 0 0;
}

.cell-w-svelte-esg-top-score {
  min-width: 330px;
}
.cell-w-svelte-esg-top-score_label {
  font-size: 16px;
  white-space: nowrap;
}
.cell-w-svelte-esg-top-score_value {
  margin: 19px 0 0 0;
  font-size: 20px;
}
.cell-w-svelte-esg-top-score_icon {
  margin: -2px 3px 0 0;
}
.cell-w-svelte-esg-top-score_icon-gov {
  margin: -1px 3px 0 0;
}
.cell-w-svelte-esg-top-score .esg-progress-circle {
  margin: 5px 0 0 0;
}
.cell-w-svelte-esg-top-score .esg-progress-circle-pie {
  height: 40px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.cell-w-svelte-esg-top-score .esg-progress-circle-pie-label {
  font-size: 18px;
  margin: 3px 0 0 0;
}
.cell-w-svelte-esg-top-score .esg-progress-circle-pie--content {
  height: 100%;
  width: 100%;
  clip: rect(0, 40px, 40px, 20px);
  margin-right: 0 !important;
  position: absolute;
}
.cell-w-svelte-esg-top-score .esg-progress-circle-pie--content .half-circle {
  border: 4px solid;
  clip: rect(0, 20px, 40px, 0);
}
.cell-w-svelte-esg-top-score-circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: grey;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-svelte-esg-top-score-circle--color__1 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-svelte-esg-top-score-circle--color__2 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: orange;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-svelte-esg-top-score-circle--color__3 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: yellow;
  margin-right: 5px;
  display: inline-block;
}
.cell-w-svelte-esg-top-score-circle--color__4 {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: green;
  margin-right: 5px;
  display: inline-block;
}

.cell-w-svelte-esg-table-view {
  font-size: 14px;
}
.cell-w-svelte-esg-table-view-header {
  height: 24px;
}
.cell-w-svelte-esg-table-view__row {
  border-top: 1px solid;
  height: 34px;
  padding: 11px 0 0 0;
}
.cell-w-svelte-esg-table-view__icon {
  margin: -5px 0 0 0;
}
.cell-w-svelte-esg-table-view__icon-gov {
  margin: -4px 0 0 0;
}
.cell-w-svelte-esg-table-view-relevance {
  padding: 0 10px 0 0;
}
.cell-w-svelte-esg-table-view-data-columns {
  max-width: 220px;
}
.cell-w-svelte-esg-table-view__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cell-w-svelte-esg-table-view__label-total {
  font-weight: bold;
}
.cell-w-svelte-esg-table-view__label-default {
  margin: 0 0 0 15px;
}

.cell-arrow {
  border-bottom-width: 5px;
  border-left-color: transparent !important;
  border-left-width: 4px;
  border-right-color: transparent !important;
  border-right-width: 4px;
  border-style: solid;
  border-top: 0;
  height: 0;
  margin-left: 5px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  width: 0;
}
.cell-arrow--container {
  cursor: pointer;
  width: 30px;
  height: 15px;
  position: absolute;
  top: -2px;
}
.cell-arrow--container--left {
  left: 0;
}
.cell-arrow--container--right {
  right: 0;
}
.cell-arrow--right {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  float: right !important;
}
.cell-arrow--left {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.cell-arrow--up {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.cell-arrow--down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.cell-arrow--size-m {
  border-bottom-width: 12px;
  border-width: 8px;
}
.cell-arrow--size-l {
  border-bottom-width: 15px;
  border-width: 10px;
}
.cell-arrow--size-xl {
  border-bottom-width: 22.5px;
  border-width: 15px;
}

.cell-clear {
  clear: both;
}
.cell-clear::before,
.cell-clear::after {
  content: "";
  display: table;
}
.cell-clear::after {
  clear: both;
}
.cell-noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (max-width: 39.99875em) {
  .cell-fixed {
    position: fixed;
  }
}

.cell-flag {
  background: url("../img/flags.png?version=20190306") no-repeat;
  background-position: -208px -224px;
  float: left;
  height: 11px;
  margin-right: 5px;
  width: 14px;
}
.cell-flag.cell-flag-ad,
.cell-flag.cell-flag-and {
  background-position: -16px 0;
}
.cell-flag.cell-flag-ae,
.cell-flag.cell-flag-are {
  background-position: -32px 0;
}
.cell-flag.cell-flag-af,
.cell-flag.cell-flag-afg {
  background-position: -48px 0;
}
.cell-flag.cell-flag-ag,
.cell-flag.cell-flag-atg {
  background-position: -64px 0;
}
.cell-flag.cell-flag-ai,
.cell-flag.cell-flag-aia {
  background-position: -80px 0;
}
.cell-flag.cell-flag-al,
.cell-flag.cell-flag-alb {
  background-position: -96px 0;
}
.cell-flag.cell-flag-am,
.cell-flag.cell-flag-arm {
  background-position: -112px 0;
}
.cell-flag.cell-flag-an,
.cell-flag.cell-flag-ant {
  background-position: -128px 0;
}
.cell-flag.cell-flag-ao,
.cell-flag.cell-flag-ago {
  background-position: -144px 0;
}
.cell-flag.cell-flag-ar,
.cell-flag.cell-flag-arg {
  background-position: -160px 0;
}
.cell-flag.cell-flag-as,
.cell-flag.cell-flag-asm {
  background-position: -176px 0;
}
.cell-flag.cell-flag-at,
.cell-flag.cell-flag-aut {
  background-position: -192px 0;
}
.cell-flag.cell-flag-au,
.cell-flag.cell-flag-aus {
  background-position: -208px 0;
}
.cell-flag.cell-flag-aw,
.cell-flag.cell-flag-abw {
  background-position: -224px 0;
}
.cell-flag.cell-flag-az,
.cell-flag.cell-flag-aze {
  background-position: 0 -16px;
}
.cell-flag.cell-flag-ba,
.cell-flag.cell-flag-bih {
  background-position: -16px -16px;
}
.cell-flag.cell-flag-bb,
.cell-flag.cell-flag-brb {
  background-position: -32px -16px;
}
.cell-flag.cell-flag-bd,
.cell-flag.cell-flag-bgd {
  background-position: -48px -16px;
}
.cell-flag.cell-flag-be,
.cell-flag.cell-flag-bel {
  background-position: -64px -16px;
}
.cell-flag.cell-flag-bf,
.cell-flag.cell-flag-bfa {
  background-position: -80px -16px;
}
.cell-flag.cell-flag-bg,
.cell-flag.cell-flag-bgr {
  background-position: -96px -16px;
}
.cell-flag.cell-flag-bh,
.cell-flag.cell-flag-bhr {
  background-position: -112px -16px;
}
.cell-flag.cell-flag-bi,
.cell-flag.cell-flag-bdi {
  background-position: -128px -16px;
}
.cell-flag.cell-flag-bj,
.cell-flag.cell-flag-ben {
  background-position: -144px -16px;
}
.cell-flag.cell-flag-bm,
.cell-flag.cell-flag-bmu {
  background-position: -160px -16px;
}
.cell-flag.cell-flag-bn,
.cell-flag.cell-flag-brn {
  background-position: -176px -16px;
}
.cell-flag.cell-flag-bo,
.cell-flag.cell-flag-bol {
  background-position: -192px -16px;
}
.cell-flag.cell-flag-br,
.cell-flag.cell-flag-bra {
  background-position: -208px -16px;
}
.cell-flag.cell-flag-bs,
.cell-flag.cell-flag-bhs {
  background-position: -224px -16px;
}
.cell-flag.cell-flag-bt,
.cell-flag.cell-flag-btn {
  background-position: 0 -32px;
}
.cell-flag.cell-flag-bw,
.cell-flag.cell-flag-bwa {
  background-position: -16px -32px;
}
.cell-flag.cell-flag-by,
.cell-flag.cell-flag-blr {
  background-position: -32px -32px;
}
.cell-flag.cell-flag-bz,
.cell-flag.cell-flag-blz {
  background-position: -48px -32px;
}
.cell-flag.cell-flag-ca,
.cell-flag.cell-flag-can {
  background-position: -64px -32px;
}
.cell-flag.cell-flag-cd,
.cell-flag.cell-flag-cod {
  background-position: -80px -32px;
}
.cell-flag.cell-flag-cf,
.cell-flag.cell-flag-caf {
  background-position: -96px -32px;
}
.cell-flag.cell-flag-cg,
.cell-flag.cell-flag-cog {
  background-position: -112px -32px;
}
.cell-flag.cell-flag-ch,
.cell-flag.cell-flag-che {
  background-position: -128px -32px;
}
.cell-flag.cell-flag-ci,
.cell-flag.cell-flag-civ {
  background-position: -144px -32px;
}
.cell-flag.cell-flag-ck,
.cell-flag.cell-flag-cok {
  background-position: -160px -32px;
}
.cell-flag.cell-flag-cl,
.cell-flag.cell-flag-chl {
  background-position: -176px -32px;
}
.cell-flag.cell-flag-cm,
.cell-flag.cell-flag-cmr {
  background-position: -192px -32px;
}
.cell-flag.cell-flag-cn,
.cell-flag.cell-flag-chn {
  background-position: -208px -32px;
}
.cell-flag.cell-flag-co,
.cell-flag.cell-flag-col {
  background-position: -224px -32px;
}
.cell-flag.cell-flag-cr,
.cell-flag.cell-flag-cri {
  background-position: 0 -48px;
}
.cell-flag.cell-flag-cu,
.cell-flag.cell-flag-cub {
  background-position: -16px -48px;
}
.cell-flag.cell-flag-cv,
.cell-flag.cell-flag-cpv {
  background-position: -32px -48px;
}
.cell-flag.cell-flag-cy,
.cell-flag.cell-flag-cyp {
  background-position: -48px -48px;
}
.cell-flag.cell-flag-cz,
.cell-flag.cell-flag-cze {
  background-position: -64px -48px;
}
.cell-flag.cell-flag-de,
.cell-flag.cell-flag-deu {
  background-position: -80px -48px;
}
.cell-flag.cell-flag-dj,
.cell-flag.cell-flag-dji {
  background-position: -96px -48px;
}
.cell-flag.cell-flag-dk,
.cell-flag.cell-flag-dnk {
  background-position: -112px -48px;
}
.cell-flag.cell-flag-dm,
.cell-flag.cell-flag-dma {
  background-position: -128px -48px;
}
.cell-flag.cell-flag-do,
.cell-flag.cell-flag-dom {
  background-position: -144px -48px;
}
.cell-flag.cell-flag-dz,
.cell-flag.cell-flag-dza {
  background-position: -160px -48px;
}
.cell-flag.cell-flag-ec,
.cell-flag.cell-flag-ecu {
  background-position: -176px -48px;
}
.cell-flag.cell-flag-ee,
.cell-flag.cell-flag-est {
  background-position: -192px -48px;
}
.cell-flag.cell-flag-eg,
.cell-flag.cell-flag-egy {
  background-position: -208px -48px;
}
.cell-flag.cell-flag-eh,
.cell-flag.cell-flag-esh {
  background-position: -224px -48px;
}
.cell-flag.cell-flag-er,
.cell-flag.cell-flag-eri {
  background-position: 0 -64px;
}
.cell-flag.cell-flag-es,
.cell-flag.cell-flag-esp {
  background-position: -16px -64px;
}
.cell-flag.cell-flag-et,
.cell-flag.cell-flag-eth {
  background-position: -32px -64px;
}
.cell-flag.cell-flag-fi,
.cell-flag.cell-flag-fin {
  background-position: -48px -64px;
}
.cell-flag.cell-flag-fj,
.cell-flag.cell-flag-fji {
  background-position: -64px -64px;
}
.cell-flag.cell-flag-fm,
.cell-flag.cell-flag-fsm {
  background-position: -80px -64px;
}
.cell-flag.cell-flag-fo,
.cell-flag.cell-flag-fro {
  background-position: -96px -64px;
}
.cell-flag.cell-flag-fr,
.cell-flag.cell-flag-fra {
  background-position: -112px -64px;
}
.cell-flag.cell-flag-ga,
.cell-flag.cell-flag-gab {
  background-position: -128px -64px;
}
.cell-flag.cell-flag-gb,
.cell-flag.cell-flag-gbr {
  background-position: -144px -64px;
}
.cell-flag.cell-flag-gd,
.cell-flag.cell-flag-grd {
  background-position: -160px -64px;
}
.cell-flag.cell-flag-ge,
.cell-flag.cell-flag-geo {
  background-position: -176px -64px;
}
.cell-flag.cell-flag-gg,
.cell-flag.cell-flag-ggy {
  background-position: -192px -64px;
}
.cell-flag.cell-flag-gh,
.cell-flag.cell-flag-gha {
  background-position: -208px -64px;
}
.cell-flag.cell-flag-gi,
.cell-flag.cell-flag-gib {
  background-position: -224px -64px;
}
.cell-flag.cell-flag-gl,
.cell-flag.cell-flag-grl {
  background-position: 0 -80px;
}
.cell-flag.cell-flag-gm,
.cell-flag.cell-flag-gmb {
  background-position: -16px -80px;
}
.cell-flag.cell-flag-gn,
.cell-flag.cell-flag-gin {
  background-position: -32px -80px;
}
.cell-flag.cell-flag-gp,
.cell-flag.cell-flag-glp {
  background-position: -48px -80px;
}
.cell-flag.cell-flag-gq,
.cell-flag.cell-flag-gnq {
  background-position: -64px -80px;
}
.cell-flag.cell-flag-gr,
.cell-flag.cell-flag-grc {
  background-position: -80px -80px;
}
.cell-flag.cell-flag-gt,
.cell-flag.cell-flag-gtm {
  background-position: -96px -80px;
}
.cell-flag.cell-flag-gu,
.cell-flag.cell-flag-gum {
  background-position: -112px -80px;
}
.cell-flag.cell-flag-gw,
.cell-flag.cell-flag-gnb {
  background-position: -128px -80px;
}
.cell-flag.cell-flag-gy,
.cell-flag.cell-flag-guy {
  background-position: -144px -80px;
}
.cell-flag.cell-flag-hk,
.cell-flag.cell-flag-hkg {
  background-position: -160px -80px;
}
.cell-flag.cell-flag-hn,
.cell-flag.cell-flag-hnd {
  background-position: -176px -80px;
}
.cell-flag.cell-flag-hr,
.cell-flag.cell-flag-hrv {
  background-position: -192px -80px;
}
.cell-flag.cell-flag-ht,
.cell-flag.cell-flag-hti {
  background-position: -208px -80px;
}
.cell-flag.cell-flag-hu,
.cell-flag.cell-flag-hun {
  background-position: -224px -80px;
}
.cell-flag.cell-flag-id,
.cell-flag.cell-flag-idn {
  background-position: 0 -96px;
}
.cell-flag.cell-flag-ie,
.cell-flag.cell-flag-irl {
  background-position: -16px -96px;
}
.cell-flag.cell-flag-il,
.cell-flag.cell-flag-isr {
  background-position: -32px -96px;
}
.cell-flag.cell-flag-im,
.cell-flag.cell-flag-imn {
  background-position: -48px -96px;
}
.cell-flag.cell-flag-in,
.cell-flag.cell-flag-ind {
  background-position: -64px -96px;
}
.cell-flag.cell-flag-iq,
.cell-flag.cell-flag-irq {
  background-position: -80px -96px;
}
.cell-flag.cell-flag-ir,
.cell-flag.cell-flag-irn {
  background-position: -96px -96px;
}
.cell-flag.cell-flag-is,
.cell-flag.cell-flag-isl {
  background-position: -112px -96px;
}
.cell-flag.cell-flag-it,
.cell-flag.cell-flag-ita {
  background-position: -128px -96px;
}
.cell-flag.cell-flag-je,
.cell-flag.cell-flag-jey {
  background-position: -144px -96px;
}
.cell-flag.cell-flag-jm,
.cell-flag.cell-flag-jam {
  background-position: -160px -96px;
}
.cell-flag.cell-flag-jo,
.cell-flag.cell-flag-jor {
  background-position: -176px -96px;
}
.cell-flag.cell-flag-jp,
.cell-flag.cell-flag-jpn {
  background-position: -192px -96px;
}
.cell-flag.cell-flag-ke,
.cell-flag.cell-flag-ken {
  background-position: -208px -96px;
}
.cell-flag.cell-flag-kg,
.cell-flag.cell-flag-kgz {
  background-position: -224px -96px;
}
.cell-flag.cell-flag-kh,
.cell-flag.cell-flag-khm {
  background-position: 0 -112px;
}
.cell-flag.cell-flag-ki,
.cell-flag.cell-flag-kir {
  background-position: -16px -112px;
}
.cell-flag.cell-flag-km,
.cell-flag.cell-flag-com {
  background-position: -32px -112px;
}
.cell-flag.cell-flag-kn,
.cell-flag.cell-flag-kna {
  background-position: -48px -112px;
}
.cell-flag.cell-flag-kp,
.cell-flag.cell-flag-prk {
  background-position: -64px -112px;
}
.cell-flag.cell-flag-kr,
.cell-flag.cell-flag-kor {
  background-position: -80px -112px;
}
.cell-flag.cell-flag-kw,
.cell-flag.cell-flag-kwt {
  background-position: -96px -112px;
}
.cell-flag.cell-flag-ky,
.cell-flag.cell-flag-cym {
  background-position: -112px -112px;
}
.cell-flag.cell-flag-kz,
.cell-flag.cell-flag-kaz {
  background-position: -128px -112px;
}
.cell-flag.cell-flag-la,
.cell-flag.cell-flag-lao {
  background-position: -144px -112px;
}
.cell-flag.cell-flag-lb,
.cell-flag.cell-flag-lbn {
  background-position: -160px -112px;
}
.cell-flag.cell-flag-lc,
.cell-flag.cell-flag-lca {
  background-position: -176px -112px;
}
.cell-flag.cell-flag-li,
.cell-flag.cell-flag-lie {
  background-position: -192px -112px;
}
.cell-flag.cell-flag-lk,
.cell-flag.cell-flag-lka {
  background-position: -208px -112px;
}
.cell-flag.cell-flag-lr,
.cell-flag.cell-flag-lbr {
  background-position: -224px -112px;
}
.cell-flag.cell-flag-ls,
.cell-flag.cell-flag-lso {
  background-position: 0 -128px;
}
.cell-flag.cell-flag-lt,
.cell-flag.cell-flag-ltu {
  background-position: -16px -128px;
}
.cell-flag.cell-flag-lu,
.cell-flag.cell-flag-lux {
  background-position: -32px -128px;
}
.cell-flag.cell-flag-lv,
.cell-flag.cell-flag-lva {
  background-position: -48px -128px;
}
.cell-flag.cell-flag-ly,
.cell-flag.cell-flag-lby {
  background-position: -64px -128px;
}
.cell-flag.cell-flag-ma,
.cell-flag.cell-flag-mar {
  background-position: -80px -128px;
}
.cell-flag.cell-flag-mc,
.cell-flag.cell-flag-mco {
  background-position: -96px -128px;
}
.cell-flag.cell-flag-md,
.cell-flag.cell-flag-mda {
  background-position: -112px -128px;
}
.cell-flag.cell-flag-me,
.cell-flag.cell-flag-mne {
  background-position: -128px -128px;
}
.cell-flag.cell-flag-mg,
.cell-flag.cell-flag-mdg {
  background-position: -144px -128px;
}
.cell-flag.cell-flag-mh,
.cell-flag.cell-flag-mhl {
  background-position: -160px -128px;
}
.cell-flag.cell-flag-mk,
.cell-flag.cell-flag-mkd {
  background-position: -176px -128px;
}
.cell-flag.cell-flag-ml,
.cell-flag.cell-flag-mli {
  background-position: -192px -128px;
}
.cell-flag.cell-flag-mm,
.cell-flag.cell-flag-mmr {
  background-position: -208px -128px;
}
.cell-flag.cell-flag-mn,
.cell-flag.cell-flag-mng {
  background-position: -224px -128px;
}
.cell-flag.cell-flag-mo,
.cell-flag.cell-flag-mac {
  background-position: 0 -144px;
}
.cell-flag.cell-flag-mq,
.cell-flag.cell-flag-mtq {
  background-position: -16px -144px;
}
.cell-flag.cell-flag-mr,
.cell-flag.cell-flag-mrt {
  background-position: -32px -144px;
}
.cell-flag.cell-flag-ms,
.cell-flag.cell-flag-msr {
  background-position: -48px -144px;
}
.cell-flag.cell-flag-mt,
.cell-flag.cell-flag-mlt {
  background-position: -64px -144px;
}
.cell-flag.cell-flag-mu,
.cell-flag.cell-flag-mus {
  background-position: -80px -144px;
}
.cell-flag.cell-flag-mv,
.cell-flag.cell-flag-mdv {
  background-position: -96px -144px;
}
.cell-flag.cell-flag-mw,
.cell-flag.cell-flag-mwi {
  background-position: -112px -144px;
}
.cell-flag.cell-flag-mx,
.cell-flag.cell-flag-mex {
  background-position: -128px -144px;
}
.cell-flag.cell-flag-my,
.cell-flag.cell-flag-mys {
  background-position: -144px -144px;
}
.cell-flag.cell-flag-mz,
.cell-flag.cell-flag-moz {
  background-position: -160px -144px;
}
.cell-flag.cell-flag-na,
.cell-flag.cell-flag-nam {
  background-position: -176px -144px;
}
.cell-flag.cell-flag-nc,
.cell-flag.cell-flag-ncl {
  background-position: -192px -144px;
}
.cell-flag.cell-flag-ne,
.cell-flag.cell-flag-ner {
  background-position: -208px -144px;
}
.cell-flag.cell-flag-ng,
.cell-flag.cell-flag-nga {
  background-position: -224px -144px;
}
.cell-flag.cell-flag-ni,
.cell-flag.cell-flag-nic {
  background-position: 0 -160px;
}
.cell-flag.cell-flag-nl,
.cell-flag.cell-flag-nld {
  background-position: -16px -160px;
}
.cell-flag.cell-flag-no,
.cell-flag.cell-flag-nor {
  background-position: -32px -160px;
}
.cell-flag.cell-flag-np,
.cell-flag.cell-flag-npl {
  background-position: -48px -160px;
}
.cell-flag.cell-flag-nr,
.cell-flag.cell-flag-nru {
  background-position: -64px -160px;
}
.cell-flag.cell-flag-nz,
.cell-flag.cell-flag-nzl {
  background-position: -80px -160px;
}
.cell-flag.cell-flag-om,
.cell-flag.cell-flag-omn {
  background-position: -96px -160px;
}
.cell-flag.cell-flag-pa,
.cell-flag.cell-flag-pan {
  background-position: -112px -160px;
}
.cell-flag.cell-flag-pe,
.cell-flag.cell-flag-per {
  background-position: -128px -160px;
}
.cell-flag.cell-flag-pf,
.cell-flag.cell-flag-pyf {
  background-position: -144px -160px;
}
.cell-flag.cell-flag-pg,
.cell-flag.cell-flag-png {
  background-position: -160px -160px;
}
.cell-flag.cell-flag-ph,
.cell-flag.cell-flag-phl {
  background-position: -176px -160px;
}
.cell-flag.cell-flag-pk,
.cell-flag.cell-flag-pak {
  background-position: -192px -160px;
}
.cell-flag.cell-flag-pl,
.cell-flag.cell-flag-pol {
  background-position: -208px -160px;
}
.cell-flag.cell-flag-pr,
.cell-flag.cell-flag-pri {
  background-position: -224px -160px;
}
.cell-flag.cell-flag-ps,
.cell-flag.cell-flag-pse {
  background-position: 0 -176px;
}
.cell-flag.cell-flag-pt,
.cell-flag.cell-flag-prt {
  background-position: -16px -176px;
}
.cell-flag.cell-flag-pw,
.cell-flag.cell-flag-plw {
  background-position: -32px -176px;
}
.cell-flag.cell-flag-py,
.cell-flag.cell-flag-pry {
  background-position: -48px -176px;
}
.cell-flag.cell-flag-qa,
.cell-flag.cell-flag-qat {
  background-position: -64px -176px;
}
.cell-flag.cell-flag-re,
.cell-flag.cell-flag-reu {
  background-position: -80px -176px;
}
.cell-flag.cell-flag-ro,
.cell-flag.cell-flag-rou {
  background-position: -96px -176px;
}
.cell-flag.cell-flag-rs,
.cell-flag.cell-flag-srb {
  background-position: -112px -176px;
}
.cell-flag.cell-flag-ru,
.cell-flag.cell-flag-rus {
  background-position: -128px -176px;
}
.cell-flag.cell-flag-rw,
.cell-flag.cell-flag-rwa {
  background-position: -144px -176px;
}
.cell-flag.cell-flag-sa,
.cell-flag.cell-flag-sau {
  background-position: -160px -176px;
}
.cell-flag.cell-flag-sb,
.cell-flag.cell-flag-slb {
  background-position: -176px -176px;
}
.cell-flag.cell-flag-sc,
.cell-flag.cell-flag-syc {
  background-position: -192px -176px;
}
.cell-flag.cell-flag-sd,
.cell-flag.cell-flag-sdn {
  background-position: -208px -176px;
}
.cell-flag.cell-flag-se,
.cell-flag.cell-flag-swe {
  background-position: -224px -176px;
}
.cell-flag.cell-flag-sg,
.cell-flag.cell-flag-sgp {
  background-position: 0 -192px;
}
.cell-flag.cell-flag-si,
.cell-flag.cell-flag-svn {
  background-position: -16px -192px;
}
.cell-flag.cell-flag-sk,
.cell-flag.cell-flag-svk {
  background-position: -32px -192px;
}
.cell-flag.cell-flag-sl,
.cell-flag.cell-flag-sle {
  background-position: -48px -192px;
}
.cell-flag.cell-flag-sm,
.cell-flag.cell-flag-smr {
  background-position: -64px -192px;
}
.cell-flag.cell-flag-sn,
.cell-flag.cell-flag-sen {
  background-position: -80px -192px;
}
.cell-flag.cell-flag-so,
.cell-flag.cell-flag-som {
  background-position: -96px -192px;
}
.cell-flag.cell-flag-sr,
.cell-flag.cell-flag-sur {
  background-position: -112px -192px;
}
.cell-flag.cell-flag-st,
.cell-flag.cell-flag-stp {
  background-position: -128px -192px;
}
.cell-flag.cell-flag-sv,
.cell-flag.cell-flag-slv {
  background-position: -144px -192px;
}
.cell-flag.cell-flag-sy,
.cell-flag.cell-flag-syr {
  background-position: -160px -192px;
}
.cell-flag.cell-flag-sz,
.cell-flag.cell-flag-swz {
  background-position: -176px -192px;
}
.cell-flag.cell-flag-tc,
.cell-flag.cell-flag-tca {
  background-position: -192px -192px;
}
.cell-flag.cell-flag-td,
.cell-flag.cell-flag-tcd {
  background-position: -208px -192px;
}
.cell-flag.cell-flag-tg,
.cell-flag.cell-flag-tgo {
  background-position: -224px -192px;
}
.cell-flag.cell-flag-th,
.cell-flag.cell-flag-tha {
  background-position: 0 -208px;
}
.cell-flag.cell-flag-tj,
.cell-flag.cell-flag-tjk {
  background-position: -16px -208px;
}
.cell-flag.cell-flag-tl,
.cell-flag.cell-flag-tls {
  background-position: -32px -208px;
}
.cell-flag.cell-flag-tm,
.cell-flag.cell-flag-tkm {
  background-position: -48px -208px;
}
.cell-flag.cell-flag-tn,
.cell-flag.cell-flag-tun {
  background-position: -64px -208px;
}
.cell-flag.cell-flag-to,
.cell-flag.cell-flag-ton {
  background-position: -80px -208px;
}
.cell-flag.cell-flag-tr,
.cell-flag.cell-flag-tur {
  background-position: -96px -208px;
}
.cell-flag.cell-flag-tt,
.cell-flag.cell-flag-tto {
  background-position: -112px -208px;
}
.cell-flag.cell-flag-tv,
.cell-flag.cell-flag-tuv {
  background-position: -128px -208px;
}
.cell-flag.cell-flag-tw,
.cell-flag.cell-flag-twn {
  background-position: -144px -208px;
}
.cell-flag.cell-flag-tz,
.cell-flag.cell-flag-tza {
  background-position: -160px -208px;
}
.cell-flag.cell-flag-ua,
.cell-flag.cell-flag-ukr {
  background-position: -176px -208px;
}
.cell-flag.cell-flag-ug,
.cell-flag.cell-flag-uga {
  background-position: -192px -208px;
}
.cell-flag.cell-flag-us,
.cell-flag.cell-flag-usa {
  background-position: -208px -208px;
}
.cell-flag.cell-flag-uy,
.cell-flag.cell-flag-ury {
  background-position: -224px -208px;
}
.cell-flag.cell-flag-uz,
.cell-flag.cell-flag-uzb {
  background-position: 0 -224px;
}
.cell-flag.cell-flag-va,
.cell-flag.cell-flag-vat {
  background-position: -16px -224px;
}
.cell-flag.cell-flag-vc,
.cell-flag.cell-flag-vct {
  background-position: -32px -224px;
}
.cell-flag.cell-flag-ve,
.cell-flag.cell-flag-ven {
  background-position: -48px -224px;
}
.cell-flag.cell-flag-vg,
.cell-flag.cell-flag-vgb {
  background-position: -64px -224px;
}
.cell-flag.cell-flag-vi,
.cell-flag.cell-flag-vir {
  background-position: -80px -224px;
}
.cell-flag.cell-flag-vn,
.cell-flag.cell-flag-vnm {
  background-position: -96px -224px;
}
.cell-flag.cell-flag-vu,
.cell-flag.cell-flag-vut {
  background-position: -112px -224px;
}
.cell-flag.cell-flag-ws,
.cell-flag.cell-flag-wsm {
  background-position: -128px -224px;
}
.cell-flag.cell-flag-ye,
.cell-flag.cell-flag-yem {
  background-position: -144px -224px;
}
.cell-flag.cell-flag-za,
.cell-flag.cell-flag-zaf {
  background-position: -160px -224px;
}
.cell-flag.cell-flag-zm,
.cell-flag.cell-flag-zmb {
  background-position: -176px -224px;
}
.cell-flag.cell-flag-zw,
.cell-flag.cell-flag-zwe {
  background-position: -192px -224px;
}
.cell-flag.cell-flag-eu,
.cell-flag.cell-flag-eur {
  background-position: -224px -224px;
}
.cell-flag.cell-flag- {
  background-position: -208px -224px;
}

.cell-flex-align-left {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.cell-flex-align-right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cell-flex-align-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cell-flex-align-justify {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cell-flex-align-spaced {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.cell-flex-align-left.vertical.menu > li > a {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.cell-flex-align-right.vertical.menu > li > a {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.cell-flex-align-center.vertical.menu > li > a {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cell-flex-align-top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.cell-flex-align-self-top {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.cell-flex-align-bottom {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.cell-flex-align-self-bottom {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.cell-flex-align-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cell-flex-align-self-middle {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.cell-flex-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.cell-flex-align-self-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.cell-flex-align-center-middle {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.cell-flex-small-order-1 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.cell-flex-small-order-2 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.cell-flex-small-order-3 {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}

.cell-flex-small-order-4 {
  -webkit-box-ordinal-group: 5;
  -ms-flex-order: 4;
  order: 4;
}

.cell-flex-small-order-5 {
  -webkit-box-ordinal-group: 6;
  -ms-flex-order: 5;
  order: 5;
}

.cell-flex-small-order-6 {
  -webkit-box-ordinal-group: 7;
  -ms-flex-order: 6;
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .cell-flex-medium-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .cell-flex-medium-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .cell-flex-medium-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .cell-flex-medium-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .cell-flex-medium-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .cell-flex-medium-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
}
@media print, screen and (min-width: 64em) {
  .cell-flex-large-order-1 {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .cell-flex-large-order-2 {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .cell-flex-large-order-3 {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
  }
  .cell-flex-large-order-4 {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
  }
  .cell-flex-large-order-5 {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
  }
  .cell-flex-large-order-6 {
    -webkit-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
  }
}
.cell-flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.cell-flex-child-auto {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.cell-flex-child-grow {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.cell-flex-child-shrink {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.cell-flex-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.cell-flex-dir-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.cell-flex-dir-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cell-flex-dir-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .cell-flex-medium-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .cell-flex-medium-child-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .cell-flex-medium-child-grow {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .cell-flex-medium-child-shrink {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .cell-flex-medium-dir-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cell-flex-medium-dir-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .cell-flex-medium-dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cell-flex-medium-dir-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
@media print, screen and (min-width: 64em) {
  .cell-flex-large-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .cell-flex-large-child-auto {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  .cell-flex-large-child-grow {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .cell-flex-large-child-shrink {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
  .cell-flex-large-dir-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .cell-flex-large-dir-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .cell-flex-large-dir-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .cell-flex-large-dir-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.cell-font-size-10px {
  font-size: 10px;
}
.cell-font-size-11px {
  font-size: 11px;
}
.cell-font-size-12px {
  font-size: 12px;
}
.cell-font-size-13px {
  font-size: 13px;
}
.cell-font-size-14px {
  font-size: 14px;
}
.cell-font-size-15px {
  font-size: 15px;
}
.cell-font-size-16px {
  font-size: 16px;
}
.cell-font-size-17px {
  font-size: 17px;
}
.cell-font-size-18px {
  font-size: 18px;
}
.cell-font-size-19px {
  font-size: 19px;
}
.cell-font-size-20px {
  font-size: 20px;
}
.cell-font-size-21px {
  font-size: 21px;
}
.cell-font-size-22px {
  font-size: 22px;
}
.cell-font-size-23px {
  font-size: 23px;
}
.cell-font-size-24px {
  font-size: 24px;
}
.cell-font-size-25px {
  font-size: 25px;
}
.cell-font-size-26px {
  font-size: 26px;
}
.cell-font-size-27px {
  font-size: 27px;
}
.cell-font-size-28px {
  font-size: 28px;
}
.cell-font-size-29px {
  font-size: 29px;
}

.cell-offset-top {
  margin-top: 10px;
}
.cell-offset-bottom {
  margin-bottom: 10px;
}
.cell-offset-right {
  margin-right: 10px;
}
.cell-offset-left {
  margin-left: 10px;
}

.cell-no-margin {
  margin: 0 !important;
}

.cell-no-margin-top {
  margin-top: 0 !important;
}

.cell-no-margin-right {
  margin-right: 0 !important;
}

.cell-no-margin-bottom {
  margin-bottom: 0 !important;
}

.cell-no-margin-left {
  margin-left: 0 !important;
}

.cell-5-margin-top {
  margin-top: 5px !important;
}

.cell-5-margin-right {
  margin-right: 5px !important;
}

.cell-5-margin-bottom {
  margin-bottom: 5px !important;
}

.cell-5-margin-left {
  margin-left: 5px !important;
}

.cell-10-margin-top {
  margin-top: 10px !important;
}

.cell-10-margin-right {
  margin-right: 10px !important;
}

.cell-10-margin-bottom {
  margin-bottom: 10px !important;
}

.cell-10-margin-left {
  margin-left: 10px !important;
}

.cell-15-margin-top {
  margin-top: 15px !important;
}

.cell-15-margin-bottom {
  margin-bottom: 15px !important;
}

.cell-10-padding {
  padding: 10px !important;
}

.cell-10-padding-top {
  padding-top: 10px !important;
}

.cell-5-padding {
  padding: 5px !important;
}

.cell-5-padding-right {
  padding-right: 5px !important;
}

.cell-5-padding-bottom {
  padding-bottom: 5px !important;
}

.cell-3-padding-right {
  padding-right: 3px !important;
}

.cell-no-padding {
  padding: 0 !important;
}

.cell-no-padding-top {
  padding-top: 0 !important;
}

.cell-no-padding-bottom {
  padding-bottom: 0 !important;
}

.cell-no-padding-left {
  padding-left: 0 !important;
}

.cell-opacity-10 {
  opacity: 0.1;
}
.cell-opacity-20 {
  opacity: 0.2;
}
.cell-opacity-30 {
  opacity: 0.3;
}
.cell-opacity-40 {
  opacity: 0.4;
}
.cell-opacity-50 {
  opacity: 0.5;
}
.cell-opacity-60 {
  opacity: 0.6;
}
.cell-opacity-70 {
  opacity: 0.7;
}
.cell-opacity-80 {
  opacity: 0.8;
}
.cell-opacity-90 {
  opacity: 0.9;
}
.cell-opacity-100 {
  opacity: 1;
}

@-webkit-keyframes shake {
  10% {
    -webkit-transform: rotate(10deg) translate3d(-1px, 1px, 0);
    transform: rotate(10deg) translate3d(-1px, 1px, 0);
  }
  20% {
    -webkit-transform: rotate(-5deg) translate3d(2px, -1px, 0);
    transform: rotate(-5deg) translate3d(2px, -1px, 0);
  }
  30%,
  50% {
    -webkit-transform: rotate(10deg) translate3d(-2px, 0, 0);
    transform: rotate(10deg) translate3d(-2px, 0, 0);
  }
  40%,
  60%,
  70% {
    -webkit-transform: rotate(-8deg) translate3d(2px, 0, 0);
    transform: rotate(-8deg) translate3d(2px, 0, 0);
  }
}

@keyframes shake {
  10% {
    -webkit-transform: rotate(10deg) translate3d(-1px, 1px, 0);
    transform: rotate(10deg) translate3d(-1px, 1px, 0);
  }
  20% {
    -webkit-transform: rotate(-5deg) translate3d(2px, -1px, 0);
    transform: rotate(-5deg) translate3d(2px, -1px, 0);
  }
  30%,
  50% {
    -webkit-transform: rotate(10deg) translate3d(-2px, 0, 0);
    transform: rotate(10deg) translate3d(-2px, 0, 0);
  }
  40%,
  60%,
  70% {
    -webkit-transform: rotate(-8deg) translate3d(2px, 0, 0);
    transform: rotate(-8deg) translate3d(2px, 0, 0);
  }
}
.cell-shake {
  -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.cell-text-left {
  text-align: left !important;
}
.cell-text-center {
  text-align: center !important;
}
.cell-text-right {
  text-align: right !important;
}
.cell-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-text-uppercase {
  text-transform: uppercase;
}

.cell-cursor-pointer {
  cursor: pointer;
}

.cell-pos-relative {
  position: relative;
}

.cell-maxed {
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.cell-pull-left {
  float: left;
}

.cell-pull-right {
  float: right;
}

.cell-valign-bottom {
  vertical-align: bottom;
}

.cell-strong,
.cell-weight-bold {
  font-weight: bold;
}

.cell-weight-normal {
  font-weight: normal;
}

.cell-italic {
  font-style: italic;
}

.cell-nowrap {
  white-space: nowrap;
}

.cell-no-border {
  border: 0;
}

.cell-no-border-top {
  border-top-width: 0;
}

.cell-1-border-top {
  border-top-style: solid;
  border-top-width: 1px;
}

.cell-1-border-right {
  border-right-style: solid;
  border-right-width: 1px;
}

.cell-no-border-bottom {
  border-bottom-width: 0;
}

.cell-1-border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.cell-1-border-left {
  border-left-style: solid;
  border-left-width: 1px;
}

.cell-border-transparent {
  border-color: transparent;
}

.cell-off-screen {
  -webkit-transform: translateY(-200%);
  transform: translateY(-200%);
}

.cell-visibility-hidden {
  visibility: hidden;
}

.cell-display-none {
  display: none !important;
}

.cell-display-block {
  display: block;
}

.cell-display-inline-block {
  display: inline-block;
}

.cell-overflow-hidden {
  overflow: hidden;
}

.cell-overflow-scroll-y {
  overflow-y: auto;
}

.cell-overflow-scroll-x {
  overflow-x: auto;
}

.cell-fullwidth {
  width: 100%;
}

.cell-space-before::before {
  content: " ";
}
.cell-space-before::after {
  content: " ";
}

.cell-textarea {
  font-family: inherit;
  font-size: 11px;
  margin: 0 5px 0 0;
  padding: 2px 3px;
  vertical-align: top;
}

.cell-checkbox {
  margin-top: 5px;
}

.cell-label {
  line-height: 23px;
}

.cell-scrollable-content {
  float: left;
  overflow: auto;
  width: 100%;
}

.cell-widget-big-text {
  font-size: 36px;
  font-weight: normal;
  margin: 9px 0 2px;
}

.cell-widget-med-text {
  font-size: 28px;
  font-weight: normal;
  margin: 6px 0;
}

.cell-delayed-indicator {
  display: inline;
  font-size: 0.8em;
  opacity: 0.5;
  vertical-align: top;
}

.cell-widget-divider {
  clear: both;
  height: 10px;
  width: 100%;
}

.cell-widget-footer {
  margin-bottom: 5px;
  margin-top: 5px;
}

.cell-tbl-change-bars {
  margin-top: 6px;
  width: 100%;
}

.cell-tbl-change-bars--td {
  height: 20px;
}

@media screen and (min-width: 40em) {
  .news-reader-iframer {
    height: 100%;
    overflow: hidden;
    padding-bottom: 46px;
  }
}

.cell-overlay-newsreader {
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
@media screen and (max-width: 39.99875em) {
  .cell-overlay-newsreader {
    margin-bottom: 88px;
  }
}

.cell-form-elements {
  float: right;
  position: absolute;
  right: 5px;
  top: 5px;
}
.cell-form-elements ul {
  display: inline-block;
}
.cell-form-elements .cell-input {
  margin-right: 0;
}

.cell-health-bad {
  height: 40px;
  left: -25px;
  position: absolute;
  top: 4px;
  width: 20px;
}

.cell-health-neutral {
  height: 40px;
  left: -25px;
  position: absolute;
  top: 4px;
  width: 20px;
}

.cell-health-good {
  height: 40px;
  left: -25px;
  position: absolute;
  top: 4px;
  width: 20px;
}

.cell-table__txt--investment-mandate,
.undefined--for-sale-in {
  line-height: 1.25;
}

.cell-page-overlay {
  height: 100%;
  left: 0;
  min-height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
}

.cell-image-link {
  background-position: 0;
  background-repeat: no-repeat;
  cursor: pointer;
  float: right;
  height: 12px;
  margin-left: 10px;
  width: 12px;
}

.cell-image {
  background-position: 0;
  background-repeat: no-repeat;
  float: right;
  height: 12px;
  margin-left: 10px;
  width: 12px;
}

.cell-w-clear-filters {
  cursor: pointer;
  margin: 2px;
  padding: 7px;
  position: relative;
}

.cell-super-absolute {
  cursor: pointer;
  padding-top: 2px;
  z-index: 1000;
}

.cell-width--150px input {
  width: 100%;
}

.cell-radio-filter__group--inline {
  border: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
}

.cell-inner-panel {
  height: 0;
  overflow: hidden;
}

.cell-inner-panel--expanded {
  height: auto;
}

.cell-vertical-align-middle {
  vertical-align: middle;
}

.undefined--for-sale-in {
  line-height: 1.25;
}

.cell-override--trade-search .cell-input.cell-input--190px {
  width: 80%;
}
.cell-override--trade-search .cell-search-results {
  margin-top: 28px;
}

.cell-list--empty {
  padding: 8px 0;
}

.news-instrument__header {
  float: left;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 3px;
  margin-left: 5px;
  padding-top: 3px;
  text-transform: uppercase;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 10px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
  z-index: 1;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 10px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0px;
  /* please don't change 'position' */
  position: absolute;
  z-index: 1;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.7;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 6px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 6px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
/*# sourceMappingURL=InfrontFramework-3.11.16.css.map */
